/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AActivityFeed {
  id: number
  /**
   * @format date
   * @example "2024-05-09"
   */
  activity_date: string
  grouped: boolean
  grouped_count: number
  /** @format date-time */
  grouped_at: string | null
  /** @format date-time */
  changed_activity_at: string
  activity_type: AActivityFeedActivityType
  string_notification_type: AActivityFeedNotificationType
  body: string
  note?: string | null
  /** @format date-time */
  happens_at?: string | null
  /** @format date-time */
  updated_at: string
  /** @format date-time */
  created_at: string
  liked_by_company: boolean
  created_by: AActivityFeedUser | null
  permissions?: APermissions
  deal?: {
    name?: string
    prospect?: {
      organization_name?: string
    } | null
  }
}

export enum AActivityFeedActivityType {
  StringNotification = "string_notification",
  Call = "call",
  Email = "email",
  Meeting = "meeting",
  MeetingScheduled = "meeting_scheduled",
  KpiEmail = "kpi_email",
  KpiCall = "kpi_call",
  Won = "won",
  PriceOfferSent = "price_offer_sent",
}

export enum AActivityFeedNotificationType {
  WeeklyUpdate = "weekly_update",
  DatabaseStatus = "database_status",
  MatchingInProgress = "matching_in_progress",
  SalesTalentEducation = "sales_talent_education",
  StatusMeeting = "status_meeting",
  SalesOutreachPlanUpdate = "sales_outreach_plan_update",
  QualityCheck = "quality_check",
  AccountSetup = "account_setup",
  SalesAssistantUpdates = "sales_assistant_updates",
}

export interface AActivityFeedUser {
  id: number
  first_name: string
  last_name: string
  type: AActivityFeedUserType
  profile_picture_url: string | null
  profile_picture_thumbnail_url: string | null
  permissions?: APermissions
}

export enum AActivityFeedUserType {
  Admin = "Admin",
  SalesPerson = "SalesPerson",
  SaasSalesPerson = "SaasSalesPerson",
}

export interface AAssignment {
  id: number
  reaches: number
  status: string
  sales_person: ASalesPerson | null
  sales_cycle: ASalesCycle
  pending_assignment_offers: AMatchingAssignmentOffer[]
  permissions?: APermissions
}

export interface AAssignmentShort {
  id: number
  reaches: number
  status?: string
  sales_person: ASalesPerson | null
  sales_cycle: {
    id: number
    name: string
  }
  pending_assignment_offers: AMatchingAssignmentOffer[]
  permissions?: APermissions
}

export interface ACompanyUserAssignmentReporting {
  id: number
  group_id: string
  total_activities: number
  activities_trend: number | null
  sales_person?: {
    id: number
    first_name: string
    last_name: string
    profile_picture_url: string | null
    profile_picture_thumbnail_url: string | null
  }
}

export interface ACompanyUserSalesNewsAssignment {
  id: number
  reaches: number
  outreach: number
  statistics: ACompanyUsersScopedStatistics
  sales_person: ACompanyUserSalesPerson | null
  permissions: APermissions
}

export interface ACompanyUserSalesCycleIterationAssignmentReporting {
  id: number
  group_id?: string
  statistics?: ACompanyUsersTrendsStatistics | null
  permissions: APermissions
}

export interface ASaasSalesPersonDashboardAssignments {
  id: number
  reaches: number
  minimum_meetings_count: number
  prospects_in_crm?: number | null
  /** @format date */
  last_activity_date?: string | null
  statistics: {
    emails_sent_activities?: number | null
    emails_sent_activities_uniq?: number | null
    calls_made_activities?: number | null
    calls_made_activities_uniq?: number | null
    won?: number | null
    price_offers?: number | null
    meetings_done_activities?: number | null
    opportunities?: number | null
  }
  sales_person?: ACompanyUserSalesPerson | null
  permissions?: APermissions
}

export interface ASaasCompanyUsersDashboardAssignments {
  id: number
  reaches: number
  minimum_meetings_count: number
  prospects_in_crm?: number | null
  /** @format date */
  last_activity_date?: string | null
  statistics: {
    emails_sent_activities?: number | null
    emails_sent_activities_uniq?: number | null
    calls_made_activities?: number | null
    calls_made_activities_uniq?: number | null
    won?: number | null
    price_offers?: number | null
    meetings_done_activities?: number | null
    opportunities?: number | null
  }
  sales_person?: ACompanyUserSalesPerson | null
  permissions?: APermissions
}

export enum AAssignmentStatuses {
  Pending = "pending",
  Active = "active",
  Canceled = "canceled",
  PreMatched = "pre_matched",
}

export interface AAssignmentOffer {
  id: number
  reward_value: number
  reaches: number
  sales_strategy_url?: string | null
  status: string
  /**
   * @format date
   * @example "2023-05-31"
   */
  valid_until: string
  currency: ACurrency
  sales_person: ASalesPerson
  products?: AProducts
  permissions?: APermissions
}

export interface AMatchingAssignmentOffer {
  id: number
  status: string
  /**
   * @format date
   * @example "2023-05-31"
   */
  valid_until: string
  sales_person: ASalesPerson
  permissions?: APermissions
}

export type AAttachments = {
  id: number
  url: string
}[]

export interface ABusinessData {
  id?: number
  company_name?: string
  company_id?: string
  street_address?: string
  city?: string
  postal_code?: string
  state?: string
  version?: number
  country?: ACountry
  permissions?: APermissions
}

export interface AActivitiesValues {
  meetings: number
  calls: number
  emails: number
}

export type AActivitiesChartData = {
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  values: AActivitiesValues
  assignments: {
    id: number
    sales_person: AAdminsProspectsAssignmentSalesPerson
    values: AActivitiesValues
  }[]
}[]

export type AActivityOccurrencesChartData = {
  /** Key visible on the X axis */
  key: string
  /** @format date-time */
  from: string
  /** @format date-time */
  to: string
  occurrences: number
}[]

export interface AOpportunitiesChartData {
  proposal_sent: number
  future_deals: number
  negotiating: number
}

export type AClientsReachChartData = AActivitiesValues

export enum AActivityEvents {
  EmailSent = "email_sent",
  CallingDone = "calling_done",
  MeetingHappened = "meeting_happened",
}

export interface ACompany {
  id: number
  name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
}

export interface ACompanyWithPermissions {
  id: number
  name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  permissions?: APermissions
}

export interface ACompanyProfile {
  id: number
  profile: {
    name: string
    website?: string | null
    headline?: string | null
    description?: string | null
    number_of_employees?: number | null
    industry?: ACompanyIndustries
    profile_picture_url?: string | null
    profile_picture_thumbnail_url?: string | null
    country?: ACountry | null
  }
  members: {
    id: number
    first_name?: string | null
    last_name?: string | null
    phone_number?: string | null
  }[]
  transactions: ATransaction[]
}

export interface ACompanyAttributes {
  profile?: {
    name?: string
    website?: string
    headline?: string
    description?: string
    number_of_employees?: number
    industry?: ACompanyIndustries
    /** @format binary */
    profile_picture?: File
    /** Country ID */
    country_id?: number
  }
  members?: {
    id: number
    first_name?: string
    last_name?: string
    phone_number?: string
  }[]
}

export enum ACompanyIndustries {
  Fmcg = "fmcg",
  Finance = "finance",
  Retail = "retail",
  FoodIndustry = "food_industry",
  Marketing = "marketing",
  ECommerce = "e_commerce",
  Corporate = "corporate",
}

export interface ACompanyUserDashboard {
  id: number
  active_sales_cycles_count: number
  drafted_sales_cycles_count: number
  finished_sales_cycles_count: number
  paused_sales_cycles_count: number
  sales_people?: ACompanyUserSalesCycleSalesPerson[]
  permissions?: APermissions
}

export interface ACountry {
  id: number
  name: string
  sales_cycle_allowed?: boolean
  visible_vat_number?: boolean
  require_vat_number?: boolean
  states?: {
    value: string
    title: string
  }[]
  /** ISO two letters */
  code: string
  currency?: ACurrency
  permissions?: APermissions
}

export interface ACurrency {
  id: number
  name: string
  symbol: string
  code: string
  permissions?: APermissions
}

export interface AProspectActivity {
  id: string
  event: AProspectActivityEventEnum
  note?: string | null
  finished: boolean
  /** @format date-time */
  changed_activity_at: string
  /** @format date */
  activity_date: string
  created_by?: AActivityFeedUser | null
  deal?: {
    name?: string
    prospect?: {
      organization_name?: string
    } | null
  }
  permissions?: APermissions
}

export type AErrors = Record<string, any>

export interface AErrorBaseMessages {
  base?: string[]
}

export interface AImporter {
  id: number
  /** @format date-time */
  created_at: string
  delete_permitted: boolean
  /** @format date-time */
  started_at?: string | null
  /** @format date-time */
  imported_at?: string | null
  /** @format date-time */
  delete_disabled_at?: string | null
  status: AImporterStatuses
  error_message?: string | null
  created_by?: AImportedByUser
  permissions?: APermissions
}

export enum AImporterStatuses {
  Waiting = "waiting",
  InProgress = "in_progress",
  Failed = "failed",
  DoneWithWarnings = "done_with_warnings",
  Done = "done",
  RolledBack = "rolled_back",
}

export interface AInvoiceDetail {
  id: number
  name: string
  email: string
  address: string
  city: string
  postal_code: string
  company: boolean
  company_name: string
  company_number: string
  company_vat_number?: string | null
  company_tax_number?: string | null
  default?: boolean
  owner: ACompany
  country: ACountry
}

export interface AInvoiceDetailAttributes {
  name: string
  email: string
  city: string
  address: string
  postal_code: string
  company: boolean
  /** Country ID */
  country_id: number
  company_name: string
  company_number: string
  company_tax_number?: string
  company_vat_number?: string
  default?: boolean
}

export interface ALanguage {
  id?: number
  name?: string
  code?: string
  permissions?: APermissions
}

export interface ALead {
  id: number
  name: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  notes?: string | null
  source?: string | null
  in_use: boolean
  company_size: ALeadCompanySizes
  country: ACountry
  permissions?: APermissions
  warnings?: string[] | null
  is_potential_duplicate?: boolean
}

export interface ALeadWithAssignmentProspect {
  id: number
  name: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  notes?: string | null
  source?: string | null
  in_use: boolean
  company_size: ALeadCompanySizes
  country: ACountry
  assignment_prospect?: AAdminProspect | null
  permissions?: APermissions
  warnings?: string[] | null
  is_potential_duplicate?: boolean
}

export interface ALeadDetail {
  id: number
  name: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  notes?: string | null
  source?: string | null
  in_use: boolean
  sources?: string[]
  ownership_levels?: {
    id: number
    owner_name: string
    access: string
  }[]
  sales_cycles?: {
    id: number
    name: string
  }[]
  company_size: ALeadCompanySizes
  country: ACountry
  permissions?: APermissions
  warnings?: string[] | null
  is_potential_duplicate?: boolean
}

export interface ALeadAttributes {
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  company_size?: ALeadCompanySizes
  notes?: string | null
  country_id: number
}

export interface APublicLead {
  organization_name: string
  contact_person_first_name: string | null
  contact_person_last_name: string | null
  phone_number: string | null
  organization_phone_number: string | null
  additional_phone_number: string | null
  email: string | null
  city: string | null
  position: string | null
  segment: string[] | null
  website: string | null
  country: {
    name?: string
  }
}

export enum ALeadCompanySizes {
  NotSpecified = "not_specified",
  Small = "small",
  Middle = "middle",
  Big = "big",
  Corporation = "corporation",
}

export enum ALeadAutocompleteFields {
  Position = "position",
  Segment = "segment",
  Country = "country",
}

export enum ALeadSortByValues {
  OrganizationName = "organization_name",
  Position = "position",
  City = "city",
  Email = "email",
}

export enum ALeadSortDirectionValues {
  Asc = "asc",
  Desc = "desc",
}

export enum ALeadDeleteStrategies {
  Gdpr = "gdpr",
  BadLead = "bad_lead",
  InvalidDetails = "invalid_details",
}

export type AMeta = AMetaWithPagination | AEmptyMeta

export interface AMetaWithPagination {
  pagination?: APagination
}

export type AEmptyMeta = any

export interface APagination {
  current_page?: number
  per_page?: number
  total_count?: number
}

export interface APreviewOrder {
  min_regular_setup_fee_price: number
  max_regular_setup_fee_price: number
  min_regular_sales_person_price: number
  max_regular_sales_person_price: number
  min_total_regular_price: number
  max_total_regular_price: number
  order: AOrder
}

export interface AOrder {
  id: number
  order_type: AOrderOrderTypeEnum
  status: AOrderStatuses
  subscription_billing_type: ASubscriptionBillingTypes
  total_price: number
  total_price_without_vat: number
  total_price_before_discount: number
  total_vat_price: number
  total_price_before_discount_without_vat?: number
  total_discount_price?: number
  customer_name?: string | null
  customer_email?: string | null
  city?: string | null
  address?: string | null
  postal_code?: string | null
  state?: string | null
  company_name?: string | null
  company_number?: string | null
  company_tax_number?: string | null
  company_vat_number?: string | null
  external_invoice_url?: string | null
  external_receipt_url?: string | null
  invoice_number?: string | null
  /**
   * @format date
   * @example "2023-07-14"
   */
  next_payment_date?: string
  next_payment_total_price?: number
  payment_method: APaymentMethods
  verified_vat_payer?: boolean
  voucher_code_input?: string | null
  voucher_code_result?: AVoucherCodeResult
  sales_cycle: ASalesCycle
  country?: ACountry | null
  currency: ACurrency
  invoice_detail?: AInvoiceDetail | null
  order_items: AOrderItem[]
  service_items: AOrderItem[]
  permissions?: APermissions
}

export interface ACompanyUserDashboardOrder {
  id: number
  status: AOrderStatuses
  total_price: number
  external_invoice_url?: string | null
  payment_method: APaymentMethods
  /** @format date */
  due_date?: string | null
  currency: ACurrency
  permissions?: APermissions
}

export type AVoucherCodeResult = {
  status?: AVoucherCodeResultStatusEnum
  message?: string
} | null

export enum AOrderStatuses {
  Created = "created",
  InProgress = "in_progress",
  Paid = "paid",
  Failed = "failed",
  Canceled = "canceled",
}

export enum APaymentMethods {
  Undefined = "undefined",
  Card = "card",
  BankTransfer = "bank_transfer",
}

export enum ASubscriptionBillingTypes {
  Month = "month",
  Year = "year",
}

export interface AOrderItem {
  id: number
  total_price: number
  total_price_without_vat: number
  total_price_before_discount: number
  name?: string | null
  description?: string | null
  unit_price: number
  unit_price_without_vat: number
  total_vat_price: number
  discount_percentage: number
  vat_rate_percentage: number
  quantity: number
  unit_price_before_discount_without_vat?: number
  unit_price_before_discount?: number
  total_price_before_discount_without_vat?: number
  unit_discount_price?: number
  unit_vat_price?: number
  total_discount_price?: number
  /** This attribute can be Package or SalesStrategy */
  purchased_product: object
  package?: APackage | null
  sales_strategy?: ASalesStrategy | null
  sales_person?: ACompanyUserSalesCycleSalesPerson | null
}

export interface APackage {
  id: number
  name: string
  short_name: string
  reaches: number
}

export interface APackageWithPrice {
  id: number
  name: string
  short_name: string
  reaches: number
  monthly_price: number
  yearly_price: number
  yearly_discount?: number
  title: string
  subtitle: string
  currency: ACurrency
  permissions?: APermissions
}

export interface APermissions {
  "update?": boolean
  "destroy?": boolean
}

export interface AResourceLevelPermissions {
  "index?": boolean
  "create?": boolean
}

export interface APublicPrivacyRequest {
  token: string
  resource: APublicLead
}

export type AProducts = AProduct[]

export interface AProduct {
  id: number
  name: string
  price: number | null
  goal: AGoals
  commission: number
  sales_person_commission: number
  commission_type: ACommissionTypes
  commission_value_type: ACommissionValueTypes
  commission_note?: string | null
  currency: ACurrency | null
  permissions?: APermissions
}

export enum AGoals {
  SetupMeeting = "setup_meeting",
  Registration = "registration",
  ContractConclusion = "contract_conclusion",
  CloseDeal = "close_deal",
}

export enum ACommissionTypes {
  OneOff = "one_off",
  Recurring = "recurring",
}

export enum ACommissionValueTypes {
  Percentage = "percentage",
  FixedPrice = "fixed_price",
}

export interface AAdminProspect {
  id: number
  name: string
  /** @format date-time */
  created_at: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  approved: boolean
  prioritized: boolean
  crm_synced?: boolean
  retired: boolean
  is_potential_duplicate: boolean
  warnings?: string[] | null
  added_by: string
  /** @format date-time */
  approved_at?: string | null
  approval_status: AProspectApprovalStatuses
  processing_status: AProspectProcessingStatuses
  status?: AProspectStatuses
  disapprove_reason?: AProspectDisapproveReasons | null
  disapprove_reason_text?: string | null
  notes?: string | null
  source?: string | null
  country: ACountry
  intended_for?: ASalesPerson | null
  assigned_to?: ASalesPerson | null
  permissions?: APermissions
}

export interface ASalesPersonProspect {
  id: number
  name: string
  /** @format date-time */
  created_at: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  approved: boolean
  prioritized: boolean
  crm_synced?: boolean
  retired: boolean
  is_potential_duplicate: boolean
  warnings?: string[] | null
  added_by: string
  /** @format date-time */
  approved_at?: string | null
  approval_status: AProspectApprovalStatuses
  processing_status: AProspectProcessingStatuses
  status?: AProspectStatuses
  disapprove_reason?: AProspectDisapproveReasons | null
  disapprove_reason_text?: string | null
  notes?: string | null
  source?: string | null
  country: ACountry
  permissions?: APermissions
}

export interface ASaasCompanyUserProspect {
  id: number
  name: string
  /** @format date-time */
  created_at: string
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  approved: boolean
  prioritized: boolean
  crm_synced?: boolean
  retired: boolean
  /** @format date-time */
  approved_at?: string | null
  approval_status: AProspectApprovalStatuses
  processing_status: AProspectProcessingStatuses
  status?: AProspectStatuses
  disapprove_reason?: AProspectDisapproveReasons | null
  disapprove_reason_text?: string | null
  notes?: string | null
  source?: string | null
  country: ACountry
  intended_for?: ASalesPerson | null
  assigned_to?: ASalesPerson | null
  permissions?: APermissions
  warnings?: string[] | null
  is_potential_duplicate: boolean
}

export interface ACompanyUserProspect {
  id: number
  organization_name: string
  city?: string | null
  position?: string | null
  segment?: string[] | null
  website?: string | null
  approved: boolean
  prioritized: boolean
  added_by: string
  /** @format date-time */
  approved_at?: string | null
  status?: AProspectStatuses
  approval_status: AProspectApprovalStatuses
  processing_status: AProspectProcessingStatuses
  disapprove_reason?: AProspectDisapproveReasons | null
  disapprove_reason_text?: string | null
  country: ACountry
  intended_for?: ASalesPerson | null
  assigned_to?: ASalesPerson | null
  warnings?: string[] | null
  is_potential_duplicate: boolean
  permissions?: APermissions
}

export interface AProspectAttributes {
  organization_name: string
  city?: string | null
  contact_person_first_name?: string | null
  contact_person_last_name?: string | null
  position?: string | null
  segment?: string[] | null
  phone_number?: string | null
  organization_phone_number?: string | null
  additional_phone_number?: string | null
  website?: string | null
  organization_number?: string | null
  email?: string | null
  source?: string | null
  notes?: string | null
  country_id: number
  retired?: boolean | null
}

export interface ACompanyUserSalesCycleProspects {
  id: number
  organization_name: string
  position?: string | null
  status: ASalesCycleProspectStatuses | null
  stage: ASalesCycleFilterStages | null
  notes: string | null
  deal_details?: string | null
  meeting_at?: string | null
  lost_reason?: string | null
  segment: string[] | null
  crm_url?: string | null
  permissions?: APermissions
}

export enum AProspectDisapproveReasons {
  Cooperation = "cooperation",
  Competition = "competition",
  UnlikelyCooperate = "unlikely_cooperate",
  WrongSegment = "wrong_segment",
  UnfitSize = "unfit_size",
  NotContact = "not_contact",
  Other = "other",
}

export enum AProspectStatuses {
  SalesPersonDraft = "sales_person_draft",
  Draft = "draft",
  ReadyToApprove = "ready_to_approve",
  Finished = "finished",
}

export enum AProspectApprovalStatuses {
  NotApproved = "not_approved",
  Approved = "approved",
  Disapproved = "disapproved",
}

export enum AProspectProcessingStatuses {
  Waiting = "waiting",
  Assigned = "assigned",
  Used = "used",
}

export enum AProspectSortDirectionValues {
  Asc = "asc",
  Desc = "desc",
}

export enum AProspectSortByValues {
  OrganizationName = "organization_name",
  Position = "position",
  City = "city",
  Email = "email",
}

export enum AProspectQueryStatusValues {
  Rejected = "rejected",
  WithWarnings = "with_warnings",
}

export enum ACompanyProspectSortByValues {
  OrganizationName = "organization_name",
  City = "city",
  Prioritized = "prioritized",
}

export enum ASalesCyclesProspectValues {
  OrganizationName = "organization_name",
}

export enum ASalesCycleProspectStages {
  FirstOutreach = "First outreach",
  SecondOutreach = "Second outreach",
  PresentationDemo = "Presentation / Demo",
  Followup1 = "Followup1",
  Followup2 = "Followup2",
  Followup3 = "Followup3",
  FutureContact = "Future contact",
  PriceOffer = "Price offer",
}

export enum ASalesCycleFilterStages {
  FirstOutreach = "First outreach",
  SecondOutreach = "Second outreach",
  PresentationDemo = "Presentation / Demo",
  Followup1 = "Followup1",
  Followup2 = "Followup2",
  Followup3 = "Followup3",
  FutureContact = "Future contact",
  PriceOffer = "Price offer",
  EmailingStage = "Emailing stage",
  CallingStage = "Calling stage",
  MeetingStage = "Meeting stage",
  Future = "Future",
}

export enum ASalesCycleProspectStatuses {
  Won = "won",
  Lost = "lost",
  Opened = "opened",
}

export enum ASalesCycleProspectAutocompleteFields {
  Segment = "segment",
}

export enum AProspectLastChangeRanges {
  TwentyFourHours = "twenty_four_hours",
  ThreeDays = "three_days",
  SevenDays = "seven_days",
  FourteenDays = "fourteen_days",
  TwentyOneDays = "twenty_one_days",
}

export interface ASaasCompanyUser {
  id: number
  email: string
  first_name: string
  last_name: string
  type: AUserTypes
  phone_number?: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  company: ACompany
  owned_company?: ACompany | null
}

export interface ASaasCompanyUserSalesCycle {
  id: number
  name: string
  status?: ASalesCycleStatuses
  website?: string | null
  segment?: string | null
  /** @format date-time */
  created_at?: string
  duration_in_months?: number
  attachments?: AAttachments | null
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export interface ASaasCompanyProspectsSalesCycle {
  id: number
  name: string
  company?: ACompanyWithPermissions
  sales_cycle_iterations: {
    id: number
    /**
     * @format date
     * @example "2024-02-01"
     */
    start_date: string
    prospects_mandatory_count: number
    prospects_approved_count: number
    prospects_finished_count: number
    assignments: {
      id: number
      prospects_mandatory_count: number
      prospects_approved_count: number
      prospects_finished_count: number
      sales_person: AAdminsProspectsAssignmentSalesPerson | null
      permissions?: APermissions
    }[]
  }[]
  permissions?: APermissions
}

export interface ASaasSalesPersonSalesCycle {
  id: number
  name: string
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export interface ASaasSalesPersonDashboardSalesCycle {
  id: number
  name: string
  statistics: {
    emails_sent_activities?: number | null
    calls_made_activities?: number | null
    meetings_done_activities?: number | null
    won?: number | null
  }
  assignments?: ASaasSalesPersonDashboardAssignments[]
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export interface ASaasCompanyUserDashboardSalesCycle {
  id: number
  name: string
  status?: ASalesCycleStatuses
  website?: string | null
  segment?: string | null
  /** @format date-time */
  created_at?: string
  duration_in_months?: number
  statistics: {
    emails_sent_activities?: number | null
    calls_made_activities?: number | null
    meetings_done_activities?: number | null
    won?: number | null
  }
  assignments?: ASaasCompanyUsersDashboardAssignments[]
  attachments?: AAttachments | null
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export enum ASaasSalesCycleSortDirectionValues {
  Asc = "asc",
  Desc = "desc",
}

export enum ASaasSalesCycleSortByValues {
  CreatedAt = "created_at",
  Name = "name",
}

export interface ASaasSalesPerson {
  id: number
  email: string
  first_name: string
  last_name: string
  type: AUserTypes
  phone_number?: string | null
  company: ACompany
  country: ACountry
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
}

export interface ASalesCycleAttributes {
  country_id: number
  ideal_customer_profile?: ASalesCycleIdealCustomerProfiles
  decision_maker_persona?: string[]
  segment?: ASalesCycleSegment
  /** @format file */
  price_list?: File
  website?: string
  elevator_pitch?: string
  story_description?: string
  product_benefits?: string[]
  product_uniques?: string[]
  references?: string[]
  years_of_market?: ASalesCycleYearsOfMarkets
  successful_events?: string[]
  appendable_attachments?: File[]
  sales_person_offline_required?: boolean
  sales_person_online_required?: boolean
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  products_attributes?: {
    id?: number | null
    commission: number
    commission_type: ACommissionTypes
    commission_value_type: ACommissionValueTypes
    goal: AGoals
    commission_note?: string | null
  }[]
}

export interface ASalesCycle {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  strategy_finalized?: boolean
  sales_talent_prospects_enabled: boolean
  hide_meeting_activities: boolean
  key_details_finalized?: boolean
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  attachments?: AAttachments | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  permissions?: APermissions
}

export interface ASalesCycleSummary {
  id: number
  name: string
  status: string
}

export enum ASalesCycleSegment {
  Fmcg = "fmcg",
  Finance = "finance",
  Retail = "retail",
  FoodIndustry = "food_industry",
  Marketing = "marketing",
  ECommerce = "e_commerce",
  Corporate = "corporate",
}

export enum ASalesCycleStatuses {
  Draft = "draft",
  Created = "created",
  Ongoing = "ongoing",
  Paused = "paused",
  Finished = "finished",
}

export enum ASalesCycleIdealCustomerProfiles {
  Small = "small",
  Middle = "middle",
  Corporation = "corporation",
}

export enum ASalesCycleYearsOfMarkets {
  Zero = "zero",
  One = "one",
  Two = "two",
  ThreeAndMore = "three_and_more",
}

export interface ACompanyUserSalesCycle {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  subscription?: ASubscription | null
  permissions?: APermissions
  attachments?: AAttachments | null
  /**
   * @format date
   * @example "2023-05-31"
   */
  first_start_date: string
  outreach: number
  activities: number
  closed_deals: number
  sales_person_closed_deals: number
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
  sales_strategy_url: string | null
  sales_cycle_iterations: {
    id: number
    /**
     * @format date
     * @example "2023-05-31"
     */
    start_date: string
    status: ACompanyUserSalesCycleStatusEnum
  }[]
  sales_person: ACompanyUserSalesPerson | null
}

export interface ACompanyUserSalesCycleDetails {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  attachments?: AAttachments | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  subscription?: ASubscription | null
  permissions?: APermissions
  /**
   * @format date
   * @example "2023-05-31"
   */
  first_start_date: string
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  sales_strategy_url: string | null
  this_month_iteration_id: number
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
}

export interface AAdminSalesCycleDetails {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  attachments?: AAttachments | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required?: boolean
  sales_person_online_required?: boolean
  price_list_url?: string | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  subscription?: ASubscription | null
  permissions?: APermissions
  /**
   * @format date
   * @example "2023-05-31"
   */
  first_start_date: string
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  sales_strategy_url: string | null
  this_month_iteration_id: number
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
}

export interface ACompanyUserSalesCycleSalesNews {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  attachments?: AAttachments | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  permissions?: APermissions
  statistics: ACompanyUsersScopedStatistics
  assignments?: ACompanyUserSalesNewsAssignment[]
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  iteration_id?: number | null
  /** @format date */
  iteration_start_date?: string | null
}

export interface AAdminSalesCycleSalesNews {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  attachments?: AAttachments | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required?: boolean
  sales_person_online_required?: boolean
  price_list_url?: string | null
  key_details_finalized?: boolean
  activity_feeds_administration_url: string
  sales_talent_prospects_enabled: boolean
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  permissions?: APermissions
  statistics: ACompanyUsersScopedStatistics
  assignments?: ACompanyUserSalesNewsAssignment[]
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  iteration_id?: number | null
  /** @format date */
  iteration_start_date?: string | null
}

export interface ASalesPersonSalesCycleSalesNews {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  permissions?: APermissions
  statistics?: ACompanyUsersScopedStatistics | null
  attachments?: AAttachments | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  iteration_id?: number | null
  /** @format date */
  iteration_start_date?: string | null
}

export interface ASalesPersonSalesCycleDetails {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  attachments?: AAttachments | null
  segment_note?: string | null
  sales_scripts?: string | null
  articles_links?: string | null
  additional_strategy_description?: string | null
  sales_person_requirements_note?: string | null
  sales_person_offline_required?: boolean
  sales_person_online_required?: boolean
  price_list_url?: string | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  default_currency?: ACurrency | null
  subscription?: ASubscription | null
  permissions?: APermissions
  /**
   * @format date
   * @example "2023-05-31"
   */
  first_start_date: string
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  sales_strategy_url: string | null
  this_month_iteration_id: number
  key_details_finalized?: boolean
  sales_talent_prospects_enabled: boolean
}

export interface AAdminsProspectsSalesCycle {
  id: number
  name: string
  company?: ACompanyWithPermissions
  sales_cycle_iterations: {
    id: number
    /**
     * @format date
     * @example "2024-02-01"
     */
    start_date: string
    /**
     * @format date
     * @example "2024-01-28"
     */
    deadline_approval_date: string
    /**
     * @format date
     * @example "2024-01-25"
     */
    deadline_add_prospects_date?: string
    prospects_mandatory_count: number
    prospects_draft_count: number
    prospects_ready_to_approve_count: number
    prospects_approved_count: number
    prospects_finished_count: number
    prospects_with_warning_count: number
    assignments: {
      id: number
      prospects_mandatory_count: number
      prospects_draft_count: number
      prospects_ready_to_approve_count: number
      prospects_approved_count: number
      prospects_finished_count: number
      prospects_with_warning_count: number
      package_name: string | null
      /** @format date-time */
      prospects_sent_for_approval_at: string | null
      sales_person: AAdminsProspectsAssignmentSalesPerson | null
      permissions?: APermissions
    }[]
  }[]
  permissions?: APermissions
}

export interface ASalesPersonProspectsSalesCycle {
  id: number
  name: string
  company?: ACompanyWithPermissions
  sales_cycle_iterations: {
    id: number
    /**
     * @format date
     * @example "2024-02-01"
     */
    start_date: string
    /**
     * @format date
     * @example "2024-01-28"
     */
    deadline_approval_date: string
    /**
     * @format date
     * @example "2024-01-25"
     */
    deadline_add_prospects_date?: string
    prospects_mandatory_count: number
    prospects_draft_count: number
    prospects_ready_to_approve_count: number
    prospects_approved_count: number
    prospects_finished_count: number
    assignments: {
      id: number
      prospects_mandatory_count: number
      prospects_draft_count: number
      prospects_ready_to_approve_count: number
      prospects_approved_count: number
      prospects_finished_count: number
      package_name: string | null
      /** @format date-time */
      prospects_sent_for_approval_at: string | null
      permissions?: APermissions
    }[]
  }[]
  permissions?: APermissions
}

export interface ACompanyProspectsSalesCycle {
  id: number
  name: string
  sales_cycle_iterations: {
    id: number
    /**
     * @format date
     * @example "2024-02-01"
     */
    start_date: string
    /**
     * @format date
     * @example "2024-01-28"
     */
    deadline_approval_date: string
    prospects_mandatory_count: number
    prospects_ready_to_approve_count: number
    prospects_approved_count: number
    assignments: {
      id: number
      prospects_mandatory_count: number
      prospects_ready_to_approve_count: number
      prospects_approved_count: number
      maximum_rejections: number
      rejections_limit: number
      package_name: string | null
      sales_person: AAdminsProspectsAssignmentSalesPerson | null
      permissions?: APermissions
    }[]
  }[]
  permissions?: APermissions
}

export interface ACompanyUsersScopedStatistics {
  activities?: number | null
  clients_reached?: number | null
  emails_sent?: number | null
  calls_made?: number | null
  meetings_done?: number | null
  opportunities?: number | null
  won?: number | null
  lost?: number | null
}

export interface ACompanyUsersTrendsStatistics {
  counters: ACompanyUsersScopedStatistics
  trends?: ACompanyUsersScopedStatistics | null
}

export interface ACompanyUserSalesCycleReporting {
  id: number
  status: ASalesCycleStatuses
  hide_meeting_activities: boolean
  max_assignments_count_per_iteration: number
  name: string
  company_name: string
  sales_cycle_iterations: {
    id: number
    /** @format date */
    start_date: string
    /** @format date */
    end_date: string
    finished_report: boolean
    download_report_url?: string | null
    report_sent_by_super_admin: boolean
  }[]
  assignments: ACompanyUserAssignmentReporting[]
  statistics?: ACompanyUsersTrendsStatistics | null
  permissions: APermissions
}

export interface ASaasCompanyUserSalesCycleReporting {
  id: number
  status: ASalesCycleStatuses
  hide_meeting_activities: boolean
  max_assignments_count_per_iteration: number
  name: string
  company_name: string
  sales_cycle_iterations: {
    id: number
    /** @format date */
    start_date: string
    /** @format date */
    end_date: string
    finished_report: boolean
    download_report_url?: string | null
  }[]
  assignments: ACompanyUserAssignmentReporting[]
  statistics?: ACompanyUsersTrendsStatistics | null
  permissions: APermissions
}

export interface ACompanyUserDashboardActiveSalesCycle {
  id: number
  name: string
  /** @format date */
  first_selling_date: string
  /** @format date */
  current_selling_date: string
  opportunities?: number
  activities?: number
  assignments?: {
    id: number
    reaches: number
    status: AAssignmentStatuses
    sales_person?: ACompanyUserDashboardSalesPerson | null
  }[]
  status: ASalesCycleIterationStatuses
  country: ACountry
  company: ACompanyWithPermissions
  order?: ACompanyUserDashboardOrder | null
  permissions?: APermissions
}

export interface ACompanyUserDashboardDraftedSalesCycle {
  id: number
  name: string
  key_details_finalized: boolean
  strategy_finalized: boolean
  strategy_touched: boolean
  onboarding_status: AOnboardingStatuses
  country: ACountry
  company: ACompanyWithPermissions
  order?: ACompanyUserDashboardOrder | null
  permissions?: APermissions
}

export interface ACompanyUserDashboardFinishedSalesCycle {
  id: number
  name: string
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export interface ACompanyUserDashboardPausedSalesCycle {
  id: number
  name: string
  country: ACountry
  company: ACompanyWithPermissions
  permissions?: APermissions
}

export interface AWelcomeScreenSalesCycle {
  id: number
  name: string
  website?: string | null
  elevator_pitch: string
  story_description?: string
  product_benefits: string[]
  product_uniques?: string[]
  ideal_customer_profile: ASalesCycleIdealCustomerProfiles
  decision_maker_persona: string[]
  segment: ASalesCycleSegment
  references?: string[]
  years_of_market: ASalesCycleYearsOfMarkets
  successful_events?: string[] | null
  /**
   * @format date
   * @example "2023-04-28"
   */
  started_at: string | null
  price_list_url?: string | null
  strategy_finalized?: boolean
  hide_meeting_activities: boolean
  sales_talent_prospects_enabled: boolean
  key_details_finalized?: boolean
  strategy_touched?: boolean
  sales_scripts?: string | null
  additional_strategy_description?: string | null
  sales_person_offline_required: boolean
  sales_person_online_required: boolean
  segment_note?: string | null
  sales_person_requirements_note?: string | null
  articles_links?: string | null
  attachments?: AAttachments | null
  status?: ASalesCycleStatuses
  products: AProducts
  country: ACountry
  permissions?: APermissions
}

export interface ASalesCycleIteration {
  id: number
  /**
   * @format date
   * @example "2023-05-31"
   */
  start_date: string
  /**
   * @format date
   * @example "2023-05-31"
   */
  end_date: string
  company_name?: string
  sales_cycle: ASalesCycle
  assignments: AAssignmentShort[]
  permissions?: APermissions
}

export interface ACompanyUserSalesCycleIteration {
  id: number
  /**
   * @format date
   * @example "2023-05-31"
   */
  start_date: string
  /**
   * @format date
   * @example "2023-05-31"
   */
  end_date: string
  assignments: {
    id?: number
    reaches?: number
    outreach?: number
    statistics?: ACompanyUsersScopedStatistics
    sales_person?: ACompanyUserSalesPerson | null
    permissions?: APermissions
  }[]
  reaches: number
  outreach: number
  activities: number
  statistics: {
    emails?: number
    calls?: number
    meetings?: number
    closed_deals?: number
    rejections?: number
    clients?: number
    price_offers?: number
  }
  permissions?: APermissions
}

export interface ACompanyUserSalesCycleIterationReporting {
  id: number
  assignments: ACompanyUserAssignmentReporting[]
  statistics?: ACompanyUsersTrendsStatistics | null
  permissions: APermissions
}

export interface ASalesPlan {
  id: number
  name: string
  company_name: string
  current_value: number
  duration_in_months: number | null
  /** @format date-time */
  created_at?: string
  status: ASalesPlanStatusEnum
  current_assignments: AAssignmentShort[] | null
  country: ACountry
  currency: ACurrency
  permissions?: APermissions
}

export enum ASalesPlanSortDirectionValues {
  Asc = "asc",
  Desc = "desc",
}

export enum ASalesPlanSortByValues {
  CreatedAt = "created_at",
  CurrentValue = "current_value",
}

export enum ASalesPlanQueryStatusValues {
  Starting = "starting",
  InProgress = "in_progress",
  Stopped = "stopped",
  Declined = "declined",
}

export interface ASalesStrategy {
  id: number
  name: string
}

export interface ASalesStrategyWithPrice {
  id: number
  name: string
  price: number
  currency: ACurrency
  permissions?: APermissions
}

export interface ASettings {
  countries: ACountry[]
  currencies: ACurrency[]
  payments: {
    publishable_api_key: string
    vat_required_country_codes: string[]
  }
  permissions: {
    sales_cycle?: AResourceLevelPermissions
    country?: AResourceLevelPermissions
    currency?: AResourceLevelPermissions
    assignment_offer?: AResourceLevelPermissions
  }
  welcome_screen: boolean
  user: AUsersCompanyUser | AUsersSalesPerson | ASaasCompanyUser | ASaasSalesPerson
  masqueraded?: boolean
}

export enum ADashboardStages {
  FirstOutreach = "first_outreach",
  SecondOutreach = "second_outreach",
  PresentationDemo = "presentation_demo",
  Closing = "closing",
}

export interface ASubscription {
  id: number
  status: ASubscriptionStatusEnum
  recurrence: ASubscriptionRecurrenceEnum
  /** @format date-time */
  cancel_requested_at: string | null
  /** @format date */
  scheduled_cancel_date: string | null
  /** @format date */
  nearest_cancel_date: string | null
  /** @format date */
  nearest_service_termination_date: string | null
  /** @format date */
  service_termination_date: string | null
  /** @format date-time */
  activated_at: string | null
}

export interface ATransaction {
  order_id: number
  payment_id: number
  invoice_number?: string | null
  /** @format date-time */
  paid_at?: string | null
  payment_status: ATransactionPaymentStatusEnum
  payment_method: APaymentMethods
  amount: number
  external_invoice_url: string
  sales_cycle: ASalesCycleSummary
  currency: ACurrency
}

export interface AImportedByUser {
  id?: number
  email?: string
  type?: AUserTypes
  first_name?: string | null
  last_name?: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  permissions?: APermissions
}

export interface ACompanyUserLogin {
  company_user?: {
    id?: number
    email?: string
    name?: string
    company_name?: string
    tac_agreement?: boolean
    company?: object
  }
}

export interface ASalesPersonLogin {
  sales_person?: {
    id?: number
    email?: string
    first_name?: string
    last_name?: string
    name?: string
    tac_agreement?: boolean
  }
}

export interface ASalesPerson {
  id?: number
  email?: string
  first_name?: string
  last_name?: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  video_introduction_viewed?: boolean
  permissions?: APermissions
}

export interface ACompanyUserSalesPerson {
  id: number
  first_name: string
  last_name: string
  profile_picture_url: string | null
  profile_picture_thumbnail_url: string | null
  permissions?: APermissions
}

export interface AMatchingSalesPerson {
  id: number
  email: string
  first_name: string
  last_name: string
  profile_picture_url: string | null
  profile_picture_thumbnail_url: string | null
  capacity: number
  remaining_capacity: number
  years_of_experience: number | null
  segment: ASalesPersonSegments
  accepted: boolean | null
  worked_on_previous_iteration: boolean
  worked_with_same_company: boolean
  country: ACountry
  permissions?: APermissions
}

export interface ASalesPersonProfile {
  id: number
  email: string
  first_name: string
  last_name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  video_introduction_viewed?: boolean
  permissions?: APermissions
  profile: {
    email: string
    first_name: string
    last_name: string
    capacity: number
    phone_number?: string | null
    accepted: boolean
    linkedin_profile?: string | null
    about?: string | null
    years_of_experience?: number | null
    profile_picture_url?: string | null
    profile_picture_thumbnail_url?: string | null
    resume_url?: string | null
    /** @format date-time */
    accepted_at?: string | null
    segment?: any
    country?: ACountry
    native_language?: ALanguage | null
    spoken_languages?: ALanguage[]
    permissions?: APermissions
  }
  learning_materials: {
    sales_person_education_finished: boolean
    closing_deals_education_finished: boolean
    onboarding_education_finished: boolean
    video_upload_finished: boolean
  }
}

export interface APublicSalesPerson {
  id: number
  first_name: string
  about?: string | null
  years_of_experience?: number | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  country: ACountry | null
  segment?: any
  native_language?: ALanguage | null
  spoken_languages: ALanguage[]
  permissions?: APermissions
}

export interface AAcceptSalesPerson {
  accepted: boolean
}

export interface ASalesPersonAttributes {
  profile?: {
    email?: string
    first_name?: string
    last_name?: string
    linkedin_profile?: string
    segment?: any
    about?: string
    years_of_experience?: number
    capacity?: number
    /** @format binary */
    profile_picture?: File
    /**
     * Upload CV file
     * @format binary
     */
    resume?: File
    phone_number?: string
    /** Country ID */
    country_id?: number
    /** Language ID */
    native_language_id?: number
    spoken_language_ids?: number[]
    video_introduction_viewed?: boolean
  }
  learning_materials?: {
    sales_person_education_finished?: boolean
    closing_deals_education_finished?: boolean
    onboarding_education_finished?: boolean
    video_upload_finished?: boolean
  }
}

export interface ASalesPersonDashboard {
  id: number
  assignment_offers: {
    id: number
    status: ASalesPersonDashboardStatusEnum
    /** @format date */
    valid_until: string
    /** @format date */
    starting_at: string
    reward_value: number
    reaches?: number
    minimum_meetings?: number
    currency: ACurrency
    products: AProduct[]
    sales_cycle: {
      id: number
      name: string
      segment: ASalesCycleSegment
      decision_maker_persona: string[]
      ideal_customer_profile: ASalesCycleIdealCustomerProfiles
      worked_with_same_company: boolean
      sales_strategy_url?: string | null
      price_list_url?: string | null
      elevator_pitch?: string | null
      company: AAssignmentOfferCompany
      country: ACountry
      permissions?: APermissions
    }
    permissions?: APermissions
  }[]
  sales_cycles: ASalesPersonDashboardSalesCyclesDetail
  permissions?: APermissions
  business_data_filled: boolean
}

export type ASalesPersonDashboardSalesCyclesDetail = {
  id: number
  name: string
  assigned_to_current_iteration: boolean
  segment: ASalesCycleSegment
  country: ACountry
  default_currency?: ACurrency | null
  status: ASalesCycleStatuses
  iteration_status: ASalesCycleIterationStatuses
  reward_value: number | null
  reaches: number | null
  minimum_meetings?: number | null
  month?: number
  year?: number
  sales_strategy_url: string | null
  price_list_url?: string | null
  products?: AProduct[]
  company_name: string
  statistics: {
    emails_sent: number
    emails_sent_activities: number
    emails_sent_total: number
    callings_done: number
    callings_done_activities: number
    callings_done_total: number
    meetings_happened: number
    meetings_happened_activities: number
    meetings_happened_total: number
    rejections: number
    deals: number
    price_offers: number
    outreach: number
    clients_in_pipeline: number
    current_stage: ADashboardStages
  } | null
  permissions?: APermissions
}[]

export interface AUser {
  id: number
  email: string
  type: AUserTypes
  first_name: string | null
  last_name: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
}

export interface AUsersCompanyUser {
  id: number
  email: string
  first_name: string
  last_name: string
  type: AUserTypes
  phone_number?: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  company: ACompany
  hero_section_visible: boolean
}

export interface AUsersSalesPerson {
  id: number
  email: string
  first_name: string
  last_name: string
  type: AUserTypes
  phone_number?: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  video_introduction_viewed: boolean
  leads_enabled?: boolean
  hero_section_visible: boolean
}

export enum AUserTypes {
  SalesPerson = "SalesPerson",
  CompanyUser = "CompanyUser",
  Admin = "Admin",
  SaasCompanyUser = "SaasCompanyUser",
  SaasSalesPerson = "SaasSalesPerson",
}

export type ASalesPersonSegments = ASalesPersonSegmentsEnum[]

export interface AAssignmentOfferCompany {
  name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  permissions?: APermissions
}

export interface ACompanyUserCycleSalesPerson {
  id: number
  first_name: string
  last_name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  permissions?: APermissions
}

export interface AAdminsProspectsAssignmentSalesPerson {
  id: number
  first_name: string
  last_name: string
  profile_picture_url: string | null
  profile_picture_thumbnail_url: string | null
}

export interface ACompanyUserSalesCycleSalesPerson {
  id: number
  first_name: string
  rating: number
  years_of_experience?: number | null
  native_language_id?: number | null
  spoken_language_ids?: number[] | null
  embed_video_id?: string | null
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  country?: ACountry
  packages?: APackageWithPrice[]
  permissions?: APermissions | null
}

export interface ACompanyUserDashboardSalesPerson {
  id: number
  first_name: string
  last_name: string
  profile_picture_url?: string | null
  profile_picture_thumbnail_url?: string | null
  permissions?: APermissions | null
}

export enum ASalesCycleIterationStatuses {
  Active = "active",
  Finished = "finished",
  Declined = "declined",
  Matching = "matching",
  Acceptance = "acceptance",
  ReadyToStart = "ready_to_start",
  Created = "created",
}

export enum AOwnershipLevels {
  CloseRocket = "close_rocket",
  Company = "company",
  SalesPerson = "sales_person",
}

export interface AWelcomeScreen {
  onboarding_status: AOnboardingStatuses
  sales_people: ACompanyUserSalesCycleSalesPerson[]
  sales_cycle?: AWelcomeScreenSalesCycle | null
  order?: AOrder | null
  requested_sales_person_assignments?: {
    id: number
    reaches: number
    status: string
    sales_person?: ACompanyUserSalesCycleSalesPerson | null
  }[]
  permissions?: APermissions
}

export enum AOnboardingStatuses {
  ShareKnowledge = "share_knowledge",
  AddKeyDetails = "add_key_details",
  RequestSalesTalents = "request_sales_talents",
}

export enum AProspectActivityEventEnum {
  EmailSent = "email_sent",
  CallingDone = "calling_done",
  MeetingHappened = "meeting_happened",
  Won = "won",
  Lost = "lost",
  PriceOfferSent = "price_offer_sent",
}

export enum AOrderOrderTypeEnum {
  Subscription = "subscription",
  Commission = "commission",
}

export enum AVoucherCodeResultStatusEnum {
  Valid = "valid",
  Invalid = "invalid",
}

export enum ACompanyUserSalesCycleStatusEnum {
  Finished = "finished",
  Active = "active",
  Incoming = "incoming",
}

export enum ASalesPlanStatusEnum {
  Starting = "starting",
  InProgress = "in_progress",
  Stopped = "stopped",
  Declined = "declined",
}

export enum ASubscriptionStatusEnum {
  Incomplete = "incomplete",
  Active = "active",
  Paused = "paused",
  Canceled = "canceled",
  PastDue = "past_due",
  Failed = "failed",
}

export enum ASubscriptionRecurrenceEnum {
  Month = "month",
  Year = "year",
}

export enum ATransactionPaymentStatusEnum {
  Awaiting = "awaiting",
  AuthorizationRequired = "authorization_required",
  Paid = "paid",
  Failed = "failed",
}

export enum ASalesPersonDashboardStatusEnum {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Expired = "expired",
  Canceled = "canceled",
  PreMatched = "pre_matched",
}

export enum ASalesPersonSegmentsEnum {
  Fmcg = "fmcg",
  Finance = "finance",
  Retail = "retail",
  FoodIndustry = "food_industry",
  Marketing = "marketing",
  ECommerce = "e_commerce",
  Corporate = "corporate",
}

export interface ALeadsAssignmentsLeadsForAssignmentDetailData {
  leads: ALeadWithAssignmentProspect[]
  meta?: {
    pagination?: APagination
    total_leads_for_assignment: number
    added_prospects_count: number
  }
}

export interface ALeadsAssignmentsAddProspectFromLeadCreateData {
  lead?: ALeadWithAssignmentProspect
  meta?: AMeta
}

export interface AAdminLeadsAssignmentsAddProspectFromLeadCreateData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export interface AAdminLeadsAssignmentsDeleteProspectFromLeadDeleteData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export enum AProspectImportOwnershipLevelEnum {
  CloseRocket = "close_rocket",
  Company = "company",
  SalesPerson = "sales_person",
}

export type AProspectsAssignmentsUploadProspectsCreateData = any

export interface AProspectsAssignmentsProspectsPrepareToApproveDetailData {
  prospects?: AAdminProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsWaitingToApproveDetailData {
  prospects?: AAdminProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsFinishedDetailData {
  prospects?: AAdminProspect[]
  meta?: {
    pagination?: APagination
    sync_crm_button?: boolean
  }
}

export type AProspectsAssignmentsSendForApprovalCreateData = any

export type AProspectsAssignmentsFinishApprovalCreateData = any

export type AProspectsAssignmentsSyncWithCrmCreateData = any

export type AProspectsAssignmentsApproveProspectsCreateData = any

export type AProspectsAssignmentsAcceptWarningsCreateData = any

export interface AProspectsSalesCyclesDetailData {
  sales_cycle: AAdminsProspectsSalesCycle
  meta?: AMeta
}

export interface AProspectsSalesCyclesLifetimeDetailData {
  prospects: AAdminProspect[]
  meta?: {
    pagination?: APagination
    lifetime_sales_people?: AUsersSalesPerson[]
  }
}

export interface AProspectsListData {
  prospects: AAdminProspect[]
  meta?: AMeta
}

export interface AProspectsExtendedIndexListData {
  sales_cycle_prospects: {
    /** Prospects assigned to the assignment (with sales person) */
    assigned_count: number
    /** Count of approved prospects */
    approved_count: number
    /** Prioritized prospects */
    prioritized_count: number
    /** Sales cycle prospects count */
    prospects_count: number
    /** Disapproved prospects */
    disapproved_count: number
    /** Prospects waiting for any approval change */
    not_approved_count: number
    /** Approved prospects waiting for the assignment or already assigned */
    approved_to_be_used_count: number
    /** Assigned or already used prospects during the selling with any approval status */
    assigned_or_used_count: number
    company: ACompanyWithPermissions
    prospects: AAdminProspect[]
    permissions?: APermissions
  }
  meta?: AMeta
}

export interface ASalesCyclesActivityFeedsDetailData {
  activity_feeds: AActivityFeed[]
  meta?: {
    pagination?: APagination
  }
}

export interface ASalesCyclesAssignmentsReportingDetailData {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailData {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export type ASalesCyclesSalesCycleIterationsFinalizeReportCreateData = any

export interface ASalesCyclesSalesCycleIterationsReportingDetailData {
  sales_cycle_iteration?: ACompanyUserSalesCycleIterationReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesNewsDetailData {
  sales_cycle?: AAdminSalesCycleSalesNews
  meta?: AMeta
}

export interface ASalesCyclesDetailsDetailData {
  sales_cycle?: AAdminSalesCycleDetails
  meta?: AMeta
}

export interface ASalesCyclesReportingDetailData {
  sales_cycle?: ACompanyUserSalesCycleReporting
  meta?: AMeta
}

export interface ASalesCyclesNewsAndInsightsDetailData {
  sales_cycle_iteration?: {
    id?: number | null
    month?: string | null
    news?: string | null
    insights?: string | null
  }
  meta?: AMeta
}

export interface ASalesCyclesActivitiesChartDetailData {
  chart_data?: AActivitiesChartData | null
}

export interface ASalesCyclesClientsReachedChartDetailData {
  chart_data?: AClientsReachChartData | null
}

export interface ASalesCyclesActivityOccurrencesChartDetailData {
  chart_data?: AActivityOccurrencesChartData | null
}

export interface ASalesCyclesOpportunitiesChartDetailData {
  chart_data?: AOpportunitiesChartData | null
}

export interface ASalesCyclesProspectsDetailData {
  prospects: ACompanyUserSalesCycleProspects[]
  meta?: {
    pagination?: APagination
    total_prospects_in_db: number
    stages_used: ASalesCycleFilterStages[]
  }
}

export interface ASalesCyclesProspectsAutocompleteValuesDetailData {
  values: {
    name: string
    value: string
  }[]
}

export interface AAssignmentOffersListData {
  assignment_offers: AAssignmentOffer[]
  meta?: AMeta
}

export interface AAssignmentOffersCreateData {
  assignment_offer: AAssignmentOffer
  meta?: AMeta
}

export interface AAssignmentOffersDetailData {
  assignment_offer: AAssignmentOffer
  meta?: AMeta
}

export interface AAssignmentOffersDeleteData {
  assignment_offer: AAssignmentOffer
  meta?: AMeta
}

export interface AAcceptPartialUpdateData {
  assignment_offer: AAssignmentOffer
  meta?: AMeta
}

export interface ARejectPartialUpdateData {
  assignment_offer: AAssignmentOffer
  meta?: AMeta
}

export interface AAssignmentsListData {
  assignments: AAssignmentShort[]
  meta?: AMeta
}

export interface AAssignmentsDetailData {
  assignment: AAssignment
  meta?: AMeta
}

export interface ARevokePartialUpdateData {
  assignment?: AAssignment
  meta?: AMeta
}

export interface ACompaniesDetailData {
  company: ACompanyProfile
}

/** To specify sections returned in response */
export enum ACompaniesDetailParamsSectionEnum {
  All = "all",
  Profile = "profile",
  Members = "members",
  Transactions = "transactions",
}

export type ACompaniesPartialUpdateData = any

/** To specify sections returned in response */
export enum ACompaniesPartialUpdateParamsSectionEnum {
  All = "all",
  Profile = "profile",
  Members = "members",
  Transactions = "transactions",
}

export type AActivityFeedsLikePartialUpdateData = any

export type AActivityFeedsDislikePartialUpdateData = any

export interface ADashboardDetailData {
  dashboard: ACompanyUserDashboard
  meta?: AMeta
}

export interface ADashboardActiveSalesCyclesDetailData {
  sales_cycles: ACompanyUserDashboardActiveSalesCycle[]
  meta?: AMeta
}

export interface ADashboardDraftedSalesCyclesDetailData {
  sales_cycles: ACompanyUserDashboardDraftedSalesCycle[]
  meta?: AMeta
}

export interface ADashboardFinishedSalesCyclesDetailData {
  sales_cycles: ACompanyUserDashboardFinishedSalesCycle[]
  meta?: AMeta
}

export interface ADashboardPausedSalesCyclesDetailData {
  sales_cycles: ACompanyUserDashboardPausedSalesCycle[]
  meta?: AMeta
}

export type AProspectsAssignmentsProspectDisapprovePartialUpdateData = any

export interface AProspectsAssignmentsProspectsWaitingToApproveDetailResult {
  prospects?: ACompanyUserProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsFinishedDetailResult {
  prospects?: ACompanyUserProspect[]
  meta?: AMeta
}

export type AProspectsAssignmentsApproveProspectsCreateResult = any

export interface AProspectsSalesCyclesDetailResult {
  sales_cycle: ACompanyProspectsSalesCycle
  meta?: AMeta
}

export interface AProspectsListResult {
  prospects: ACompanyUserProspect[]
  meta?: AMeta
}

export interface AProspectsExtendedIndexListResult {
  sales_cycle_prospects: {
    /** Prospects assigned to the assignment (with sales person) */
    assigned_count: number
    /** Count of approved prospects */
    approved_count: number
    /** Prioritized prospects */
    prioritized_count: number
    /** Sales cycle prospects count */
    prospects_count: number
    /** Disapproved prospects */
    disapproved_count: number
    /** Prospects waiting for any approval change */
    not_approved_count: number
    /** Approved prospects waiting for the assignment or already assigned */
    approved_to_be_used_count: number
    /** Assigned or already used prospects during the selling with any approval status */
    assigned_or_used_count: number
    company: ACompanyWithPermissions
    prospects: ACompanyUserProspect[]
    permissions?: APermissions
  }
  meta?: AMeta
}

export interface ASalesCycleIterationsDetailData {
  sales_cycle_iteration?: ACompanyUserSalesCycleIteration
  meta?: AMeta
}

export interface ASalesCyclesActivityFeedsDetailResult {
  activity_feeds: AActivityFeed[]
  meta?: {
    pagination?: APagination
  }
}

export interface ASalesCyclesAssignmentsReportingDetailResult {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailResult {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesCycleIterationsReportingDetailResult {
  sales_cycle_iteration?: ACompanyUserSalesCycleIterationReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesPeopleDetailData {
  sales_people?: ACompanyUserSalesCycleSalesPerson[]
  meta?: {
    pagination: APagination
    sales_person_placeholder: {
      id?: number | null
      first_name?: string | null
      rating?: number
      years_of_experience?: number | null
      native_language_id?: number | null
      spoken_language_ids?: number[] | null
      embed_video_id?: string | null
      profile_picture_url?: string | null
      profile_picture_thumbnail_url?: string | null
      country: ACountry
      packages: APackageWithPrice[]
      permissions?: APermissions
    }
  }
}

export interface ASalesCyclesDetailData {
  sales_cycle?: ACompanyUserSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesSalesNewsDetailResult {
  sales_cycle?: ACompanyUserSalesCycleSalesNews
  meta?: AMeta
}

export interface ASalesCyclesDetailsDetailResult {
  sales_cycle?: ACompanyUserSalesCycleDetails
  meta?: AMeta
}

export interface ASalesCyclesReportingDetailResult {
  sales_cycle?: ACompanyUserSalesCycleReporting
  meta?: AMeta
}

export interface ASalesCyclesNewsAndInsightsDetailResult {
  sales_cycle_iteration?: {
    id?: number | null
    month?: string | null
    news?: string | null
    insights?: string | null
  }
  meta?: AMeta
}

export interface ASalesCyclesActivitiesChartDetailResult {
  chart_data?: AActivitiesChartData | null
}

export interface ASalesCyclesClientsReachedChartDetailResult {
  chart_data?: AClientsReachChartData | null
}

export interface ASalesCyclesActivityOccurrencesChartDetailResult {
  chart_data?: AActivityOccurrencesChartData | null
}

export interface ASalesCyclesOpportunitiesChartDetailResult {
  chart_data?: AOpportunitiesChartData | null
}

export interface ASalesCyclesProspectsDetailResult {
  prospects: ACompanyUserSalesCycleProspects[]
  meta?: {
    pagination?: APagination
    total_prospects_in_db: number
    stages_used: ASalesCycleFilterStages[]
  }
}

export interface ASalesCyclesProspectsAutocompleteValuesDetailResult {
  values: {
    name: string
    value: string
  }[]
}

export type ACompanyUsersCreateData = any

export type AConfirmationCreateData = any

export interface AConfirmationPartialUpdateData {
  company_user?: AUsersCompanyUser
  meta?: AMeta
}

export interface AWelcomeScreenDetailData {
  welcome_screen: AWelcomeScreen
  meta?: AMeta
}

export interface ACountriesListData {
  countries: ACountry[]
}

export interface ACurrenciesListData {
  currencies?: ACurrency[]
}

export interface ARollbackDeleteData {
  importer?: AImporter
}

export interface AInvoiceDetailsListData {
  invoice_details: AInvoiceDetail[]
}

export interface AInvoiceDetailsCreateData {
  invoice_detail: AInvoiceDetail
}

export interface AInvoiceDetailsUpdateData {
  invoice_detail: AInvoiceDetail
}

export interface ALanguagesListData {
  languages?: ALanguage[]
  meta?: AMeta
}

export interface ALeadsListData {
  leads: ALead[]
  meta?: {
    pagination?: APagination
  }
}

export interface AAutocompleteValuesListData {
  values: {
    name: string
    value: string
  }[]
}

export type AImportCreateData = any

export type ASalesPersonImportCreateData = any

export type ALeadsDeleteData = any

export interface ALeadsDetailData {
  lead: ALeadDetail
  meta?: AMeta
}

export type ALeadsPartialUpdateData = any

export interface ALeadsDeleteResult {
  warnings: string[]
}

export interface AImportersListData {
  importers: AImporter[]
  meta: AMeta
}

export interface AIndexMetadataListData {
  leads_metadata: {
    import_in_progress: boolean
    total_leads_in_db: number
    removal_requested: number
  }
  meta?: AMeta
}

export interface ARemovalRequestsIndexListData {
  leads: ALead[]
  meta?: {
    pagination?: APagination
  }
}

export type AAcceptWarningsCreateData = any

export interface AMatchableSalesPeopleDetailData {
  sales_people?: AMatchingSalesPerson[]
  meta?: AMeta
}

export interface APackagesListData {
  packages: APackageWithPrice[]
  setup_fee_strategy: ASalesStrategyWithPrice
}

export interface APendingConfirmationsDetailData {
  pending_confirmation?: {
    email?: string
    first_name?: string
    last_name?: string
    phone_number?: string
    company?: ACompany
  }
  meta?: AMeta
}

export type AImportCreateResult = any

export type AApprovePartialUpdateData = any

export type ARemoveApproveStatusPartialUpdateData = any

export type ADisapprovePartialUpdateData = any

export type APrioritizePartialUpdateData = any

export type ADeprioritizePartialUpdateData = any

export interface AProspectsDisapprovePartialUpdateData {
  warnings: string[]
}

export interface AProspectsPrioritizePartialUpdateData {
  warnings: string[]
}

export interface AProspectsDeprioritizePartialUpdateData {
  warnings: string[]
}

export interface AProspectsDeleteData {
  warnings: string[]
}

export type AProspectsCreateData = any

export interface AProspectsPartialUpdateData {
  prospect?: AAdminProspect
  meta?: AMeta
}

export type AProspectsDeleteResult = any

export type ASaasUpdatePartialUpdateData = any

export type ARemoveDisapprovePartialUpdateData = any

export interface AActivitiesDetailData {
  activities?: AProspectActivity[]
  meta?: AMeta
}

export interface APrivacyRequestListData {
  privacy_request?: APublicPrivacyRequest
  meta?: AMeta
}

export type ARequestRemoveCreateData = any

export interface ADashboardListData {
  dashboard?: {
    dates?: string[]
  }
  meta?: AMeta
}

export interface ALeadsAssignmentsLeadsForAssignmentDetailResult {
  leads: ALeadWithAssignmentProspect[]
  meta?: {
    pagination?: APagination
    total_leads_for_assignment: number
    added_prospects_count: number
  }
}

export interface ALeadsAssignmentsAddProspectFromLeadCreateResult {
  lead?: ALeadWithAssignmentProspect
  meta?: AMeta
}

export interface ASaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreateData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export interface ASaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDeleteData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export type AProspectsAssignmentsUploadProspectsCreateResult = any

export interface AProspectsAssignmentsProspectsFinishedDetailOutput {
  prospects?: ASaasCompanyUserProspect[]
  meta?: {
    pagination?: APagination
    sync_crm_button?: boolean
  }
}

export type AProspectsAssignmentsSyncWithCrmCreateResult = any

export type AProspectsAssignmentsApproveProspectsCreateOutput = any

export interface AProspectsSalesCyclesDetailOutput {
  sales_cycle: ASaasCompanyProspectsSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesActivityFeedsDetailOutput {
  activity_feeds: AActivityFeed[]
  meta?: {
    pagination?: APagination
  }
}

export interface ASalesCyclesAssignmentsReportingDetailOutput {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailOutput {
  assignment?: ACompanyUserSalesCycleIterationAssignmentReporting
  meta?: AMeta
}

export interface ASalesCyclesSalesCycleIterationsReportingDetailOutput {
  sales_cycle_iteration?: ACompanyUserSalesCycleIterationReporting
  meta?: AMeta
}

export interface ASalesCyclesListData {
  sales_cycles?: ASaasCompanyUserDashboardSalesCycle[]
  meta?: AMeta
}

export interface ASalesCyclesDetailResult {
  sales_cycle?: ASaasCompanyUserSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesReportingDetailOutput {
  sales_cycle?: ASaasCompanyUserSalesCycleReporting
  meta?: AMeta
}

export interface ASalesCyclesNewsAndInsightsDetailOutput {
  sales_cycle_iteration?: {
    id?: number | null
    month?: string | null
    news?: string | null
    insights?: string | null
  }
  meta?: AMeta
}

export interface ASalesCyclesActivitiesChartDetailOutput {
  chart_data?: AActivitiesChartData | null
}

export interface ASalesCyclesClientsReachedChartDetailOutput {
  chart_data?: AClientsReachChartData | null
}

export interface ASalesCyclesActivityOccurrencesChartDetailOutput {
  chart_data?: AActivityOccurrencesChartData | null
}

export interface ASalesCyclesOpportunitiesChartDetailOutput {
  chart_data?: AOpportunitiesChartData | null
}

export interface ASalesCyclesProspectsDetailOutput {
  prospects: ACompanyUserSalesCycleProspects[]
  meta?: {
    pagination?: APagination
    total_prospects_in_db: number
    stages_used: ASalesCycleFilterStages[]
  }
}

export interface ASalesCyclesProspectsAutocompleteValuesDetailOutput {
  values: {
    name: string
    value: string
  }[]
}

export interface ASalesCyclesSalesNewsDetailOutput {
  sales_cycle?: ACompanyUserSalesCycleSalesNews
  meta?: AMeta
}

export interface ADashboardListResult {
  dashboard?: {
    dates?: string[]
  }
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsPrepareToApproveDetailResult {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsWaitingToApproveDetailOutput {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsFinishedDetailResponse {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export type AProspectsAssignmentsSendForApprovalCreateResult = any

export interface AProspectsSalesCyclesDetailResponse {
  sales_cycle: ASalesPersonProspectsSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesActivityFeedsDetailResponse {
  activity_feeds: AActivityFeed[]
  meta?: {
    pagination?: APagination
  }
}

export interface ASalesCyclesListResult {
  sales_cycles?: ASaasSalesPersonDashboardSalesCycle[]
  meta?: AMeta
}

export interface ASalesCyclesDetailOutput {
  sales_cycle?: ASaasSalesPersonSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesSalesNewsDetailResponse {
  sales_cycle?: ASalesPersonSalesCycleSalesNews
  meta?: AMeta
}

export interface ASalesCycleIterationsListData {
  sales_cycle_iterations: ASalesCycleIteration[]
  meta?: {
    pagination?: APagination
    tabs?: {
      waiting_for_acceptance?: number
      waiting_for_matching?: number
      ready_to_start?: number
      active?: number
      declined?: number
      finished?: number
    }
  }
}

/** Status filter */
export enum ASalesCycleIterationsListParamsStatusEnum {
  WaitingForMatching = "waiting_for_matching",
  Finished = "finished",
  ReadyToStart = "ready_to_start",
  Active = "active",
  WaitingForAcceptance = "waiting_for_acceptance",
  Declined = "declined",
}

export interface ASalesCycleIterationsDetailResult {
  sales_cycle_iteration?: ASalesCycleIteration
  meta?: AMeta
}

export interface ASalesCyclesListOutput {
  sales_cycles?: ASalesCycle[]
  meta?: AMeta
}

export type ASalesCyclesCreateData = any

export interface ASalesCyclesDetailResponse {
  sales_cycle?: ASalesCycle
  meta?: AMeta
}

export type ASalesCyclesPartialUpdateData = any

export type AFinalizeCreateData = any

export type AFinalizeKeyDetailsCreateData = any

export interface APackagesDetailData {
  packages: APackageWithPrice[]
  setup_fee_strategy: ASalesStrategyWithPrice
}

export type ARemoveAttachmentDeleteData = any

export interface ALeadsAssignmentsLeadsForAssignmentDetailOutput {
  leads: ALeadWithAssignmentProspect[]
  meta?: {
    pagination?: APagination
    total_leads_for_assignment: number
    added_prospects_count: number
  }
}

export interface ALeadsAssignmentsAddProspectFromLeadCreateOutput {
  lead?: ALeadWithAssignmentProspect
  meta?: AMeta
}

export interface ASalesPeopleLeadsAssignmentsAddProspectFromLeadCreateData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export interface ASalesPeopleLeadsAssignmentsDeleteProspectFromLeadDeleteData {
  leads: ALeadWithAssignmentProspect[]
  warnings: string[]
  processed_count: number
  skipped_count: number
}

export interface AProspectsAssignmentsProspectsPrepareToApproveDetailOutput {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsWaitingToApproveDetailResponse {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export interface AProspectsAssignmentsProspectsFinishedDetailPayload {
  prospects?: ASalesPersonProspect[]
  meta?: AMeta
}

export type AProspectsAssignmentsSendForApprovalCreateOutput = any

export interface AProspectsSalesCyclesDetailPayload {
  sales_cycle: ASalesPersonProspectsSalesCycle
  meta?: AMeta
}

export interface ASalesCyclesActivityFeedsDetailPayload {
  activity_feeds: AActivityFeed[]
  meta?: {
    pagination?: APagination
  }
}

export interface ASalesCyclesSalesNewsDetailPayload {
  sales_cycle?: ASalesPersonSalesCycleSalesNews
  meta?: AMeta
}

export interface ASalesCyclesDetailsDetailOutput {
  sales_cycle?: AAdminSalesCycleDetails
  meta?: AMeta
}

export type ASalesPeopleCreateData = any

export type AConfirmationCreateResult = any

export interface AConfirmationPartialUpdateResult {
  sales_person: AUsersSalesPerson
  meta?: AMeta
}

export interface ASalesPeopleDetailData {
  sales_person: ASalesPersonProfile
  meta?: AMeta
}

/** To specify sections returned in response */
export enum ASalesPeopleDetailParamsSectionEnum {
  All = "all",
  Profile = "profile",
  LearningMaterials = "learning_materials",
}

export type ASalesPeoplePartialUpdateData = any

/** To specify sections returned in response */
export enum ASalesPeoplePartialUpdateParamsSectionEnum {
  All = "all",
  Profile = "profile",
  LearningMaterials = "learning_materials",
}

export interface APublicDetailData {
  sales_person: APublicSalesPerson
  meta: AMeta
}

export type AAcceptPartialUpdateResult = any

/** To specify sections returned in response */
export enum AAcceptPartialUpdateParams1SectionEnum {
  All = "all",
  Profile = "profile",
  LearningMaterials = "learning_materials",
}

export interface ABusinessDataDetailData {
  business_data?: ABusinessData | null
  meta?: AMeta | null
}

export type ASubmitBusinessDataCreateData = any

export interface ADashboardDetailResult {
  dashboard: ASalesPersonDashboard
  meta?: AMeta
}

export interface ASalesPlansListData {
  sales_plans?: ASalesPlan[]
  meta?: {
    pagination?: APagination
    tabs?: {
      all?: number
      starting?: number
      in_progress?: number
      declined?: number
      stopped?: number
    }
  }
}

export interface ASettingsListData {
  settings: ASettings
  meta?: AMeta
}

export interface ACheckoutDetailData {
  order?: AOrder
}

export interface ACheckoutCreateData {
  order?: AOrder
  clientSecret?: string
}

export interface ACheckoutUpdateData {
  order: AOrder
}

export interface ACheckoutPreviewDetailData {
  preview_order?: APreviewOrder
}

export interface ACheckoutCreate2Data {
  order: AOrder
}

export interface ACancelCreateData {
  subscription: ASubscription
  meta?: AMeta
}

export type ASignInCreateData = any

export type ASignOutDeleteData = any

export type APasswordCreateData = any

export type APasswordUpdateData = any

export namespace Admins {
  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsLeadsForAssignmentDetail
   * @summary List of all assignment leads
   * @request GET:/admins/leads/assignments/{id}/leads_for_assignment
   */
  export namespace LeadsAssignmentsLeadsForAssignmentDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      "country_ids[]"?: number[]
      "positions[]"?: string[]
      "segments[]"?: string[]
      "company_sizes[]"?: ALeadCompanySizes[]
      "ids[]"?: number[]
      /** Sort by values */
      sort_by?: ALeadSortByValues
      /** Sort by direction */
      sort_direction?: ALeadSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsLeadsForAssignmentDetailData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add prospect to sales cycle from the leads database
   * @request POST:/admins/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace LeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      lead_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsAddProspectFromLeadCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsUploadProspectsCreate
   * @summary Assignment upload prospects for Admin Prospects
   * @request POST:/admins/prospects/assignments/{id}/upload_prospects
   */
  export namespace ProspectsAssignmentsUploadProspectsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      "prospect_import[ownership_level]"?: AProspectImportOwnershipLevelEnum
      "prospect_import[ownership_id]"?: number | null
      "prospect_import[source]": string
      /** Attach prospect import file */
      "prospect_import[prospects_file]": File
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsUploadProspectsCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
   * @summary List of all prospects for assignment waiting to send for approve
   * @request GET:/admins/prospects/assignments/{id}/prospects_prepare_to_approve
   */
  export namespace ProspectsAssignmentsProspectsPrepareToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      sales_person_id?: number
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsPrepareToApproveDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
   * @summary List of all prospects for assignment waiting to approve by company
   * @request GET:/admins/prospects/assignments/{id}/prospects_waiting_to_approve
   */
  export namespace ProspectsAssignmentsProspectsWaitingToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      sales_person_id?: number
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsWaitingToApproveDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsFinishedDetail
   * @summary List of all prospects for assignment already finished
   * @request GET:/admins/prospects/assignments/{id}/prospects_finished
   */
  export namespace ProspectsAssignmentsProspectsFinishedDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      sales_person_id?: number
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsFinishedDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsSendForApprovalCreate
   * @summary Send assignment prospects in the draft status for approval
   * @request POST:/admins/prospects/assignments/{id}/send_for_approval
   */
  export namespace ProspectsAssignmentsSendForApprovalCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsSendForApprovalCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsFinishApprovalCreate
   * @summary Finish approval process of ready-to-approve prospects
   * @request POST:/admins/prospects/assignments/{id}/finish_approval
   */
  export namespace ProspectsAssignmentsFinishApprovalCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsFinishApprovalCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsSyncWithCrmCreate
   * @summary Sync approved finished assigned prospects with the CRM
   * @request POST:/admins/prospects/assignments/{id}/sync_with_crm
   */
  export namespace ProspectsAssignmentsSyncWithCrmCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsSyncWithCrmCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsApproveProspectsCreate
   * @summary Approve and finished prospects without notifications
   * @request POST:/admins/prospects/assignments/{id}/approve_prospects
   */
  export namespace ProspectsAssignmentsApproveProspectsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsApproveProspectsCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsAcceptWarningsCreate
   * @summary Approve prospect normalization warnings
   * @request POST:/admins/prospects/assignments/{id}/accept_warnings/{prospect_id}
   */
  export namespace ProspectsAssignmentsAcceptWarningsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
      /** Prospect ID */
      prospectId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsAcceptWarningsCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesDetail
   * @summary Sales Cycle detail for Prospects
   * @request GET:/admins/prospects/sales_cycles/{id}
   */
  export namespace ProspectsSalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesLifetimeDetail
   * @summary Sales Cycle detail for Prospects
   * @request GET:/admins/prospects/sales_cycles/{id}/lifetime
   */
  export namespace ProspectsSalesCyclesLifetimeDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      sales_person_id?: number
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesLifetimeDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsList
   * @summary List of all prospects with admin-visible data
   * @request GET:/admins/prospects
   */
  export namespace ProspectsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
      /** Prospects sales cycle ID */
      sales_cycle_id: number
      /** Search in phone numbers, email and organization name */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsListData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsExtendedIndexList
   * @summary List of all prospects with admin-visible data and prospects statistics
   * @request GET:/admins/prospects/extended_index
   */
  export namespace ProspectsExtendedIndexList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
      /** Prospects sales cycle ID */
      sales_cycle_id: number
      /** Search in phone numbers, email and organization name */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsExtendedIndexListData
  }

  /**
   * No description
   * @tags Admins/ActivityFeeds
   * @name SalesCyclesActivityFeedsDetail
   * @summary Admin activity feed for the current sales cycle
   * @request GET:/admins/sales_cycles/{sales_cycle_id}/activity_feeds
   */
  export namespace SalesCyclesActivityFeedsDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityFeedsDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/admins/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesAssignmentsReportingDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      salesCycleIterationId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesSalesCycleIterationsFinalizeReportCreate
   * @summary Admin finalize report for sales cycle iteration reporting data
   * @request POST:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/finalize_report
   */
  export namespace SalesCyclesSalesCycleIterationsFinalizeReportCreate {
    export type RequestParams = {
      salesCycleId: number
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsFinalizeReportCreateData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesSalesCycleIterationsReportingDetail
   * @summary Sales cycle iteration reporting data
   * @request GET:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsReportingDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesSalesNewsDetail
   * @summary Sales cycle - current iteration sales news
   * @request GET:/admins/sales_cycles/{id}/sales_news
   */
  export namespace SalesCyclesSalesNewsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesNewsDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesDetailsDetail
   * @summary Sales cycle detail
   * @request GET:/admins/sales_cycles/{id}/details
   */
  export namespace SalesCyclesDetailsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailsDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesReportingDetail
   * @summary Sales cycle reporting data
   * @request GET:/admins/sales_cycles/{id}/reporting
   */
  export namespace SalesCyclesReportingDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesReportingDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesNewsAndInsightsDetail
   * @summary Sales cycle / sales cycle iteration news and insights
   * @request GET:/admins/sales_cycles/{id}/news_and_insights
   */
  export namespace SalesCyclesNewsAndInsightsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesNewsAndInsightsDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesActivitiesChartDetail
   * @summary Sales cycle activities chart data
   * @request GET:/admins/sales_cycles/{id}/activities_chart
   */
  export namespace SalesCyclesActivitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivitiesChartDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesClientsReachedChartDetail
   * @summary Sales cycle clients reached chart data
   * @request GET:/admins/sales_cycles/{id}/clients_reached_chart
   */
  export namespace SalesCyclesClientsReachedChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesClientsReachedChartDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesActivityOccurrencesChartDetail
   * @summary Activity occurrences chart data
   * @request GET:/admins/sales_cycles/{id}/activity_occurrences_chart
   */
  export namespace SalesCyclesActivityOccurrencesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      activity_event: AActivityEvents
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityOccurrencesChartDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesOpportunitiesChartDetail
   * @summary Opportunities chart data
   * @request GET:/admins/sales_cycles/{id}/opportunities_chart
   */
  export namespace SalesCyclesOpportunitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesOpportunitiesChartDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesProspectsDetail
   * @summary Admins list of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
   * @request GET:/admins/sales_cycles/{id}/prospects
   */
  export namespace SalesCyclesProspectsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      /** Search query */
      search?: string
      /** Last changed within */
      last_change?: string
      "statuses[]"?: string[]
      "stages[]"?: string[]
      "segments[]"?: string[]
      /** Sort by values */
      sort_by?: ASalesCyclesProspectValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsDetailData
  }

  /**
   * No description
   * @tags Admins/SalesCycles
   * @name SalesCyclesProspectsAutocompleteValuesDetail
   * @summary Admins the 10 most similar autocomplete values for specified field and value
   * @request GET:/admins/sales_cycles/{id}/prospects_autocomplete_values
   */
  export namespace SalesCyclesProspectsAutocompleteValuesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      /** Autocomplete field */
      field: ASalesCycleProspectAutocompleteFields
      /** Search value */
      value: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsAutocompleteValuesDetailData
  }
}

export namespace BulkActions {
  /**
   * No description
   * @tags Leads
   * @name AdminLeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add many prospects to assignment from the leads database
   * @request POST:/bulk_actions/admin/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace AdminLeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = AAdminLeadsAssignmentsAddProspectFromLeadCreateData
  }

  /**
   * No description
   * @tags Leads
   * @name AdminLeadsAssignmentsDeleteProspectFromLeadDelete
   * @summary Delete assigment prospects created from provided leads
   * @request DELETE:/bulk_actions/admin/leads/assignments/{id}/delete_prospect_from_lead
   */
  export namespace AdminLeadsAssignmentsDeleteProspectFromLeadDelete {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = AAdminLeadsAssignmentsDeleteProspectFromLeadDeleteData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsDelete
   * @summary Remove a list of leads
   * @request DELETE:/bulk_actions/leads
   */
  export namespace LeadsDelete {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
      delete_strategy?: ALeadDeleteStrategies
    }
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsDeleteResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsDisapprovePartialUpdate
   * @summary Disapprove a list of prospects
   * @request PATCH:/bulk_actions/prospects/disapprove
   */
  export namespace ProspectsDisapprovePartialUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
      disapprove_reason: AProspectDisapproveReasons
      disapprove_reason_text?: string | null
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsDisapprovePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsPrioritizePartialUpdate
   * @summary Prioritize a list of prospects
   * @request PATCH:/bulk_actions/prospects/prioritize
   */
  export namespace ProspectsPrioritizePartialUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsPrioritizePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsDeprioritizePartialUpdate
   * @summary Deprioritize a list of prospects
   * @request PATCH:/bulk_actions/prospects/deprioritize
   */
  export namespace ProspectsDeprioritizePartialUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsDeprioritizePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsDelete
   * @summary Remove a list of prospects
   * @request DELETE:/bulk_actions/prospects
   */
  export namespace ProspectsDelete {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsDeleteData
  }

  /**
   * No description
   * @tags Leads
   * @name SaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add many prospects to assignment from the leads database
   * @request POST:/bulk_actions/saas_company_users/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace SaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreateData
  }

  /**
   * No description
   * @tags Leads
   * @name SaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDelete
   * @summary Delete assigment prospects created from provided leads
   * @request DELETE:/bulk_actions/saas_company_users/leads/assignments/{id}/delete_prospect_from_lead
   */
  export namespace SaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDelete {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDeleteData
  }

  /**
   * No description
   * @tags Leads
   * @name SalesPeopleLeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add many prospects to assignment from the leads database
   * @request POST:/bulk_actions/sales_people/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace SalesPeopleLeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPeopleLeadsAssignmentsAddProspectFromLeadCreateData
  }

  /**
   * No description
   * @tags Leads
   * @name SalesPeopleLeadsAssignmentsDeleteProspectFromLeadDelete
   * @summary Delete assigment prospects created from provided leads
   * @request DELETE:/bulk_actions/sales_people/leads/assignments/{id}/delete_prospect_from_lead
   */
  export namespace SalesPeopleLeadsAssignmentsDeleteProspectFromLeadDelete {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      ids: number[]
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPeopleLeadsAssignmentsDeleteProspectFromLeadDeleteData
  }
}

export namespace AssignmentOffers {
  /**
   * No description
   * @tags Assignment Offers
   * @name AssignmentOffersList
   * @summary List of all assignment_offers
   * @request GET:/assignment_offers
   */
  export namespace AssignmentOffersList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentOffersListData
  }

  /**
   * No description
   * @tags Assignment Offers
   * @name AssignmentOffersCreate
   * @summary Create a new assignment_offer
   * @request POST:/assignment_offers
   */
  export namespace AssignmentOffersCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      assignment_offer: {
        assignment_id: number
        sales_person_id: number
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentOffersCreateData
  }

  /**
   * No description
   * @tags Assignment Offers
   * @name AssignmentOffersDetail
   * @summary Get one assignment offer
   * @request GET:/assignment_offers/{id}
   */
  export namespace AssignmentOffersDetail {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentOffersDetailData
  }

  /**
   * No description
   * @tags Assignment Offers
   * @name AssignmentOffersDelete
   * @summary Cancel an assignment offer
   * @request DELETE:/assignment_offers/{id}
   */
  export namespace AssignmentOffersDelete {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentOffersDeleteData
  }

  /**
   * No description
   * @tags Assignment Offers
   * @name AcceptPartialUpdate
   * @summary Accept an assignment offer
   * @request PATCH:/assignment_offers/{id}/accept
   */
  export namespace AcceptPartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAcceptPartialUpdateData
  }

  /**
   * No description
   * @tags Assignment Offers
   * @name RejectPartialUpdate
   * @summary Accept an assignment offer
   * @request PATCH:/assignment_offers/{id}/reject
   */
  export namespace RejectPartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARejectPartialUpdateData
  }
}

export namespace Assignments {
  /**
   * No description
   * @tags Assignments
   * @name AssignmentsList
   * @summary List of all assignments
   * @request GET:/assignments
   */
  export namespace AssignmentsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentsListData
  }

  /**
   * No description
   * @tags Assignments
   * @name AssignmentsDetail
   * @summary Get one assignment
   * @request GET:/assignments/{id}
   */
  export namespace AssignmentsDetail {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAssignmentsDetailData
  }

  /**
   * No description
   * @tags Assignments
   * @name RevokePartialUpdate
   * @summary Clear assignment from current sales person
   * @request PATCH:/assignments/{id}/revoke
   */
  export namespace RevokePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARevokePartialUpdateData
  }
}

export namespace Companies {
  /**
   * No description
   * @tags Companies
   * @name CompaniesDetail
   * @summary Get company profile data
   * @request GET:/companies/{id}
   */
  export namespace CompaniesDetail {
    export type RequestParams = {
      /** Company ID */
      id: number
    }
    export type RequestQuery = {
      /** To specify sections returned in response */
      section?: ACompaniesDetailParamsSectionEnum
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ACompaniesDetailData
  }

  /**
   * No description
   * @tags Companies
   * @name CompaniesPartialUpdate
   * @summary Update company
   * @request PATCH:/companies/{id}
   */
  export namespace CompaniesPartialUpdate {
    export type RequestParams = {
      /** Company ID */
      id: number
    }
    export type RequestQuery = {
      /** To specify sections returned in response */
      section?: ACompaniesPartialUpdateParamsSectionEnum
    }
    export type RequestBody = {
      company?: ACompanyAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACompaniesPartialUpdateData
  }
}

export namespace CompanyUsers {
  /**
   * No description
   * @tags ActivityFeeds
   * @name ActivityFeedsLikePartialUpdate
   * @summary Activity feed company like
   * @request PATCH:/company_users/activity_feeds/{id}/like
   */
  export namespace ActivityFeedsLikePartialUpdate {
    export type RequestParams = {
      /** Activity Feed ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AActivityFeedsLikePartialUpdateData
  }

  /**
   * No description
   * @tags ActivityFeeds
   * @name ActivityFeedsDislikePartialUpdate
   * @summary Activity feed company dislike
   * @request PATCH:/company_users/activity_feeds/{id}/dislike
   */
  export namespace ActivityFeedsDislikePartialUpdate {
    export type RequestParams = {
      /** Activity Feed ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AActivityFeedsDislikePartialUpdateData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name DashboardDetail
   * @summary Get company user dashboard data
   * @request GET:/company_users/dashboard/{id}
   */
  export namespace DashboardDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name DashboardActiveSalesCyclesDetail
   * @summary Get active sales cycles for company dashboard
   * @request GET:/company_users/dashboard/{id}/active_sales_cycles
   */
  export namespace DashboardActiveSalesCyclesDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardActiveSalesCyclesDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name DashboardDraftedSalesCyclesDetail
   * @summary Get drafted sales cycles for company dashboard
   * @request GET:/company_users/dashboard/{id}/drafted_sales_cycles
   */
  export namespace DashboardDraftedSalesCyclesDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardDraftedSalesCyclesDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name DashboardFinishedSalesCyclesDetail
   * @summary Get finished sales cycles for company dashboard
   * @request GET:/company_users/dashboard/{id}/finished_sales_cycles
   */
  export namespace DashboardFinishedSalesCyclesDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardFinishedSalesCyclesDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name DashboardPausedSalesCyclesDetail
   * @summary Get paused sales cycles for company dashboard
   * @request GET:/company_users/dashboard/{id}/paused_sales_cycles
   */
  export namespace DashboardPausedSalesCyclesDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardPausedSalesCyclesDetailData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectDisapprovePartialUpdate
   * @summary Disapprove a prospect of assignment
   * @request PATCH:/company_users/prospects/assignments/{id}/prospect_disapprove
   */
  export namespace ProspectsAssignmentsProspectDisapprovePartialUpdate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      prospect: {
        /** Prospect ID */
        id: number
        disapprove_reason: AProspectDisapproveReasons
        disapprove_reason_text?: string | null
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectDisapprovePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
   * @summary Prospects Company list of all prospects for assignment waiting to approve
   * @request GET:/company_users/prospects/assignments/{id}/prospects_waiting_to_approve
   */
  export namespace ProspectsAssignmentsProspectsWaitingToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: ACompanyProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsWaitingToApproveDetailResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsFinishedDetail
   * @summary Prospects Company list of all prospects for assignment already finished
   * @request GET:/company_users/prospects/assignments/{id}/prospects_finished
   */
  export namespace ProspectsAssignmentsProspectsFinishedDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: ACompanyProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsFinishedDetailResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsApproveProspectsCreate
   * @summary Company approve ready-to-approved prospects
   * @request POST:/company_users/prospects/assignments/{id}/approve_prospects
   */
  export namespace ProspectsAssignmentsApproveProspectsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsApproveProspectsCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesDetail
   * @summary Sales Cycle detail for Company Prospects
   * @request GET:/company_users/prospects/sales_cycles/{id}
   */
  export namespace ProspectsSalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesDetailResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsList
   * @summary List of all prospects with company-user-visible data
   * @request GET:/company_users/prospects
   */
  export namespace ProspectsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
      /** Prospects sales cycle ID */
      sales_cycle_id: number
      /** Search organization name */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsListResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsExtendedIndexList
   * @summary List of all prospects with company-user-visible data and prospects statistics
   * @request GET:/company_users/prospects/extended_index
   */
  export namespace ProspectsExtendedIndexList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
      /** Prospects sales cycle ID */
      sales_cycle_id: number
      /** Search organization name */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsExtendedIndexListResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCycleIterationsDetail
   * @summary Sales cycle iteration detail
   * @request GET:/company_users/sales_cycle_iterations/{id}
   * @deprecated
   */
  export namespace SalesCycleIterationsDetail {
    export type RequestParams = {
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCycleIterationsDetailData
  }

  /**
   * No description
   * @tags ActivityFeeds
   * @name SalesCyclesActivityFeedsDetail
   * @summary Company activity feed for the current sales cycle
   * @request GET:/company_users/sales_cycles/{sales_cycle_id}/activity_feeds
   */
  export namespace SalesCyclesActivityFeedsDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityFeedsDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/company_users/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesAssignmentsReportingDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      salesCycleIterationId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesSalesCycleIterationsReportingDetail
   * @summary Sales cycle iteration reporting data
   * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsReportingDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers/SalesCycles/SalesPeople
   * @name SalesCyclesSalesPeopleDetail
   * @summary Sales cycle iteration reporting data
   * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_people
   */
  export namespace SalesCyclesSalesPeopleDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesPeopleDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesDetail
   * @summary Sales cycle detail
   * @request GET:/company_users/sales_cycles/{id}
   * @deprecated
   */
  export namespace SalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesSalesNewsDetail
   * @summary Sales cycle - current iteration sales news
   * @request GET:/company_users/sales_cycles/{id}/sales_news
   */
  export namespace SalesCyclesSalesNewsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesNewsDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesDetailsDetail
   * @summary Sales cycle detail
   * @request GET:/company_users/sales_cycles/{id}/details
   */
  export namespace SalesCyclesDetailsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailsDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesReportingDetail
   * @summary Sales cycle reporting data
   * @request GET:/company_users/sales_cycles/{id}/reporting
   */
  export namespace SalesCyclesReportingDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesReportingDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesNewsAndInsightsDetail
   * @summary Sales cycle / sales cycle iteration news and insights
   * @request GET:/company_users/sales_cycles/{id}/news_and_insights
   */
  export namespace SalesCyclesNewsAndInsightsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesNewsAndInsightsDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesActivitiesChartDetail
   * @summary Sales cycle activities chart data
   * @request GET:/company_users/sales_cycles/{id}/activities_chart
   */
  export namespace SalesCyclesActivitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivitiesChartDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesClientsReachedChartDetail
   * @summary Sales cycle clients reached chart data
   * @request GET:/company_users/sales_cycles/{id}/clients_reached_chart
   */
  export namespace SalesCyclesClientsReachedChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesClientsReachedChartDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesActivityOccurrencesChartDetail
   * @summary Activity occurrences chart data
   * @request GET:/company_users/sales_cycles/{id}/activity_occurrences_chart
   */
  export namespace SalesCyclesActivityOccurrencesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      activity_event: AActivityEvents
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityOccurrencesChartDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesOpportunitiesChartDetail
   * @summary Opportunities chart data
   * @request GET:/company_users/sales_cycles/{id}/opportunities_chart
   */
  export namespace SalesCyclesOpportunitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesOpportunitiesChartDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesProspectsDetail
   * @summary List of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
   * @request GET:/company_users/sales_cycles/{id}/prospects
   */
  export namespace SalesCyclesProspectsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      /** Search query */
      search?: string
      /** Last changed within */
      last_change?: string
      "statuses[]"?: ASalesCycleProspectStatuses[]
      "stages[]"?: ASalesCycleFilterStages[]
      "segments[]"?: string[]
      /** Sort by values */
      sort_by?: ASalesCyclesProspectValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name SalesCyclesProspectsAutocompleteValuesDetail
   * @summary The 10 most similar autocomplete values for specified field and value
   * @request GET:/company_users/sales_cycles/{id}/prospects_autocomplete_values
   */
  export namespace SalesCyclesProspectsAutocompleteValuesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      /** Autocomplete field */
      field: ASalesCycleProspectAutocompleteFields
      /** Search value */
      value: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsAutocompleteValuesDetailResult
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name CompanyUsersCreate
   * @summary Registers in company user
   * @request POST:/company_users
   */
  export namespace CompanyUsersCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      lang?: string
    }
    export type RequestBody = {
      company_user: {
        email: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACompanyUsersCreateData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name ConfirmationCreate
   * @summary Resend confirmation email
   * @request POST:/company_users/confirmation
   */
  export namespace ConfirmationCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      company_user: {
        email: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = AConfirmationCreateData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name ConfirmationPartialUpdate
   * @summary Confirms email address and saves user details
   * @request PATCH:/company_users/confirmation
   */
  export namespace ConfirmationPartialUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      company: {
        name: string
      }
      company_user: {
        phone_number: string
        password: string
        password_confirmation: string
        tac_agreement: boolean
      }
      confirmation_token: string
    }
    export type RequestHeaders = {}
    export type ResponseBody = AConfirmationPartialUpdateData
  }

  /**
   * No description
   * @tags CompanyUsers
   * @name WelcomeScreenDetail
   * @summary Get company user welcome screen data
   * @request GET:/company_users/welcome_screen/{id}
   */
  export namespace WelcomeScreenDetail {
    export type RequestParams = {
      /** Company User ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AWelcomeScreenDetailData
  }
}

export namespace Countries {
  /**
   * No description
   * @tags Countries
   * @name CountriesList
   * @summary List of all countries
   * @request GET:/countries
   */
  export namespace CountriesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ACountriesListData
  }
}

export namespace Currencies {
  /**
   * No description
   * @tags Currencies
   * @name CurrenciesList
   * @summary List of all currencies
   * @request GET:/currencies
   */
  export namespace CurrenciesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ACurrenciesListData
  }
}

export namespace Importers {
  /**
   * No description
   * @tags Importers
   * @name RollbackDelete
   * @summary Rollback importer and its imported resources
   * @request DELETE:/importers/{id}/rollback
   */
  export namespace RollbackDelete {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARollbackDeleteData
  }
}

export namespace InvoiceDetails {
  /**
   * No description
   * @tags InvoiceDetails
   * @name InvoiceDetailsList
   * @summary List of invoice details belongs to company or user
   * @request GET:/invoice_details
   */
  export namespace InvoiceDetailsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AInvoiceDetailsListData
  }

  /**
   * No description
   * @tags InvoiceDetails
   * @name InvoiceDetailsCreate
   * @summary Creates a new invoice detail
   * @request POST:/invoice_details
   */
  export namespace InvoiceDetailsCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      invoice_detail: AInvoiceDetailAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = AInvoiceDetailsCreateData
  }

  /**
   * No description
   * @tags InvoiceDetails
   * @name InvoiceDetailsUpdate
   * @summary Updates an invoice detail
   * @request PUT:/invoice_details/{id}
   */
  export namespace InvoiceDetailsUpdate {
    export type RequestParams = {
      /** InvoiceDetail ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      invoice_detail: AInvoiceDetailAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = AInvoiceDetailsUpdateData
  }
}

export namespace Languages {
  /**
   * No description
   * @tags Languages
   * @name LanguagesList
   * @summary List of all languages
   * @request GET:/languages
   */
  export namespace LanguagesList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALanguagesListData
  }
}

export namespace Leads {
  /**
   * No description
   * @tags Leads
   * @name LeadsList
   * @summary List of all leads
   * @request GET:/leads
   */
  export namespace LeadsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Search query */
      search?: string
      "country_ids[]"?: number[]
      "positions[]"?: string[]
      "segments[]"?: string[]
      "company_sizes[]"?: ALeadCompanySizes[]
      "ids[]"?: number[]
      /** Sort by values */
      sort_by?: ALeadSortByValues
      /** Sort by direction */
      sort_direction?: ALeadSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsListData
  }

  /**
   * No description
   * @tags Leads
   * @name AutocompleteValuesList
   * @summary The 10 most similar autocomplete values for specified field and value
   * @request GET:/leads/autocomplete_values
   */
  export namespace AutocompleteValuesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Autocomplete field */
      field: ALeadAutocompleteFields
      /** Search value */
      value: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAutocompleteValuesListData
  }

  /**
   * No description
   * @tags Leads
   * @name ImportCreate
   * @summary Imports list of leads
   * @request POST:/leads/import
   */
  export namespace ImportCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      "leads_import[leads_file]": File
      "leads_import[source]": string
      "leads_import[ownership_level]"?: AOwnershipLevels
      "leads_import[owner_id]"?: number | null
    }
    export type RequestHeaders = {}
    export type ResponseBody = AImportCreateData
  }

  /**
   * No description
   * @tags Leads
   * @name SalesPersonImportCreate
   * @summary Imports list of sales person leads
   * @request POST:/leads/sales_person_import
   */
  export namespace SalesPersonImportCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      "leads_import[leads_file]": File
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPersonImportCreateData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsDelete
   * @summary Deletes lead
   * @request DELETE:/leads/{id}
   */
  export namespace LeadsDelete {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {
      delete_strategy: ALeadDeleteStrategies
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsDeleteData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsDetail
   * @summary Lead detail
   * @request GET:/leads/{id}
   */
  export namespace LeadsDetail {
    export type RequestParams = {
      /** Lead ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsDetailData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsPartialUpdate
   * @summary Updates a lead
   * @request PATCH:/leads/{id}
   */
  export namespace LeadsPartialUpdate {
    export type RequestParams = {
      /** Lead ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      lead: ALeadAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsPartialUpdateData
  }

  /**
   * No description
   * @tags Leads
   * @name ImportersList
   * @summary List of leads importers
   * @request GET:/leads/importers
   */
  export namespace ImportersList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AImportersListData
  }

  /**
   * No description
   * @tags Leads
   * @name IndexMetadataList
   * @summary Display metadata for leads index
   * @request GET:/leads/index_metadata
   */
  export namespace IndexMetadataList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AIndexMetadataListData
  }

  /**
   * No description
   * @tags Leads
   * @name RemovalRequestsIndexList
   * @summary List of requested removal leads
   * @request GET:/leads/removal_requests_index
   */
  export namespace RemovalRequestsIndexList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Search query */
      search?: string
      "country_ids[]"?: number[]
      "positions[]"?: string[]
      "segments[]"?: string[]
      "company_sizes[]"?: ALeadCompanySizes[]
      "ids[]"?: number[]
      /** Sort by values */
      sort_by?: ALeadSortByValues
      /** Sort by direction */
      sort_direction?: ALeadSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARemovalRequestsIndexListData
  }

  /**
   * No description
   * @tags Leads
   * @name AcceptWarningsCreate
   * @summary Approve lead normalization warnings
   * @request POST:/leads/accept_warnings/{id}
   */
  export namespace AcceptWarningsCreate {
    export type RequestParams = {
      /** Lead ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AAcceptWarningsCreateData
  }
}

export namespace SalesCycleIterations {
  /**
   * No description
   * @tags Sales People for matching
   * @name MatchableSalesPeopleDetail
   * @summary List of all sales people for matching
   * @request GET:/sales_cycle_iterations/{id}/matchable_sales_people
   */
  export namespace MatchableSalesPeopleDetail {
    export type RequestParams = {
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {
      /** SalesPerson search on names */
      search?: string
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AMatchableSalesPeopleDetailData
  }

  /**
   * No description
   * @tags SalesCycleIterations
   * @name SalesCycleIterationsList
   * @summary List of all sales cycle iterations
   * @request GET:/sales_cycle_iterations
   */
  export namespace SalesCycleIterationsList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Status filter */
      status?: ASalesCycleIterationsListParamsStatusEnum
      /** Start date filter */
      start_date?: string
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCycleIterationsListData
  }

  /**
   * No description
   * @tags SalesCycleIterations
   * @name SalesCycleIterationsDetail
   * @summary One sales cycle iteration
   * @request GET:/sales_cycle_iterations/{id}
   */
  export namespace SalesCycleIterationsDetail {
    export type RequestParams = {
      /** SalesCycleIteration ID */
      id: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCycleIterationsDetailResult
  }
}

export namespace Packages {
  /**
   * No description
   * @tags Packages
   * @name PackagesList
   * @summary List of all packages for country
   * @request GET:/packages
   */
  export namespace PackagesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Country code (SK, CZ etc., default is SK) */
      country_code?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APackagesListData
  }
}

export namespace PendingConfirmations {
  /**
   * No description
   * @tags Pending Confirmations
   * @name PendingConfirmationsDetail
   * @summary Get confirmation data for a pending confirmation
   * @request GET:/pending_confirmations/{confirmation_token}
   */
  export namespace PendingConfirmationsDetail {
    export type RequestParams = {
      /** The confirmation token of a pending confirmation */
      confirmationToken: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APendingConfirmationsDetailData
  }
}

export namespace Prospects {
  /**
   * No description
   * @tags Prospects
   * @name ImportCreate
   * @summary Imports list of prospects (with leads) for specified sales cycle
   * @request POST:/prospects/import
   */
  export namespace ImportCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      sales_cycle_id: number
      owner_id?: number | null
      ownership_level?: AOwnershipLevels
      /** @format binary */
      prospects_file: File
      source: string
    }
    export type RequestHeaders = {}
    export type ResponseBody = AImportCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ApprovePartialUpdate
   * @summary Approve a prospect of sales cycle
   * @request PATCH:/prospects/{id}/approve
   * @deprecated
   */
  export namespace ApprovePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AApprovePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name RemoveApproveStatusPartialUpdate
   * @summary Remove approve status for a prospect of sales cycle
   * @request PATCH:/prospects/{id}/remove_approve_status
   * @deprecated
   */
  export namespace RemoveApproveStatusPartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARemoveApproveStatusPartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name DisapprovePartialUpdate
   * @summary Disapprove a prospect of sales cycle
   * @request PATCH:/prospects/{id}/disapprove
   * @deprecated
   */
  export namespace DisapprovePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      disapprove_reason: AProspectDisapproveReasons
    }
    export type RequestHeaders = {}
    export type ResponseBody = ADisapprovePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name PrioritizePartialUpdate
   * @summary Prioritize a prospect of sales cycle
   * @request PATCH:/prospects/{id}/prioritize
   */
  export namespace PrioritizePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APrioritizePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name DeprioritizePartialUpdate
   * @summary Deprioritize a prospect of sales cycle
   * @request PATCH:/prospects/{id}/deprioritize
   */
  export namespace DeprioritizePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADeprioritizePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsCreate
   * @summary Creates a prospect
   * @request POST:/prospects
   */
  export namespace ProspectsCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      assignment_id: number
    }
    export type RequestBody = {
      prospect: AProspectAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsCreateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsPartialUpdate
   * @summary Updates a prospect
   * @request PATCH:/prospects/{id}
   */
  export namespace ProspectsPartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      prospect: AProspectAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsPartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsDelete
   * @summary Permanently deletes a prospect
   * @request DELETE:/prospects/{id}
   */
  export namespace ProspectsDelete {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsDeleteResult
  }

  /**
   * No description
   * @tags Prospects
   * @name SaasUpdatePartialUpdate
   * @summary Updates a SAAS prospect
   * @request PATCH:/prospects/{id}/saas_update
   */
  export namespace SaasUpdatePartialUpdate {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      prospect: AProspectAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASaasUpdatePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name RemoveDisapprovePartialUpdate
   * @summary Remove disapprove status for a prospect of sales cycle
   * @request PATCH:/prospects/{id}/remove_disapprove
   */
  export namespace RemoveDisapprovePartialUpdate {
    export type RequestParams = {
      /** Prospect ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARemoveDisapprovePartialUpdateData
  }

  /**
   * No description
   * @tags Prospects
   * @name ActivitiesDetail
   * @summary All or iteration scoped activities of prospects
   * @request GET:/prospects/{id}/activities
   */
  export namespace ActivitiesDetail {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AActivitiesDetailData
  }
}

export namespace PrivacyRequest {
  /**
   * No description
   * @tags PrivacyRequests
   * @name PrivacyRequestList
   * @summary Privacy request detail
   * @request GET:/privacy_request
   */
  export namespace PrivacyRequestList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Privacy request token */
      token: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APrivacyRequestListData
  }

  /**
   * No description
   * @tags PrivacyRequests
   * @name RequestRemoveCreate
   * @summary Request remove privacy request for lead data
   * @request POST:/privacy_request/request_remove
   */
  export namespace RequestRemoveCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Privacy request token */
      token: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARequestRemoveCreateData
  }
}

export namespace SaasCompanyUsers {
  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name DashboardList
   * @summary Saas Company User dashboard data
   * @request GET:/saas_company_users/dashboard
   */
  export namespace DashboardList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardListData
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsLeadsForAssignmentDetail
   * @summary List of all assignment leads
   * @request GET:/saas_company_users/leads/assignments/{id}/leads_for_assignment
   */
  export namespace LeadsAssignmentsLeadsForAssignmentDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      "country_ids[]"?: number[]
      "positions[]"?: string[]
      "segments[]"?: string[]
      "company_sizes[]"?: ALeadCompanySizes[]
      "ids[]"?: number[]
      /** Sort by values */
      sort_by?: ALeadSortByValues
      /** Sort by direction */
      sort_direction?: ALeadSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsLeadsForAssignmentDetailResult
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add prospect to sales cycle from the leads database
   * @request POST:/saas_company_users/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace LeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      lead_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsAddProspectFromLeadCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsUploadProspectsCreate
   * @summary Assignment upload prospects for SAAS Company Users Prospects
   * @request POST:/saas_company_users/prospects/assignments/{id}/upload_prospects
   */
  export namespace ProspectsAssignmentsUploadProspectsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      /** Attach prospect import file */
      prospects_file: File
    }
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsUploadProspectsCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsFinishedDetail
   * @summary List of all prospects for assignment already finished
   * @request GET:/saas_company_users/prospects/assignments/{id}/prospects_finished
   */
  export namespace ProspectsAssignmentsProspectsFinishedDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsFinishedDetailOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsSyncWithCrmCreate
   * @summary Sync approved finished assigned prospects with the CRM
   * @request POST:/saas_company_users/prospects/assignments/{id}/sync_with_crm
   */
  export namespace ProspectsAssignmentsSyncWithCrmCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsSyncWithCrmCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsApproveProspectsCreate
   * @summary Approve and finished prospects without notifications
   * @request POST:/saas_company_users/prospects/assignments/{id}/approve_prospects
   */
  export namespace ProspectsAssignmentsApproveProspectsCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsApproveProspectsCreateOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesDetail
   * @summary SAAS Sales Cycle detail for Prospects
   * @request GET:/saas_company_users/prospects/sales_cycles/{id}
   */
  export namespace ProspectsSalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesActivityFeedsDetail
   * @summary Company activity feed for the current sales cycle
   * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/activity_feeds
   */
  export namespace SalesCyclesActivityFeedsDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityFeedsDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesAssignmentsReportingDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
   * @summary Assignment reporting data
   * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsAssignmentsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      salesCycleIterationId: number
      /** Base64 assignment ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesSalesCycleIterationsReportingDetail
   * @summary Sales cycle iteration reporting data
   * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
   */
  export namespace SalesCyclesSalesCycleIterationsReportingDetail {
    export type RequestParams = {
      salesCycleId: number
      /** Sales cycle iteration ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesCycleIterationsReportingDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesList
   * @summary List of all saas sales cycles for saas company
   * @request GET:/saas_company_users/sales_cycles
   */
  export namespace SalesCyclesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Start date for iteration in 2024-08-01 format. Dates are received in dashboard */
      start_date: string
      /** SalesCycle search on names */
      search?: string
      /** Sort by values */
      sort_by?: ASaasSalesCycleSortByValues
      /** Sort by direction */
      sort_direction?: ASaasSalesCycleSortDirectionValues
      /** Country filter */
      country_id?: number
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesListData
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesDetail
   * @summary Saas sales cycle detail
   * @request GET:/saas_company_users/sales_cycles/{id}
   */
  export namespace SalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailResult
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesReportingDetail
   * @summary SAAS sales cycle reporting data
   * @request GET:/saas_company_users/sales_cycles/{id}/reporting
   */
  export namespace SalesCyclesReportingDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesReportingDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesNewsAndInsightsDetail
   * @summary Sales cycle / sales cycle iteration news and insights
   * @request GET:/saas_company_users/sales_cycles/{id}/news_and_insights
   */
  export namespace SalesCyclesNewsAndInsightsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesNewsAndInsightsDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesActivitiesChartDetail
   * @summary Sales cycle activities chart data
   * @request GET:/saas_company_users/sales_cycles/{id}/activities_chart
   */
  export namespace SalesCyclesActivitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivitiesChartDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesClientsReachedChartDetail
   * @summary Sales cycle clients reached chart data
   * @request GET:/saas_company_users/sales_cycles/{id}/clients_reached_chart
   */
  export namespace SalesCyclesClientsReachedChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesClientsReachedChartDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesActivityOccurrencesChartDetail
   * @summary Activity occurrences chart data
   * @request GET:/saas_company_users/sales_cycles/{id}/activity_occurrences_chart
   */
  export namespace SalesCyclesActivityOccurrencesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      activity_event: AActivityEvents
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityOccurrencesChartDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesOpportunitiesChartDetail
   * @summary Opportunities chart data
   * @request GET:/saas_company_users/sales_cycles/{id}/opportunities_chart
   */
  export namespace SalesCyclesOpportunitiesChartDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesOpportunitiesChartDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesProspectsDetail
   * @summary Admins list of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
   * @request GET:/saas_company_users/sales_cycles/{id}/prospects
   */
  export namespace SalesCyclesProspectsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      sales_cycle_iteration_id?: number
      assignment_id?: string
      /** Search query */
      search?: string
      /** Last changed within */
      last_change?: string
      "statuses[]"?: string[]
      "stages[]"?: string[]
      "segments[]"?: string[]
      /** Sort by values */
      sort_by?: ASalesCyclesProspectValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesProspectsAutocompleteValuesDetail
   * @summary Returns the 10 most similar autocomplete values for specified field and value
   * @request GET:/saas_company_users/sales_cycles/{id}/prospects_autocomplete_values
   */
  export namespace SalesCyclesProspectsAutocompleteValuesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      /** Autocomplete field */
      field: ASalesCycleProspectAutocompleteFields
      /** Search value */
      value: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesProspectsAutocompleteValuesDetailOutput
  }

  /**
   * No description
   * @tags SaasCompanyUsers/SalesCycles
   * @name SalesCyclesSalesNewsDetail
   * @summary Sales cycle - current iteration sales news
   * @request GET:/saas_company_users/sales_cycles/{id}/sales_news
   */
  export namespace SalesCyclesSalesNewsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesNewsDetailOutput
  }
}

export namespace SaasSalesPeople {
  /**
   * No description
   * @tags SaasSalesPeople/SalesCycles
   * @name DashboardList
   * @summary Saas Sales Person dashboard data
   * @request GET:/saas_sales_people/dashboard
   */
  export namespace DashboardList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardListResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
   * @summary List of all prospects for assignment waiting to send for approve
   * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_prepare_to_approve
   */
  export namespace ProspectsAssignmentsProspectsPrepareToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsPrepareToApproveDetailResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
   * @summary List of all prospects for assignment waiting to approve by company / admin
   * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_waiting_to_approve
   */
  export namespace ProspectsAssignmentsProspectsWaitingToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsWaitingToApproveDetailOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsFinishedDetail
   * @summary List of all prospects for assignment already finished
   * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_finished
   */
  export namespace ProspectsAssignmentsProspectsFinishedDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsFinishedDetailResponse
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsSendForApprovalCreate
   * @summary Send assignment prospects in the sales person draft status for approval
   * @request POST:/saas_sales_people/prospects/assignments/{id}/send_for_approval
   */
  export namespace ProspectsAssignmentsSendForApprovalCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsSendForApprovalCreateResult
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesDetail
   * @summary Sales Cycle detail for SAAS Sales Person Prospects
   * @request GET:/saas_sales_people/prospects/sales_cycles/{id}
   */
  export namespace ProspectsSalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesDetailResponse
  }

  /**
   * No description
   * @tags SaasSalesPeople/SalesCycles
   * @name SalesCyclesActivityFeedsDetail
   * @summary Saas sales person activity feed for the current sales cycle
   * @request GET:/saas_sales_people/sales_cycles/{sales_cycle_id}/activity_feeds
   */
  export namespace SalesCyclesActivityFeedsDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityFeedsDetailResponse
  }

  /**
   * No description
   * @tags SaasSalesPeople/SalesCycles
   * @name SalesCyclesList
   * @summary List of all saas sales cycles for saas company
   * @request GET:/saas_sales_people/sales_cycles
   */
  export namespace SalesCyclesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** SalesCycle search on names */
      search?: string
      /** Sort by values */
      sort_by?: ASaasSalesCycleSortByValues
      /** Sort by direction */
      sort_direction?: ASaasSalesCycleSortDirectionValues
      /** Country filter */
      country_id?: number
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesListResult
  }

  /**
   * No description
   * @tags SaasSalesPeople/SalesCycles
   * @name SalesCyclesDetail
   * @summary Saas sales cycle detail
   * @request GET:/saas_sales_people/sales_cycles/{id}
   */
  export namespace SalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailOutput
  }

  /**
   * No description
   * @tags SaasSalesPeople/SalesCycles
   * @name SalesCyclesSalesNewsDetail
   * @summary Sales cycle - current iteration sales news
   * @request GET:/saas_sales_people/sales_cycles/{id}/sales_news
   */
  export namespace SalesCyclesSalesNewsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesNewsDetailResponse
  }
}

export namespace SalesCycles {
  /**
   * No description
   * @tags SalesCycles
   * @name SalesCyclesList
   * @summary List of all sales cycles
   * @request GET:/sales_cycles
   */
  export namespace SalesCyclesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesListOutput
  }

  /**
   * No description
   * @tags SalesCycles
   * @name SalesCyclesCreate
   * @summary Create sales cycle
   * @request POST:/sales_cycles
   */
  export namespace SalesCyclesCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      sales_cycle: {
        country_id: number
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesCreateData
  }

  /**
   * No description
   * @tags SalesCycles
   * @name SalesCyclesDetail
   * @summary Sales cycle detail
   * @request GET:/sales_cycles/{id}
   */
  export namespace SalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailResponse
  }

  /**
   * No description
   * @tags SalesCycles
   * @name SalesCyclesPartialUpdate
   * @summary Update sales cycle
   * @request PATCH:/sales_cycles/{id}
   */
  export namespace SalesCyclesPartialUpdate {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      sales_cycle?: ASalesCycleAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesPartialUpdateData
  }

  /**
   * No description
   * @tags SalesCycles
   * @name FinalizeCreate
   * @summary Finalize sales cycle process
   * @request POST:/sales_cycles/{id}/finalize
   */
  export namespace FinalizeCreate {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AFinalizeCreateData
  }

  /**
   * No description
   * @tags SalesCycles
   * @name FinalizeKeyDetailsCreate
   * @summary Finalize key details for sales cycle
   * @request POST:/sales_cycles/{id}/finalize_key_details
   */
  export namespace FinalizeKeyDetailsCreate {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AFinalizeKeyDetailsCreateData
  }

  /**
   * No description
   * @tags SalesCycles
   * @name PackagesDetail
   * @summary List of all packages for sales cycle
   * @request GET:/sales_cycles/{id}/packages
   */
  export namespace PackagesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APackagesDetailData
  }

  /**
   * No description
   * @tags SalesCycles
   * @name RemoveAttachmentDelete
   * @summary Remove sales cycle attachment
   * @request DELETE:/sales_cycles/{id}/remove_attachment
   */
  export namespace RemoveAttachmentDelete {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {
      attachment_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ARemoveAttachmentDeleteData
  }
}

export namespace SalesPeople {
  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsLeadsForAssignmentDetail
   * @summary List of all assignment leads
   * @request GET:/sales_people/leads/assignments/{id}/leads_for_assignment
   */
  export namespace LeadsAssignmentsLeadsForAssignmentDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      "country_ids[]"?: number[]
      "positions[]"?: string[]
      "segments[]"?: string[]
      "company_sizes[]"?: ALeadCompanySizes[]
      "ids[]"?: number[]
      /** Sort by values */
      sort_by?: ALeadSortByValues
      /** Sort by direction */
      sort_direction?: ALeadSortDirectionValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsLeadsForAssignmentDetailOutput
  }

  /**
   * No description
   * @tags Leads
   * @name LeadsAssignmentsAddProspectFromLeadCreate
   * @summary Add prospect to sales cycle from the leads database
   * @request POST:/sales_people/leads/assignments/{id}/add_prospect_from_lead
   */
  export namespace LeadsAssignmentsAddProspectFromLeadCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      lead_id: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ALeadsAssignmentsAddProspectFromLeadCreateOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
   * @summary List of all prospects for assignment waiting to send for approve
   * @request GET:/sales_people/prospects/assignments/{id}/prospects_prepare_to_approve
   */
  export namespace ProspectsAssignmentsProspectsPrepareToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsPrepareToApproveDetailOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
   * @summary List of all prospects for assignment waiting to approve by company / admin
   * @request GET:/sales_people/prospects/assignments/{id}/prospects_waiting_to_approve
   */
  export namespace ProspectsAssignmentsProspectsWaitingToApproveDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsWaitingToApproveDetailResponse
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsProspectsFinishedDetail
   * @summary List of all prospects for assignment already finished
   * @request GET:/sales_people/prospects/assignments/{id}/prospects_finished
   */
  export namespace ProspectsAssignmentsProspectsFinishedDetail {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {
      /** Search query */
      search?: string
      /** Sort by values */
      sort_by?: AProspectSortByValues
      /** Sort by direction */
      sort_direction?: AProspectSortDirectionValues
      /** Status filter */
      status?: AProspectQueryStatusValues
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsProspectsFinishedDetailPayload
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsAssignmentsSendForApprovalCreate
   * @summary Send assignment prospects in the sales person draft status for approval
   * @request POST:/sales_people/prospects/assignments/{id}/send_for_approval
   */
  export namespace ProspectsAssignmentsSendForApprovalCreate {
    export type RequestParams = {
      /** Assignment ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsAssignmentsSendForApprovalCreateOutput
  }

  /**
   * No description
   * @tags Prospects
   * @name ProspectsSalesCyclesDetail
   * @summary Sales Cycle detail for Sales Person Prospects
   * @request GET:/sales_people/prospects/sales_cycles/{id}
   */
  export namespace ProspectsSalesCyclesDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AProspectsSalesCyclesDetailPayload
  }

  /**
   * No description
   * @tags ActivityFeeds
   * @name SalesCyclesActivityFeedsDetail
   * @summary Sales person activity feed for the current sales cycle
   * @request GET:/sales_people/sales_cycles/{sales_cycle_id}/activity_feeds
   */
  export namespace SalesCyclesActivityFeedsDetail {
    export type RequestParams = {
      salesCycleId: number
    }
    export type RequestQuery = {
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesActivityFeedsDetailPayload
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SalesCyclesSalesNewsDetail
   * @summary Sales cycle - current iteration sales news
   * @request GET:/sales_people/sales_cycles/{id}/sales_news
   */
  export namespace SalesCyclesSalesNewsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesSalesNewsDetailPayload
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SalesCyclesDetailsDetail
   * @summary Sales cycle detail
   * @request GET:/sales_people/sales_cycles/{id}/details
   */
  export namespace SalesCyclesDetailsDetail {
    export type RequestParams = {
      /** Sales cycle ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesCyclesDetailsDetailOutput
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SalesPeopleCreate
   * @summary Registers in sales person
   * @request POST:/sales_people
   */
  export namespace SalesPeopleCreate {
    export type RequestParams = {}
    export type RequestQuery = {
      lang?: string
    }
    export type RequestBody = {
      sales_person: {
        email: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPeopleCreateData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name ConfirmationCreate
   * @summary Resend confirmation email
   * @request POST:/sales_people/confirmation
   */
  export namespace ConfirmationCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      sales_person: {
        email: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = AConfirmationCreateResult
  }

  /**
   * No description
   * @tags SalesPeople
   * @name ConfirmationPartialUpdate
   * @summary Confirms email address and saves user details
   * @request PATCH:/sales_people/confirmation
   */
  export namespace ConfirmationPartialUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      sales_person: {
        first_name: string
        last_name: string
        phone_number: string
        password: string
        password_confirmation: string
        tac_agreement: boolean
      }
      confirmation_token: string
    }
    export type RequestHeaders = {}
    export type ResponseBody = AConfirmationPartialUpdateResult
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SalesPeopleDetail
   * @summary Get sales person profile data
   * @request GET:/sales_people/{id}
   */
  export namespace SalesPeopleDetail {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {
      /** To specify sections returned in response */
      section?: ASalesPeopleDetailParamsSectionEnum
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPeopleDetailData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SalesPeoplePartialUpdate
   * @summary Update sales person
   * @request PATCH:/sales_people/{id}
   */
  export namespace SalesPeoplePartialUpdate {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {
      /** To specify sections returned in response */
      section?: ASalesPeoplePartialUpdateParamsSectionEnum
    }
    export type RequestBody = {
      sales_person?: ASalesPersonAttributes
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPeoplePartialUpdateData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name PublicDetail
   * @summary Get public sales person data
   * @request GET:/sales_people/{id}/public
   */
  export namespace PublicDetail {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = APublicDetailData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name AcceptPartialUpdate
   * @summary Accept sales person
   * @request PATCH:/sales_people/{id}/accept
   */
  export namespace AcceptPartialUpdate {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {
      /** To specify sections returned in response */
      section?: AAcceptPartialUpdateParams1SectionEnum
    }
    export type RequestBody = {
      sales_person?: AAcceptSalesPerson
    }
    export type RequestHeaders = {}
    export type ResponseBody = AAcceptPartialUpdateResult
  }

  /**
   * No description
   * @tags SalesPeople
   * @name BusinessDataDetail
   * @summary Get business data
   * @request GET:/sales_people/{id}/business_data
   */
  export namespace BusinessDataDetail {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ABusinessDataDetailData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name SubmitBusinessDataCreate
   * @summary Submit business data
   * @request POST:/sales_people/{id}/submit_business_data
   */
  export namespace SubmitBusinessDataCreate {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      sales_person: {
        company_name: string
        company_id: string
        street_address: string
        city: string
        postal_code: string
        country_id: number
        state?: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASubmitBusinessDataCreateData
  }

  /**
   * No description
   * @tags SalesPeople
   * @name DashboardDetail
   * @summary Get sales person dashboard data
   * @request GET:/sales_people/dashboard/{id}
   */
  export namespace DashboardDetail {
    export type RequestParams = {
      /** Sales Person ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ADashboardDetailResult
  }
}

export namespace SalesPlans {
  /**
   * No description
   * @tags Sales Plans
   * @name SalesPlansList
   * @summary List of all sales plans
   * @request GET:/sales_plans
   */
  export namespace SalesPlansList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** SalesPlan search on names */
      search?: string
      /** Sort by values */
      sort_by?: ASalesPlanSortByValues
      /** Sort by direction */
      sort_direction?: ASalesPlanSortDirectionValues
      /** Status filter */
      status?: ASalesPlanQueryStatusValues
      /** Country filter */
      country_id?: number
      /** Page number */
      page?: number
      /** Number of results per page */
      per_page?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASalesPlansListData
  }
}

export namespace Settings {
  /**
   * No description
   * @tags Settings
   * @name SettingsList
   * @summary List of all countries, currencies and other settings
   * @request GET:/settings
   */
  export namespace SettingsList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASettingsListData
  }
}

export namespace Subscriptions {
  /**
   * No description
   * @tags Subscriptions
   * @name CheckoutDetail
   * @summary Show order data
   * @request GET:/subscriptions/checkout/{id}
   */
  export namespace CheckoutDetail {
    export type RequestParams = {
      /** Order ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ACheckoutDetailData
  }

  /**
   * No description
   * @tags Subscriptions
   * @name CheckoutCreate
   * @summary Confirm order with customer data
   * @request POST:/subscriptions/checkout/{id}
   */
  export namespace CheckoutCreate {
    export type RequestParams = {
      /** Order ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      order?: {
        country_id: number
        payment_method: APaymentMethods
        /** must be true */
        terms_of_service: boolean
        subscription_billing_type: ASubscriptionBillingTypes
        customer_name: string
        customer_email: string
        city: string
        address: string
        postal_code: string
        state?: string
        company_name: string
        company_number: string
        company_tax_number?: string
        company_vat_number?: string
        invoice_detail_id?: number
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACheckoutCreateData
  }

  /**
   * No description
   * @tags Subscriptions
   * @name CheckoutUpdate
   * @summary Update order with specific params
   * @request PUT:/subscriptions/checkout/{id}
   */
  export namespace CheckoutUpdate {
    export type RequestParams = {
      /** Order ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      /** Update specific parameter Country or billing type, that recalculates prices for order */
      order?: {
        country_id?: number
        subscription_billing_type?: ASubscriptionBillingTypes
        company_vat_number?: string
        voucher_code_input?: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACheckoutUpdateData
  }

  /**
   * No description
   * @tags Subscriptions
   * @name CheckoutPreviewDetail
   * @summary Show preview order data
   * @request GET:/subscriptions/checkout/{id}/preview
   */
  export namespace CheckoutPreviewDetail {
    export type RequestParams = {
      /** Order ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ACheckoutPreviewDetailData
  }

  /**
   * No description
   * @tags Subscriptions
   * @name CheckoutCreate2
   * @summary Create order for submitted packages
   * @request POST:/subscriptions/checkout
   * @originalName checkoutCreate
   * @duplicate
   */
  export namespace CheckoutCreate2 {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      checkout?: {
        packages: {
          package_id: number
          sales_person_id?: number | null
        }[]
        sales_cycle_id: number
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACheckoutCreate2Data
  }

  /**
   * No description
   * @tags Subscriptions
   * @name CancelCreate
   * @summary Cancel subscription
   * @request POST:/subscriptions/{id}/cancel
   */
  export namespace CancelCreate {
    export type RequestParams = {
      /** Subscription ID */
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = {
      subscription: {
        cancel_reason: string | null
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ACancelCreateData
  }
}

export namespace Users {
  /**
   * No description
   * @tags Users
   * @name SignInCreate
   * @summary Creates a session / logs in user
   * @request POST:/users/sign_in
   */
  export namespace SignInCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      user?: {
        email: string
        password: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = ASignInCreateData
  }

  /**
   * No description
   * @tags Users
   * @name SignOutDelete
   * @summary Deletes a session / logs out user
   * @request DELETE:/users/sign_out
   */
  export namespace SignOutDelete {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ASignOutDeleteData
  }

  /**
   * No description
   * @tags Users
   * @name PasswordCreate
   * @summary Requests password reset (forgot password flow)
   * @request POST:/users/password
   */
  export namespace PasswordCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      user?: {
        email: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = APasswordCreateData
  }

  /**
   * No description
   * @tags Users
   * @name PasswordUpdate
   * @summary Reset password
   * @request PUT:/users/password
   */
  export namespace PasswordUpdate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = {
      user?: {
        password: string
        password_confirmation: string
        reset_password_token: string
      }
    }
    export type RequestHeaders = {}
    export type ResponseBody = APasswordUpdateData
  }
}

import type {AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType} from "axios"
import axios from "axios"

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"]
  private secure?: boolean
  private format?: ResponseType

  constructor({securityWorker, secure, format, ...axiosConfig}: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({...axiosConfig, baseURL: axiosConfig.baseURL || "https://api.closerocket.com"})
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? {"Content-Type": type} : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title API
 * @version v1
 * @baseUrl https://api.closerocket.com
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admins = {
    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsLeadsForAssignmentDetail
     * @summary List of all assignment leads
     * @request GET:/admins/leads/assignments/{id}/leads_for_assignment
     */
    leadsAssignmentsLeadsForAssignmentDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        "country_ids[]"?: number[]
        "positions[]"?: string[]
        "segments[]"?: string[]
        "company_sizes[]"?: ALeadCompanySizes[]
        "ids[]"?: number[]
        /** Sort by values */
        sort_by?: ALeadSortByValues
        /** Sort by direction */
        sort_direction?: ALeadSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsLeadsForAssignmentDetailData, void>({
        path: `/admins/leads/assignments/${id}/leads_for_assignment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add prospect to sales cycle from the leads database
     * @request POST:/admins/leads/assignments/{id}/add_prospect_from_lead
     */
    leadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      query: {
        lead_id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsAddProspectFromLeadCreateData, void>({
        path: `/admins/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsUploadProspectsCreate
     * @summary Assignment upload prospects for Admin Prospects
     * @request POST:/admins/prospects/assignments/{id}/upload_prospects
     */
    prospectsAssignmentsUploadProspectsCreate: (
      id: number,
      data: {
        "prospect_import[ownership_level]"?: AProspectImportOwnershipLevelEnum
        "prospect_import[ownership_id]"?: number | null
        "prospect_import[source]": string
        /** Attach prospect import file */
        "prospect_import[prospects_file]": File
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsUploadProspectsCreateData, void>({
        path: `/admins/prospects/assignments/${id}/upload_prospects`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
     * @summary List of all prospects for assignment waiting to send for approve
     * @request GET:/admins/prospects/assignments/{id}/prospects_prepare_to_approve
     */
    prospectsAssignmentsProspectsPrepareToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        sales_person_id?: number
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsPrepareToApproveDetailData, void>({
        path: `/admins/prospects/assignments/${id}/prospects_prepare_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
     * @summary List of all prospects for assignment waiting to approve by company
     * @request GET:/admins/prospects/assignments/{id}/prospects_waiting_to_approve
     */
    prospectsAssignmentsProspectsWaitingToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        sales_person_id?: number
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsWaitingToApproveDetailData, void>({
        path: `/admins/prospects/assignments/${id}/prospects_waiting_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsFinishedDetail
     * @summary List of all prospects for assignment already finished
     * @request GET:/admins/prospects/assignments/{id}/prospects_finished
     */
    prospectsAssignmentsProspectsFinishedDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        sales_person_id?: number
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsFinishedDetailData, void>({
        path: `/admins/prospects/assignments/${id}/prospects_finished`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsSendForApprovalCreate
     * @summary Send assignment prospects in the draft status for approval
     * @request POST:/admins/prospects/assignments/{id}/send_for_approval
     */
    prospectsAssignmentsSendForApprovalCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsSendForApprovalCreateData, void>({
        path: `/admins/prospects/assignments/${id}/send_for_approval`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsFinishApprovalCreate
     * @summary Finish approval process of ready-to-approve prospects
     * @request POST:/admins/prospects/assignments/{id}/finish_approval
     */
    prospectsAssignmentsFinishApprovalCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsFinishApprovalCreateData, void>({
        path: `/admins/prospects/assignments/${id}/finish_approval`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsSyncWithCrmCreate
     * @summary Sync approved finished assigned prospects with the CRM
     * @request POST:/admins/prospects/assignments/{id}/sync_with_crm
     */
    prospectsAssignmentsSyncWithCrmCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsSyncWithCrmCreateData, void>({
        path: `/admins/prospects/assignments/${id}/sync_with_crm`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsApproveProspectsCreate
     * @summary Approve and finished prospects without notifications
     * @request POST:/admins/prospects/assignments/{id}/approve_prospects
     */
    prospectsAssignmentsApproveProspectsCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsApproveProspectsCreateData, void>({
        path: `/admins/prospects/assignments/${id}/approve_prospects`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsAcceptWarningsCreate
     * @summary Approve prospect normalization warnings
     * @request POST:/admins/prospects/assignments/{id}/accept_warnings/{prospect_id}
     */
    prospectsAssignmentsAcceptWarningsCreate: (id: number, prospectId: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsAcceptWarningsCreateData, void>({
        path: `/admins/prospects/assignments/${id}/accept_warnings/${prospectId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesDetail
     * @summary Sales Cycle detail for Prospects
     * @request GET:/admins/prospects/sales_cycles/{id}
     */
    prospectsSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsSalesCyclesDetailData, void>({
        path: `/admins/prospects/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesLifetimeDetail
     * @summary Sales Cycle detail for Prospects
     * @request GET:/admins/prospects/sales_cycles/{id}/lifetime
     */
    prospectsSalesCyclesLifetimeDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        sales_person_id?: number
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsSalesCyclesLifetimeDetailData, void>({
        path: `/admins/prospects/sales_cycles/${id}/lifetime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsList
     * @summary List of all prospects with admin-visible data
     * @request GET:/admins/prospects
     */
    prospectsList: (
      query: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
        /** Prospects sales cycle ID */
        sales_cycle_id: number
        /** Search in phone numbers, email and organization name */
        search?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsListData, void>({
        path: `/admins/prospects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsExtendedIndexList
     * @summary List of all prospects with admin-visible data and prospects statistics
     * @request GET:/admins/prospects/extended_index
     */
    prospectsExtendedIndexList: (
      query: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
        /** Prospects sales cycle ID */
        sales_cycle_id: number
        /** Search in phone numbers, email and organization name */
        search?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsExtendedIndexListData, void>({
        path: `/admins/prospects/extended_index`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/ActivityFeeds
     * @name SalesCyclesActivityFeedsDetail
     * @summary Admin activity feed for the current sales cycle
     * @request GET:/admins/sales_cycles/{sales_cycle_id}/activity_feeds
     */
    salesCyclesActivityFeedsDetail: (
      salesCycleId: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityFeedsDetailData, void>({
        path: `/admins/sales_cycles/${salesCycleId}/activity_feeds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/admins/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
     */
    salesCyclesAssignmentsReportingDetail: (salesCycleId: number, id: string, params: RequestParams = {}) =>
      this.request<ASalesCyclesAssignmentsReportingDetailData, void>({
        path: `/admins/sales_cycles/${salesCycleId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
     */
    salesCyclesSalesCycleIterationsAssignmentsReportingDetail: (
      salesCycleId: number,
      salesCycleIterationId: number,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailData, void>({
        path: `/admins/sales_cycles/${salesCycleId}/sales_cycle_iterations/${salesCycleIterationId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesSalesCycleIterationsFinalizeReportCreate
     * @summary Admin finalize report for sales cycle iteration reporting data
     * @request POST:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/finalize_report
     */
    salesCyclesSalesCycleIterationsFinalizeReportCreate: (
      salesCycleId: number,
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesSalesCycleIterationsFinalizeReportCreateData, void>({
        path: `/admins/sales_cycles/${salesCycleId}/sales_cycle_iterations/${id}/finalize_report`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesSalesCycleIterationsReportingDetail
     * @summary Sales cycle iteration reporting data
     * @request GET:/admins/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
     */
    salesCyclesSalesCycleIterationsReportingDetail: (salesCycleId: number, id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesCycleIterationsReportingDetailData, void>({
        path: `/admins/sales_cycles/${salesCycleId}/sales_cycle_iterations/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesSalesNewsDetail
     * @summary Sales cycle - current iteration sales news
     * @request GET:/admins/sales_cycles/{id}/sales_news
     */
    salesCyclesSalesNewsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesNewsDetailData, void>({
        path: `/admins/sales_cycles/${id}/sales_news`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesDetailsDetail
     * @summary Sales cycle detail
     * @request GET:/admins/sales_cycles/{id}/details
     */
    salesCyclesDetailsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailsDetailData, void>({
        path: `/admins/sales_cycles/${id}/details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesReportingDetail
     * @summary Sales cycle reporting data
     * @request GET:/admins/sales_cycles/{id}/reporting
     */
    salesCyclesReportingDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesReportingDetailData, void>({
        path: `/admins/sales_cycles/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesNewsAndInsightsDetail
     * @summary Sales cycle / sales cycle iteration news and insights
     * @request GET:/admins/sales_cycles/{id}/news_and_insights
     */
    salesCyclesNewsAndInsightsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesNewsAndInsightsDetailData, void>({
        path: `/admins/sales_cycles/${id}/news_and_insights`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesActivitiesChartDetail
     * @summary Sales cycle activities chart data
     * @request GET:/admins/sales_cycles/{id}/activities_chart
     */
    salesCyclesActivitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivitiesChartDetailData, void>({
        path: `/admins/sales_cycles/${id}/activities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesClientsReachedChartDetail
     * @summary Sales cycle clients reached chart data
     * @request GET:/admins/sales_cycles/{id}/clients_reached_chart
     */
    salesCyclesClientsReachedChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesClientsReachedChartDetailData, void>({
        path: `/admins/sales_cycles/${id}/clients_reached_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesActivityOccurrencesChartDetail
     * @summary Activity occurrences chart data
     * @request GET:/admins/sales_cycles/{id}/activity_occurrences_chart
     */
    salesCyclesActivityOccurrencesChartDetail: (
      id: number,
      query: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        activity_event: AActivityEvents
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityOccurrencesChartDetailData, void>({
        path: `/admins/sales_cycles/${id}/activity_occurrences_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesOpportunitiesChartDetail
     * @summary Opportunities chart data
     * @request GET:/admins/sales_cycles/{id}/opportunities_chart
     */
    salesCyclesOpportunitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesOpportunitiesChartDetailData, void>({
        path: `/admins/sales_cycles/${id}/opportunities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesProspectsDetail
     * @summary Admins list of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
     * @request GET:/admins/sales_cycles/{id}/prospects
     */
    salesCyclesProspectsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        /** Search query */
        search?: string
        /** Last changed within */
        last_change?: string
        "statuses[]"?: string[]
        "stages[]"?: string[]
        "segments[]"?: string[]
        /** Sort by values */
        sort_by?: ASalesCyclesProspectValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsDetailData, void>({
        path: `/admins/sales_cycles/${id}/prospects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admins/SalesCycles
     * @name SalesCyclesProspectsAutocompleteValuesDetail
     * @summary Admins the 10 most similar autocomplete values for specified field and value
     * @request GET:/admins/sales_cycles/{id}/prospects_autocomplete_values
     */
    salesCyclesProspectsAutocompleteValuesDetail: (
      id: number,
      query: {
        /** Autocomplete field */
        field: ASalesCycleProspectAutocompleteFields
        /** Search value */
        value: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsAutocompleteValuesDetailData, void>({
        path: `/admins/sales_cycles/${id}/prospects_autocomplete_values`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  bulkActions = {
    /**
     * No description
     *
     * @tags Leads
     * @name AdminLeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add many prospects to assignment from the leads database
     * @request POST:/bulk_actions/admin/leads/assignments/{id}/add_prospect_from_lead
     */
    adminLeadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<AAdminLeadsAssignmentsAddProspectFromLeadCreateData, void>({
        path: `/bulk_actions/admin/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name AdminLeadsAssignmentsDeleteProspectFromLeadDelete
     * @summary Delete assigment prospects created from provided leads
     * @request DELETE:/bulk_actions/admin/leads/assignments/{id}/delete_prospect_from_lead
     */
    adminLeadsAssignmentsDeleteProspectFromLeadDelete: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<AAdminLeadsAssignmentsDeleteProspectFromLeadDeleteData, void>({
        path: `/bulk_actions/admin/leads/assignments/${id}/delete_prospect_from_lead`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsDelete
     * @summary Remove a list of leads
     * @request DELETE:/bulk_actions/leads
     */
    leadsDelete: (
      data: {
        ids: number[]
        delete_strategy?: ALeadDeleteStrategies
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsDeleteResult, void>({
        path: `/bulk_actions/leads`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsDisapprovePartialUpdate
     * @summary Disapprove a list of prospects
     * @request PATCH:/bulk_actions/prospects/disapprove
     */
    prospectsDisapprovePartialUpdate: (
      data: {
        ids: number[]
        disapprove_reason: AProspectDisapproveReasons
        disapprove_reason_text?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsDisapprovePartialUpdateData, void>({
        path: `/bulk_actions/prospects/disapprove`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsPrioritizePartialUpdate
     * @summary Prioritize a list of prospects
     * @request PATCH:/bulk_actions/prospects/prioritize
     */
    prospectsPrioritizePartialUpdate: (
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsPrioritizePartialUpdateData, void>({
        path: `/bulk_actions/prospects/prioritize`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsDeprioritizePartialUpdate
     * @summary Deprioritize a list of prospects
     * @request PATCH:/bulk_actions/prospects/deprioritize
     */
    prospectsDeprioritizePartialUpdate: (
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsDeprioritizePartialUpdateData, void>({
        path: `/bulk_actions/prospects/deprioritize`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsDelete
     * @summary Remove a list of prospects
     * @request DELETE:/bulk_actions/prospects
     */
    prospectsDelete: (
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsDeleteData, void>({
        path: `/bulk_actions/prospects`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name SaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add many prospects to assignment from the leads database
     * @request POST:/bulk_actions/saas_company_users/leads/assignments/{id}/add_prospect_from_lead
     */
    saasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<ASaasCompanyUsersLeadsAssignmentsAddProspectFromLeadCreateData, void>({
        path: `/bulk_actions/saas_company_users/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name SaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDelete
     * @summary Delete assigment prospects created from provided leads
     * @request DELETE:/bulk_actions/saas_company_users/leads/assignments/{id}/delete_prospect_from_lead
     */
    saasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDelete: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<ASaasCompanyUsersLeadsAssignmentsDeleteProspectFromLeadDeleteData, void>({
        path: `/bulk_actions/saas_company_users/leads/assignments/${id}/delete_prospect_from_lead`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name SalesPeopleLeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add many prospects to assignment from the leads database
     * @request POST:/bulk_actions/sales_people/leads/assignments/{id}/add_prospect_from_lead
     */
    salesPeopleLeadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPeopleLeadsAssignmentsAddProspectFromLeadCreateData, void>({
        path: `/bulk_actions/sales_people/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name SalesPeopleLeadsAssignmentsDeleteProspectFromLeadDelete
     * @summary Delete assigment prospects created from provided leads
     * @request DELETE:/bulk_actions/sales_people/leads/assignments/{id}/delete_prospect_from_lead
     */
    salesPeopleLeadsAssignmentsDeleteProspectFromLeadDelete: (
      id: number,
      data: {
        ids: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPeopleLeadsAssignmentsDeleteProspectFromLeadDeleteData, void>({
        path: `/bulk_actions/sales_people/leads/assignments/${id}/delete_prospect_from_lead`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  assignmentOffers = {
    /**
     * No description
     *
     * @tags Assignment Offers
     * @name AssignmentOffersList
     * @summary List of all assignment_offers
     * @request GET:/assignment_offers
     */
    assignmentOffersList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AAssignmentOffersListData, void>({
        path: `/assignment_offers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment Offers
     * @name AssignmentOffersCreate
     * @summary Create a new assignment_offer
     * @request POST:/assignment_offers
     */
    assignmentOffersCreate: (
      data: {
        assignment_offer: {
          assignment_id: number
          sales_person_id: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<AAssignmentOffersCreateData, void>({
        path: `/assignment_offers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment Offers
     * @name AssignmentOffersDetail
     * @summary Get one assignment offer
     * @request GET:/assignment_offers/{id}
     */
    assignmentOffersDetail: (id: number, params: RequestParams = {}) =>
      this.request<AAssignmentOffersDetailData, void>({
        path: `/assignment_offers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment Offers
     * @name AssignmentOffersDelete
     * @summary Cancel an assignment offer
     * @request DELETE:/assignment_offers/{id}
     */
    assignmentOffersDelete: (id: number, params: RequestParams = {}) =>
      this.request<
        AAssignmentOffersDeleteData,
        void | {
          errors?: AErrorBaseMessages
        }
      >({
        path: `/assignment_offers/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment Offers
     * @name AcceptPartialUpdate
     * @summary Accept an assignment offer
     * @request PATCH:/assignment_offers/{id}/accept
     */
    acceptPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<AAcceptPartialUpdateData, void>({
        path: `/assignment_offers/${id}/accept`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment Offers
     * @name RejectPartialUpdate
     * @summary Accept an assignment offer
     * @request PATCH:/assignment_offers/{id}/reject
     */
    rejectPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<ARejectPartialUpdateData, void>({
        path: `/assignment_offers/${id}/reject`,
        method: "PATCH",
        format: "json",
        ...params,
      }),
  }
  assignments = {
    /**
     * No description
     *
     * @tags Assignments
     * @name AssignmentsList
     * @summary List of all assignments
     * @request GET:/assignments
     */
    assignmentsList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AAssignmentsListData, void>({
        path: `/assignments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignments
     * @name AssignmentsDetail
     * @summary Get one assignment
     * @request GET:/assignments/{id}
     */
    assignmentsDetail: (id: number, params: RequestParams = {}) =>
      this.request<AAssignmentsDetailData, void>({
        path: `/assignments/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignments
     * @name RevokePartialUpdate
     * @summary Clear assignment from current sales person
     * @request PATCH:/assignments/{id}/revoke
     */
    revokePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<
        ARevokePartialUpdateData,
        void | {
          errors?: AErrorBaseMessages
        }
      >({
        path: `/assignments/${id}/revoke`,
        method: "PATCH",
        format: "json",
        ...params,
      }),
  }
  companies = {
    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesDetail
     * @summary Get company profile data
     * @request GET:/companies/{id}
     */
    companiesDetail: (
      id: number,
      query?: {
        /** To specify sections returned in response */
        section?: ACompaniesDetailParamsSectionEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<ACompaniesDetailData, void>({
        path: `/companies/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Companies
     * @name CompaniesPartialUpdate
     * @summary Update company
     * @request PATCH:/companies/{id}
     */
    companiesPartialUpdate: (
      id: number,
      data: {
        company?: ACompanyAttributes
      },
      query?: {
        /** To specify sections returned in response */
        section?: ACompaniesPartialUpdateParamsSectionEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<ACompaniesPartialUpdateData, void>({
        path: `/companies/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  companyUsers = {
    /**
     * No description
     *
     * @tags ActivityFeeds
     * @name ActivityFeedsLikePartialUpdate
     * @summary Activity feed company like
     * @request PATCH:/company_users/activity_feeds/{id}/like
     */
    activityFeedsLikePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<AActivityFeedsLikePartialUpdateData, void>({
        path: `/company_users/activity_feeds/${id}/like`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityFeeds
     * @name ActivityFeedsDislikePartialUpdate
     * @summary Activity feed company dislike
     * @request PATCH:/company_users/activity_feeds/{id}/dislike
     */
    activityFeedsDislikePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<AActivityFeedsDislikePartialUpdateData, void>({
        path: `/company_users/activity_feeds/${id}/dislike`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name DashboardDetail
     * @summary Get company user dashboard data
     * @request GET:/company_users/dashboard/{id}
     */
    dashboardDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardDetailData, void>({
        path: `/company_users/dashboard/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name DashboardActiveSalesCyclesDetail
     * @summary Get active sales cycles for company dashboard
     * @request GET:/company_users/dashboard/{id}/active_sales_cycles
     */
    dashboardActiveSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardActiveSalesCyclesDetailData, void>({
        path: `/company_users/dashboard/${id}/active_sales_cycles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name DashboardDraftedSalesCyclesDetail
     * @summary Get drafted sales cycles for company dashboard
     * @request GET:/company_users/dashboard/{id}/drafted_sales_cycles
     */
    dashboardDraftedSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardDraftedSalesCyclesDetailData, void>({
        path: `/company_users/dashboard/${id}/drafted_sales_cycles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name DashboardFinishedSalesCyclesDetail
     * @summary Get finished sales cycles for company dashboard
     * @request GET:/company_users/dashboard/{id}/finished_sales_cycles
     */
    dashboardFinishedSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardFinishedSalesCyclesDetailData, void>({
        path: `/company_users/dashboard/${id}/finished_sales_cycles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name DashboardPausedSalesCyclesDetail
     * @summary Get paused sales cycles for company dashboard
     * @request GET:/company_users/dashboard/{id}/paused_sales_cycles
     */
    dashboardPausedSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardPausedSalesCyclesDetailData, void>({
        path: `/company_users/dashboard/${id}/paused_sales_cycles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectDisapprovePartialUpdate
     * @summary Disapprove a prospect of assignment
     * @request PATCH:/company_users/prospects/assignments/{id}/prospect_disapprove
     */
    prospectsAssignmentsProspectDisapprovePartialUpdate: (
      id: number,
      data: {
        prospect: {
          /** Prospect ID */
          id: number
          disapprove_reason: AProspectDisapproveReasons
          disapprove_reason_text?: string | null
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectDisapprovePartialUpdateData, void>({
        path: `/company_users/prospects/assignments/${id}/prospect_disapprove`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
     * @summary Prospects Company list of all prospects for assignment waiting to approve
     * @request GET:/company_users/prospects/assignments/{id}/prospects_waiting_to_approve
     */
    prospectsAssignmentsProspectsWaitingToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: ACompanyProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsWaitingToApproveDetailResult, void>({
        path: `/company_users/prospects/assignments/${id}/prospects_waiting_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsFinishedDetail
     * @summary Prospects Company list of all prospects for assignment already finished
     * @request GET:/company_users/prospects/assignments/{id}/prospects_finished
     */
    prospectsAssignmentsProspectsFinishedDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: ACompanyProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsFinishedDetailResult, void>({
        path: `/company_users/prospects/assignments/${id}/prospects_finished`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsApproveProspectsCreate
     * @summary Company approve ready-to-approved prospects
     * @request POST:/company_users/prospects/assignments/{id}/approve_prospects
     */
    prospectsAssignmentsApproveProspectsCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsApproveProspectsCreateResult, void>({
        path: `/company_users/prospects/assignments/${id}/approve_prospects`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesDetail
     * @summary Sales Cycle detail for Company Prospects
     * @request GET:/company_users/prospects/sales_cycles/{id}
     */
    prospectsSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsSalesCyclesDetailResult, void>({
        path: `/company_users/prospects/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsList
     * @summary List of all prospects with company-user-visible data
     * @request GET:/company_users/prospects
     */
    prospectsList: (
      query: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
        /** Prospects sales cycle ID */
        sales_cycle_id: number
        /** Search organization name */
        search?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsListResult, void>({
        path: `/company_users/prospects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsExtendedIndexList
     * @summary List of all prospects with company-user-visible data and prospects statistics
     * @request GET:/company_users/prospects/extended_index
     */
    prospectsExtendedIndexList: (
      query: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
        /** Prospects sales cycle ID */
        sales_cycle_id: number
        /** Search organization name */
        search?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsExtendedIndexListResult, void>({
        path: `/company_users/prospects/extended_index`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCycleIterationsDetail
     * @summary Sales cycle iteration detail
     * @request GET:/company_users/sales_cycle_iterations/{id}
     * @deprecated
     */
    salesCycleIterationsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCycleIterationsDetailData, void>({
        path: `/company_users/sales_cycle_iterations/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityFeeds
     * @name SalesCyclesActivityFeedsDetail
     * @summary Company activity feed for the current sales cycle
     * @request GET:/company_users/sales_cycles/{sales_cycle_id}/activity_feeds
     */
    salesCyclesActivityFeedsDetail: (
      salesCycleId: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityFeedsDetailResult, void>({
        path: `/company_users/sales_cycles/${salesCycleId}/activity_feeds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/company_users/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
     */
    salesCyclesAssignmentsReportingDetail: (salesCycleId: number, id: string, params: RequestParams = {}) =>
      this.request<ASalesCyclesAssignmentsReportingDetailResult, void>({
        path: `/company_users/sales_cycles/${salesCycleId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
     */
    salesCyclesSalesCycleIterationsAssignmentsReportingDetail: (
      salesCycleId: number,
      salesCycleIterationId: number,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailResult, void>({
        path: `/company_users/sales_cycles/${salesCycleId}/sales_cycle_iterations/${salesCycleIterationId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesSalesCycleIterationsReportingDetail
     * @summary Sales cycle iteration reporting data
     * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
     */
    salesCyclesSalesCycleIterationsReportingDetail: (salesCycleId: number, id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesCycleIterationsReportingDetailResult, void>({
        path: `/company_users/sales_cycles/${salesCycleId}/sales_cycle_iterations/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers/SalesCycles/SalesPeople
     * @name SalesCyclesSalesPeopleDetail
     * @summary Sales cycle iteration reporting data
     * @request GET:/company_users/sales_cycles/{sales_cycle_id}/sales_people
     */
    salesCyclesSalesPeopleDetail: (salesCycleId: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesPeopleDetailData, void>({
        path: `/company_users/sales_cycles/${salesCycleId}/sales_people`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesDetail
     * @summary Sales cycle detail
     * @request GET:/company_users/sales_cycles/{id}
     * @deprecated
     */
    salesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailData, void>({
        path: `/company_users/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesSalesNewsDetail
     * @summary Sales cycle - current iteration sales news
     * @request GET:/company_users/sales_cycles/{id}/sales_news
     */
    salesCyclesSalesNewsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesNewsDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/sales_news`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesDetailsDetail
     * @summary Sales cycle detail
     * @request GET:/company_users/sales_cycles/{id}/details
     */
    salesCyclesDetailsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailsDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesReportingDetail
     * @summary Sales cycle reporting data
     * @request GET:/company_users/sales_cycles/{id}/reporting
     */
    salesCyclesReportingDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesReportingDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesNewsAndInsightsDetail
     * @summary Sales cycle / sales cycle iteration news and insights
     * @request GET:/company_users/sales_cycles/{id}/news_and_insights
     */
    salesCyclesNewsAndInsightsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesNewsAndInsightsDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/news_and_insights`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesActivitiesChartDetail
     * @summary Sales cycle activities chart data
     * @request GET:/company_users/sales_cycles/{id}/activities_chart
     */
    salesCyclesActivitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivitiesChartDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/activities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesClientsReachedChartDetail
     * @summary Sales cycle clients reached chart data
     * @request GET:/company_users/sales_cycles/{id}/clients_reached_chart
     */
    salesCyclesClientsReachedChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesClientsReachedChartDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/clients_reached_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesActivityOccurrencesChartDetail
     * @summary Activity occurrences chart data
     * @request GET:/company_users/sales_cycles/{id}/activity_occurrences_chart
     */
    salesCyclesActivityOccurrencesChartDetail: (
      id: number,
      query: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        activity_event: AActivityEvents
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityOccurrencesChartDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/activity_occurrences_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesOpportunitiesChartDetail
     * @summary Opportunities chart data
     * @request GET:/company_users/sales_cycles/{id}/opportunities_chart
     */
    salesCyclesOpportunitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesOpportunitiesChartDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/opportunities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesProspectsDetail
     * @summary List of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
     * @request GET:/company_users/sales_cycles/{id}/prospects
     */
    salesCyclesProspectsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        /** Search query */
        search?: string
        /** Last changed within */
        last_change?: string
        "statuses[]"?: ASalesCycleProspectStatuses[]
        "stages[]"?: ASalesCycleFilterStages[]
        "segments[]"?: string[]
        /** Sort by values */
        sort_by?: ASalesCyclesProspectValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/prospects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name SalesCyclesProspectsAutocompleteValuesDetail
     * @summary The 10 most similar autocomplete values for specified field and value
     * @request GET:/company_users/sales_cycles/{id}/prospects_autocomplete_values
     */
    salesCyclesProspectsAutocompleteValuesDetail: (
      id: number,
      query: {
        /** Autocomplete field */
        field: ASalesCycleProspectAutocompleteFields
        /** Search value */
        value: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsAutocompleteValuesDetailResult, void>({
        path: `/company_users/sales_cycles/${id}/prospects_autocomplete_values`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name CompanyUsersCreate
     * @summary Registers in company user
     * @request POST:/company_users
     */
    companyUsersCreate: (
      data: {
        company_user: {
          email: string
        }
      },
      query?: {
        lang?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ACompanyUsersCreateData, void>({
        path: `/company_users`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name ConfirmationCreate
     * @summary Resend confirmation email
     * @request POST:/company_users/confirmation
     */
    confirmationCreate: (
      data: {
        company_user: {
          email: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<AConfirmationCreateData, void>({
        path: `/company_users/confirmation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name ConfirmationPartialUpdate
     * @summary Confirms email address and saves user details
     * @request PATCH:/company_users/confirmation
     */
    confirmationPartialUpdate: (
      data: {
        company: {
          name: string
        }
        company_user: {
          phone_number: string
          password: string
          password_confirmation: string
          tac_agreement: boolean
        }
        confirmation_token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        AConfirmationPartialUpdateData,
        void | {
          errors?: AErrors
        }
      >({
        path: `/company_users/confirmation`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyUsers
     * @name WelcomeScreenDetail
     * @summary Get company user welcome screen data
     * @request GET:/company_users/welcome_screen/{id}
     */
    welcomeScreenDetail: (id: number, params: RequestParams = {}) =>
      this.request<AWelcomeScreenDetailData, void>({
        path: `/company_users/welcome_screen/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  countries = {
    /**
     * No description
     *
     * @tags Countries
     * @name CountriesList
     * @summary List of all countries
     * @request GET:/countries
     */
    countriesList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ACountriesListData, void>({
        path: `/countries`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  currencies = {
    /**
     * No description
     *
     * @tags Currencies
     * @name CurrenciesList
     * @summary List of all currencies
     * @request GET:/currencies
     */
    currenciesList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ACurrenciesListData, void>({
        path: `/currencies`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  importers = {
    /**
     * No description
     *
     * @tags Importers
     * @name RollbackDelete
     * @summary Rollback importer and its imported resources
     * @request DELETE:/importers/{id}/rollback
     */
    rollbackDelete: (id: number, params: RequestParams = {}) =>
      this.request<ARollbackDeleteData, void>({
        path: `/importers/${id}/rollback`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  }
  invoiceDetails = {
    /**
     * No description
     *
     * @tags InvoiceDetails
     * @name InvoiceDetailsList
     * @summary List of invoice details belongs to company or user
     * @request GET:/invoice_details
     */
    invoiceDetailsList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AInvoiceDetailsListData, void>({
        path: `/invoice_details`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceDetails
     * @name InvoiceDetailsCreate
     * @summary Creates a new invoice detail
     * @request POST:/invoice_details
     */
    invoiceDetailsCreate: (
      data: {
        invoice_detail: AInvoiceDetailAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<AInvoiceDetailsCreateData, void>({
        path: `/invoice_details`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceDetails
     * @name InvoiceDetailsUpdate
     * @summary Updates an invoice detail
     * @request PUT:/invoice_details/{id}
     */
    invoiceDetailsUpdate: (
      id: number,
      data: {
        invoice_detail: AInvoiceDetailAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<AInvoiceDetailsUpdateData, void>({
        path: `/invoice_details/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  languages = {
    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesList
     * @summary List of all languages
     * @request GET:/languages
     */
    languagesList: (params: RequestParams = {}) =>
      this.request<ALanguagesListData, void>({
        path: `/languages`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  leads = {
    /**
     * No description
     *
     * @tags Leads
     * @name LeadsList
     * @summary List of all leads
     * @request GET:/leads
     */
    leadsList: (
      query?: {
        /** Search query */
        search?: string
        "country_ids[]"?: number[]
        "positions[]"?: string[]
        "segments[]"?: string[]
        "company_sizes[]"?: ALeadCompanySizes[]
        "ids[]"?: number[]
        /** Sort by values */
        sort_by?: ALeadSortByValues
        /** Sort by direction */
        sort_direction?: ALeadSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsListData, void>({
        path: `/leads`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name AutocompleteValuesList
     * @summary The 10 most similar autocomplete values for specified field and value
     * @request GET:/leads/autocomplete_values
     */
    autocompleteValuesList: (
      query: {
        /** Autocomplete field */
        field: ALeadAutocompleteFields
        /** Search value */
        value: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AAutocompleteValuesListData, void>({
        path: `/leads/autocomplete_values`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name ImportCreate
     * @summary Imports list of leads
     * @request POST:/leads/import
     */
    importCreate: (
      data: {
        "leads_import[leads_file]": File
        "leads_import[source]": string
        "leads_import[ownership_level]"?: AOwnershipLevels
        "leads_import[owner_id]"?: number | null
      },
      params: RequestParams = {}
    ) =>
      this.request<AImportCreateData, void>({
        path: `/leads/import`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name SalesPersonImportCreate
     * @summary Imports list of sales person leads
     * @request POST:/leads/sales_person_import
     */
    salesPersonImportCreate: (
      data: {
        "leads_import[leads_file]": File
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPersonImportCreateData, void>({
        path: `/leads/sales_person_import`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsDelete
     * @summary Deletes lead
     * @request DELETE:/leads/{id}
     */
    leadsDelete: (
      id: number,
      query: {
        delete_strategy: ALeadDeleteStrategies
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsDeleteData, void>({
        path: `/leads/${id}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsDetail
     * @summary Lead detail
     * @request GET:/leads/{id}
     */
    leadsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ALeadsDetailData, void>({
        path: `/leads/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsPartialUpdate
     * @summary Updates a lead
     * @request PATCH:/leads/{id}
     */
    leadsPartialUpdate: (
      id: number,
      data: {
        lead: ALeadAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsPartialUpdateData, void>({
        path: `/leads/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name ImportersList
     * @summary List of leads importers
     * @request GET:/leads/importers
     */
    importersList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AImportersListData, void>({
        path: `/leads/importers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name IndexMetadataList
     * @summary Display metadata for leads index
     * @request GET:/leads/index_metadata
     */
    indexMetadataList: (params: RequestParams = {}) =>
      this.request<AIndexMetadataListData, void>({
        path: `/leads/index_metadata`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name RemovalRequestsIndexList
     * @summary List of requested removal leads
     * @request GET:/leads/removal_requests_index
     */
    removalRequestsIndexList: (
      query?: {
        /** Search query */
        search?: string
        "country_ids[]"?: number[]
        "positions[]"?: string[]
        "segments[]"?: string[]
        "company_sizes[]"?: ALeadCompanySizes[]
        "ids[]"?: number[]
        /** Sort by values */
        sort_by?: ALeadSortByValues
        /** Sort by direction */
        sort_direction?: ALeadSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ARemovalRequestsIndexListData, void>({
        path: `/leads/removal_requests_index`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name AcceptWarningsCreate
     * @summary Approve lead normalization warnings
     * @request POST:/leads/accept_warnings/{id}
     */
    acceptWarningsCreate: (id: number, params: RequestParams = {}) =>
      this.request<AAcceptWarningsCreateData, void>({
        path: `/leads/accept_warnings/${id}`,
        method: "POST",
        ...params,
      }),
  }
  salesCycleIterations = {
    /**
     * No description
     *
     * @tags Sales People for matching
     * @name MatchableSalesPeopleDetail
     * @summary List of all sales people for matching
     * @request GET:/sales_cycle_iterations/{id}/matchable_sales_people
     */
    matchableSalesPeopleDetail: (
      id: number,
      query?: {
        /** SalesPerson search on names */
        search?: string
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AMatchableSalesPeopleDetailData, void>({
        path: `/sales_cycle_iterations/${id}/matchable_sales_people`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycleIterations
     * @name SalesCycleIterationsList
     * @summary List of all sales cycle iterations
     * @request GET:/sales_cycle_iterations
     */
    salesCycleIterationsList: (
      query?: {
        /** Status filter */
        status?: ASalesCycleIterationsListParamsStatusEnum
        /** Start date filter */
        start_date?: string
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCycleIterationsListData, void>({
        path: `/sales_cycle_iterations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycleIterations
     * @name SalesCycleIterationsDetail
     * @summary One sales cycle iteration
     * @request GET:/sales_cycle_iterations/{id}
     */
    salesCycleIterationsDetail: (
      id: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCycleIterationsDetailResult, void>({
        path: `/sales_cycle_iterations/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  packages = {
    /**
     * No description
     *
     * @tags Packages
     * @name PackagesList
     * @summary List of all packages for country
     * @request GET:/packages
     */
    packagesList: (
      query?: {
        /** Country code (SK, CZ etc., default is SK) */
        country_code?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<APackagesListData, void>({
        path: `/packages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  pendingConfirmations = {
    /**
     * No description
     *
     * @tags Pending Confirmations
     * @name PendingConfirmationsDetail
     * @summary Get confirmation data for a pending confirmation
     * @request GET:/pending_confirmations/{confirmation_token}
     */
    pendingConfirmationsDetail: (confirmationToken: string, params: RequestParams = {}) =>
      this.request<APendingConfirmationsDetailData, any>({
        path: `/pending_confirmations/${confirmationToken}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  prospects = {
    /**
     * No description
     *
     * @tags Prospects
     * @name ImportCreate
     * @summary Imports list of prospects (with leads) for specified sales cycle
     * @request POST:/prospects/import
     */
    importCreate: (
      data: {
        sales_cycle_id: number
        owner_id?: number | null
        ownership_level?: AOwnershipLevels
        /** @format binary */
        prospects_file: File
        source: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AImportCreateResult, void>({
        path: `/prospects/import`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ApprovePartialUpdate
     * @summary Approve a prospect of sales cycle
     * @request PATCH:/prospects/{id}/approve
     * @deprecated
     */
    approvePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<AApprovePartialUpdateData, void>({
        path: `/prospects/${id}/approve`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name RemoveApproveStatusPartialUpdate
     * @summary Remove approve status for a prospect of sales cycle
     * @request PATCH:/prospects/{id}/remove_approve_status
     * @deprecated
     */
    removeApproveStatusPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<ARemoveApproveStatusPartialUpdateData, void>({
        path: `/prospects/${id}/remove_approve_status`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name DisapprovePartialUpdate
     * @summary Disapprove a prospect of sales cycle
     * @request PATCH:/prospects/{id}/disapprove
     * @deprecated
     */
    disapprovePartialUpdate: (
      id: number,
      data: {
        disapprove_reason: AProspectDisapproveReasons
      },
      params: RequestParams = {}
    ) =>
      this.request<ADisapprovePartialUpdateData, void>({
        path: `/prospects/${id}/disapprove`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name PrioritizePartialUpdate
     * @summary Prioritize a prospect of sales cycle
     * @request PATCH:/prospects/{id}/prioritize
     */
    prioritizePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<APrioritizePartialUpdateData, void>({
        path: `/prospects/${id}/prioritize`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name DeprioritizePartialUpdate
     * @summary Deprioritize a prospect of sales cycle
     * @request PATCH:/prospects/{id}/deprioritize
     */
    deprioritizePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<ADeprioritizePartialUpdateData, void>({
        path: `/prospects/${id}/deprioritize`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsCreate
     * @summary Creates a prospect
     * @request POST:/prospects
     */
    prospectsCreate: (
      query: {
        assignment_id: number
      },
      data: {
        prospect: AProspectAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsCreateData, void>({
        path: `/prospects`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsPartialUpdate
     * @summary Updates a prospect
     * @request PATCH:/prospects/{id}
     */
    prospectsPartialUpdate: (
      id: number,
      data: {
        prospect: AProspectAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsPartialUpdateData, void>({
        path: `/prospects/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsDelete
     * @summary Permanently deletes a prospect
     * @request DELETE:/prospects/{id}
     */
    prospectsDelete: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsDeleteResult, void>({
        path: `/prospects/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name SaasUpdatePartialUpdate
     * @summary Updates a SAAS prospect
     * @request PATCH:/prospects/{id}/saas_update
     */
    saasUpdatePartialUpdate: (
      id: number,
      data: {
        prospect: AProspectAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<ASaasUpdatePartialUpdateData, void>({
        path: `/prospects/${id}/saas_update`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name RemoveDisapprovePartialUpdate
     * @summary Remove disapprove status for a prospect of sales cycle
     * @request PATCH:/prospects/{id}/remove_disapprove
     */
    removeDisapprovePartialUpdate: (id: number, params: RequestParams = {}) =>
      this.request<ARemoveDisapprovePartialUpdateData, void>({
        path: `/prospects/${id}/remove_disapprove`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ActivitiesDetail
     * @summary All or iteration scoped activities of prospects
     * @request GET:/prospects/{id}/activities
     */
    activitiesDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AActivitiesDetailData, void>({
        path: `/prospects/${id}/activities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  privacyRequest = {
    /**
     * No description
     *
     * @tags PrivacyRequests
     * @name PrivacyRequestList
     * @summary Privacy request detail
     * @request GET:/privacy_request
     */
    privacyRequestList: (
      query: {
        /** Privacy request token */
        token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<APrivacyRequestListData, void>({
        path: `/privacy_request`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PrivacyRequests
     * @name RequestRemoveCreate
     * @summary Request remove privacy request for lead data
     * @request POST:/privacy_request/request_remove
     */
    requestRemoveCreate: (
      query: {
        /** Privacy request token */
        token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ARequestRemoveCreateData, void>({
        path: `/privacy_request/request_remove`,
        method: "POST",
        query: query,
        ...params,
      }),
  }
  saasCompanyUsers = {
    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name DashboardList
     * @summary Saas Company User dashboard data
     * @request GET:/saas_company_users/dashboard
     */
    dashboardList: (params: RequestParams = {}) =>
      this.request<ADashboardListData, void>({
        path: `/saas_company_users/dashboard`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsLeadsForAssignmentDetail
     * @summary List of all assignment leads
     * @request GET:/saas_company_users/leads/assignments/{id}/leads_for_assignment
     */
    leadsAssignmentsLeadsForAssignmentDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        "country_ids[]"?: number[]
        "positions[]"?: string[]
        "segments[]"?: string[]
        "company_sizes[]"?: ALeadCompanySizes[]
        "ids[]"?: number[]
        /** Sort by values */
        sort_by?: ALeadSortByValues
        /** Sort by direction */
        sort_direction?: ALeadSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsLeadsForAssignmentDetailResult, void>({
        path: `/saas_company_users/leads/assignments/${id}/leads_for_assignment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add prospect to sales cycle from the leads database
     * @request POST:/saas_company_users/leads/assignments/{id}/add_prospect_from_lead
     */
    leadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      query: {
        lead_id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsAddProspectFromLeadCreateResult, void>({
        path: `/saas_company_users/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsUploadProspectsCreate
     * @summary Assignment upload prospects for SAAS Company Users Prospects
     * @request POST:/saas_company_users/prospects/assignments/{id}/upload_prospects
     */
    prospectsAssignmentsUploadProspectsCreate: (
      id: number,
      data: {
        /** Attach prospect import file */
        prospects_file: File
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsUploadProspectsCreateResult, any>({
        path: `/saas_company_users/prospects/assignments/${id}/upload_prospects`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsFinishedDetail
     * @summary List of all prospects for assignment already finished
     * @request GET:/saas_company_users/prospects/assignments/{id}/prospects_finished
     */
    prospectsAssignmentsProspectsFinishedDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsFinishedDetailOutput, void>({
        path: `/saas_company_users/prospects/assignments/${id}/prospects_finished`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsSyncWithCrmCreate
     * @summary Sync approved finished assigned prospects with the CRM
     * @request POST:/saas_company_users/prospects/assignments/{id}/sync_with_crm
     */
    prospectsAssignmentsSyncWithCrmCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsSyncWithCrmCreateResult, void>({
        path: `/saas_company_users/prospects/assignments/${id}/sync_with_crm`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsApproveProspectsCreate
     * @summary Approve and finished prospects without notifications
     * @request POST:/saas_company_users/prospects/assignments/{id}/approve_prospects
     */
    prospectsAssignmentsApproveProspectsCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsApproveProspectsCreateOutput, void>({
        path: `/saas_company_users/prospects/assignments/${id}/approve_prospects`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesDetail
     * @summary SAAS Sales Cycle detail for Prospects
     * @request GET:/saas_company_users/prospects/sales_cycles/{id}
     */
    prospectsSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsSalesCyclesDetailOutput, void>({
        path: `/saas_company_users/prospects/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesActivityFeedsDetail
     * @summary Company activity feed for the current sales cycle
     * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/activity_feeds
     */
    salesCyclesActivityFeedsDetail: (
      salesCycleId: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityFeedsDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${salesCycleId}/activity_feeds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/assignments/{id}/reporting
     */
    salesCyclesAssignmentsReportingDetail: (salesCycleId: number, id: string, params: RequestParams = {}) =>
      this.request<ASalesCyclesAssignmentsReportingDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${salesCycleId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesSalesCycleIterationsAssignmentsReportingDetail
     * @summary Assignment reporting data
     * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{sales_cycle_iteration_id}/assignments/{id}/reporting
     */
    salesCyclesSalesCycleIterationsAssignmentsReportingDetail: (
      salesCycleId: number,
      salesCycleIterationId: number,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesSalesCycleIterationsAssignmentsReportingDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${salesCycleId}/sales_cycle_iterations/${salesCycleIterationId}/assignments/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesSalesCycleIterationsReportingDetail
     * @summary Sales cycle iteration reporting data
     * @request GET:/saas_company_users/sales_cycles/{sales_cycle_id}/sales_cycle_iterations/{id}/reporting
     */
    salesCyclesSalesCycleIterationsReportingDetail: (salesCycleId: number, id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesCycleIterationsReportingDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${salesCycleId}/sales_cycle_iterations/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesList
     * @summary List of all saas sales cycles for saas company
     * @request GET:/saas_company_users/sales_cycles
     */
    salesCyclesList: (
      query: {
        /** Start date for iteration in 2024-08-01 format. Dates are received in dashboard */
        start_date: string
        /** SalesCycle search on names */
        search?: string
        /** Sort by values */
        sort_by?: ASaasSalesCycleSortByValues
        /** Sort by direction */
        sort_direction?: ASaasSalesCycleSortDirectionValues
        /** Country filter */
        country_id?: number
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesListData, void>({
        path: `/saas_company_users/sales_cycles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesDetail
     * @summary Saas sales cycle detail
     * @request GET:/saas_company_users/sales_cycles/{id}
     */
    salesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailResult, void>({
        path: `/saas_company_users/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesReportingDetail
     * @summary SAAS sales cycle reporting data
     * @request GET:/saas_company_users/sales_cycles/{id}/reporting
     */
    salesCyclesReportingDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesReportingDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/reporting`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesNewsAndInsightsDetail
     * @summary Sales cycle / sales cycle iteration news and insights
     * @request GET:/saas_company_users/sales_cycles/{id}/news_and_insights
     */
    salesCyclesNewsAndInsightsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesNewsAndInsightsDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/news_and_insights`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesActivitiesChartDetail
     * @summary Sales cycle activities chart data
     * @request GET:/saas_company_users/sales_cycles/{id}/activities_chart
     */
    salesCyclesActivitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivitiesChartDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/activities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesClientsReachedChartDetail
     * @summary Sales cycle clients reached chart data
     * @request GET:/saas_company_users/sales_cycles/{id}/clients_reached_chart
     */
    salesCyclesClientsReachedChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesClientsReachedChartDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/clients_reached_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesActivityOccurrencesChartDetail
     * @summary Activity occurrences chart data
     * @request GET:/saas_company_users/sales_cycles/{id}/activity_occurrences_chart
     */
    salesCyclesActivityOccurrencesChartDetail: (
      id: number,
      query: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        activity_event: AActivityEvents
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityOccurrencesChartDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/activity_occurrences_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesOpportunitiesChartDetail
     * @summary Opportunities chart data
     * @request GET:/saas_company_users/sales_cycles/{id}/opportunities_chart
     */
    salesCyclesOpportunitiesChartDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesOpportunitiesChartDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/opportunities_chart`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesProspectsDetail
     * @summary Admins list of prospects for Sales Cycle, Sales Cycle Iteration or Assignment
     * @request GET:/saas_company_users/sales_cycles/{id}/prospects
     */
    salesCyclesProspectsDetail: (
      id: number,
      query?: {
        sales_cycle_iteration_id?: number
        assignment_id?: string
        /** Search query */
        search?: string
        /** Last changed within */
        last_change?: string
        "statuses[]"?: string[]
        "stages[]"?: string[]
        "segments[]"?: string[]
        /** Sort by values */
        sort_by?: ASalesCyclesProspectValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/prospects`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesProspectsAutocompleteValuesDetail
     * @summary Returns the 10 most similar autocomplete values for specified field and value
     * @request GET:/saas_company_users/sales_cycles/{id}/prospects_autocomplete_values
     */
    salesCyclesProspectsAutocompleteValuesDetail: (
      id: number,
      query: {
        /** Autocomplete field */
        field: ASalesCycleProspectAutocompleteFields
        /** Search value */
        value: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesProspectsAutocompleteValuesDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/prospects_autocomplete_values`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasCompanyUsers/SalesCycles
     * @name SalesCyclesSalesNewsDetail
     * @summary Sales cycle - current iteration sales news
     * @request GET:/saas_company_users/sales_cycles/{id}/sales_news
     */
    salesCyclesSalesNewsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesNewsDetailOutput, void>({
        path: `/saas_company_users/sales_cycles/${id}/sales_news`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  saasSalesPeople = {
    /**
     * No description
     *
     * @tags SaasSalesPeople/SalesCycles
     * @name DashboardList
     * @summary Saas Sales Person dashboard data
     * @request GET:/saas_sales_people/dashboard
     */
    dashboardList: (params: RequestParams = {}) =>
      this.request<ADashboardListResult, void>({
        path: `/saas_sales_people/dashboard`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
     * @summary List of all prospects for assignment waiting to send for approve
     * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_prepare_to_approve
     */
    prospectsAssignmentsProspectsPrepareToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsPrepareToApproveDetailResult, void>({
        path: `/saas_sales_people/prospects/assignments/${id}/prospects_prepare_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
     * @summary List of all prospects for assignment waiting to approve by company / admin
     * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_waiting_to_approve
     */
    prospectsAssignmentsProspectsWaitingToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsWaitingToApproveDetailOutput, void>({
        path: `/saas_sales_people/prospects/assignments/${id}/prospects_waiting_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsFinishedDetail
     * @summary List of all prospects for assignment already finished
     * @request GET:/saas_sales_people/prospects/assignments/{id}/prospects_finished
     */
    prospectsAssignmentsProspectsFinishedDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsFinishedDetailResponse, void>({
        path: `/saas_sales_people/prospects/assignments/${id}/prospects_finished`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsSendForApprovalCreate
     * @summary Send assignment prospects in the sales person draft status for approval
     * @request POST:/saas_sales_people/prospects/assignments/{id}/send_for_approval
     */
    prospectsAssignmentsSendForApprovalCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsSendForApprovalCreateResult, void>({
        path: `/saas_sales_people/prospects/assignments/${id}/send_for_approval`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesDetail
     * @summary Sales Cycle detail for SAAS Sales Person Prospects
     * @request GET:/saas_sales_people/prospects/sales_cycles/{id}
     */
    prospectsSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsSalesCyclesDetailResponse, void>({
        path: `/saas_sales_people/prospects/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasSalesPeople/SalesCycles
     * @name SalesCyclesActivityFeedsDetail
     * @summary Saas sales person activity feed for the current sales cycle
     * @request GET:/saas_sales_people/sales_cycles/{sales_cycle_id}/activity_feeds
     */
    salesCyclesActivityFeedsDetail: (
      salesCycleId: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityFeedsDetailResponse, void>({
        path: `/saas_sales_people/sales_cycles/${salesCycleId}/activity_feeds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasSalesPeople/SalesCycles
     * @name SalesCyclesList
     * @summary List of all saas sales cycles for saas company
     * @request GET:/saas_sales_people/sales_cycles
     */
    salesCyclesList: (
      query?: {
        /** SalesCycle search on names */
        search?: string
        /** Sort by values */
        sort_by?: ASaasSalesCycleSortByValues
        /** Sort by direction */
        sort_direction?: ASaasSalesCycleSortDirectionValues
        /** Country filter */
        country_id?: number
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesListResult, void>({
        path: `/saas_sales_people/sales_cycles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasSalesPeople/SalesCycles
     * @name SalesCyclesDetail
     * @summary Saas sales cycle detail
     * @request GET:/saas_sales_people/sales_cycles/{id}
     */
    salesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailOutput, void>({
        path: `/saas_sales_people/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaasSalesPeople/SalesCycles
     * @name SalesCyclesSalesNewsDetail
     * @summary Sales cycle - current iteration sales news
     * @request GET:/saas_sales_people/sales_cycles/{id}/sales_news
     */
    salesCyclesSalesNewsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesNewsDetailResponse, void>({
        path: `/saas_sales_people/sales_cycles/${id}/sales_news`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  salesCycles = {
    /**
     * No description
     *
     * @tags SalesCycles
     * @name SalesCyclesList
     * @summary List of all sales cycles
     * @request GET:/sales_cycles
     */
    salesCyclesList: (
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesListOutput, void>({
        path: `/sales_cycles`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name SalesCyclesCreate
     * @summary Create sales cycle
     * @request POST:/sales_cycles
     */
    salesCyclesCreate: (
      data: {
        sales_cycle: {
          country_id: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesCreateData, void>({
        path: `/sales_cycles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name SalesCyclesDetail
     * @summary Sales cycle detail
     * @request GET:/sales_cycles/{id}
     */
    salesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailResponse, void>({
        path: `/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name SalesCyclesPartialUpdate
     * @summary Update sales cycle
     * @request PATCH:/sales_cycles/{id}
     */
    salesCyclesPartialUpdate: (
      id: number,
      data: {
        sales_cycle?: ASalesCycleAttributes
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesPartialUpdateData, void>({
        path: `/sales_cycles/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name FinalizeCreate
     * @summary Finalize sales cycle process
     * @request POST:/sales_cycles/{id}/finalize
     */
    finalizeCreate: (id: number, params: RequestParams = {}) =>
      this.request<AFinalizeCreateData, void | AErrors>({
        path: `/sales_cycles/${id}/finalize`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name FinalizeKeyDetailsCreate
     * @summary Finalize key details for sales cycle
     * @request POST:/sales_cycles/{id}/finalize_key_details
     */
    finalizeKeyDetailsCreate: (id: number, params: RequestParams = {}) =>
      this.request<AFinalizeKeyDetailsCreateData, void | AErrors>({
        path: `/sales_cycles/${id}/finalize_key_details`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name PackagesDetail
     * @summary List of all packages for sales cycle
     * @request GET:/sales_cycles/{id}/packages
     */
    packagesDetail: (id: number, params: RequestParams = {}) =>
      this.request<APackagesDetailData, void>({
        path: `/sales_cycles/${id}/packages`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesCycles
     * @name RemoveAttachmentDelete
     * @summary Remove sales cycle attachment
     * @request DELETE:/sales_cycles/{id}/remove_attachment
     */
    removeAttachmentDelete: (
      id: number,
      query: {
        attachment_id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ARemoveAttachmentDeleteData, void>({
        path: `/sales_cycles/${id}/remove_attachment`,
        method: "DELETE",
        query: query,
        ...params,
      }),
  }
  salesPeople = {
    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsLeadsForAssignmentDetail
     * @summary List of all assignment leads
     * @request GET:/sales_people/leads/assignments/{id}/leads_for_assignment
     */
    leadsAssignmentsLeadsForAssignmentDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        "country_ids[]"?: number[]
        "positions[]"?: string[]
        "segments[]"?: string[]
        "company_sizes[]"?: ALeadCompanySizes[]
        "ids[]"?: number[]
        /** Sort by values */
        sort_by?: ALeadSortByValues
        /** Sort by direction */
        sort_direction?: ALeadSortDirectionValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsLeadsForAssignmentDetailOutput, void>({
        path: `/sales_people/leads/assignments/${id}/leads_for_assignment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsAssignmentsAddProspectFromLeadCreate
     * @summary Add prospect to sales cycle from the leads database
     * @request POST:/sales_people/leads/assignments/{id}/add_prospect_from_lead
     */
    leadsAssignmentsAddProspectFromLeadCreate: (
      id: number,
      query: {
        lead_id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ALeadsAssignmentsAddProspectFromLeadCreateOutput, void>({
        path: `/sales_people/leads/assignments/${id}/add_prospect_from_lead`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsPrepareToApproveDetail
     * @summary List of all prospects for assignment waiting to send for approve
     * @request GET:/sales_people/prospects/assignments/{id}/prospects_prepare_to_approve
     */
    prospectsAssignmentsProspectsPrepareToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsPrepareToApproveDetailOutput, void>({
        path: `/sales_people/prospects/assignments/${id}/prospects_prepare_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsWaitingToApproveDetail
     * @summary List of all prospects for assignment waiting to approve by company / admin
     * @request GET:/sales_people/prospects/assignments/{id}/prospects_waiting_to_approve
     */
    prospectsAssignmentsProspectsWaitingToApproveDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsWaitingToApproveDetailResponse, void>({
        path: `/sales_people/prospects/assignments/${id}/prospects_waiting_to_approve`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsProspectsFinishedDetail
     * @summary List of all prospects for assignment already finished
     * @request GET:/sales_people/prospects/assignments/{id}/prospects_finished
     */
    prospectsAssignmentsProspectsFinishedDetail: (
      id: number,
      query?: {
        /** Search query */
        search?: string
        /** Sort by values */
        sort_by?: AProspectSortByValues
        /** Sort by direction */
        sort_direction?: AProspectSortDirectionValues
        /** Status filter */
        status?: AProspectQueryStatusValues
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AProspectsAssignmentsProspectsFinishedDetailPayload, void>({
        path: `/sales_people/prospects/assignments/${id}/prospects_finished`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsAssignmentsSendForApprovalCreate
     * @summary Send assignment prospects in the sales person draft status for approval
     * @request POST:/sales_people/prospects/assignments/{id}/send_for_approval
     */
    prospectsAssignmentsSendForApprovalCreate: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsAssignmentsSendForApprovalCreateOutput, void>({
        path: `/sales_people/prospects/assignments/${id}/send_for_approval`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prospects
     * @name ProspectsSalesCyclesDetail
     * @summary Sales Cycle detail for Sales Person Prospects
     * @request GET:/sales_people/prospects/sales_cycles/{id}
     */
    prospectsSalesCyclesDetail: (id: number, params: RequestParams = {}) =>
      this.request<AProspectsSalesCyclesDetailPayload, void>({
        path: `/sales_people/prospects/sales_cycles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityFeeds
     * @name SalesCyclesActivityFeedsDetail
     * @summary Sales person activity feed for the current sales cycle
     * @request GET:/sales_people/sales_cycles/{sales_cycle_id}/activity_feeds
     */
    salesCyclesActivityFeedsDetail: (
      salesCycleId: number,
      query?: {
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesCyclesActivityFeedsDetailPayload, void>({
        path: `/sales_people/sales_cycles/${salesCycleId}/activity_feeds`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SalesCyclesSalesNewsDetail
     * @summary Sales cycle - current iteration sales news
     * @request GET:/sales_people/sales_cycles/{id}/sales_news
     */
    salesCyclesSalesNewsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesSalesNewsDetailPayload, void>({
        path: `/sales_people/sales_cycles/${id}/sales_news`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SalesCyclesDetailsDetail
     * @summary Sales cycle detail
     * @request GET:/sales_people/sales_cycles/{id}/details
     */
    salesCyclesDetailsDetail: (id: number, params: RequestParams = {}) =>
      this.request<ASalesCyclesDetailsDetailOutput, void>({
        path: `/sales_people/sales_cycles/${id}/details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SalesPeopleCreate
     * @summary Registers in sales person
     * @request POST:/sales_people
     */
    salesPeopleCreate: (
      data: {
        sales_person: {
          email: string
        }
      },
      query?: {
        lang?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPeopleCreateData, void>({
        path: `/sales_people`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name ConfirmationCreate
     * @summary Resend confirmation email
     * @request POST:/sales_people/confirmation
     */
    confirmationCreate: (
      data: {
        sales_person: {
          email: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<AConfirmationCreateResult, void>({
        path: `/sales_people/confirmation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name ConfirmationPartialUpdate
     * @summary Confirms email address and saves user details
     * @request PATCH:/sales_people/confirmation
     */
    confirmationPartialUpdate: (
      data: {
        sales_person: {
          first_name: string
          last_name: string
          phone_number: string
          password: string
          password_confirmation: string
          tac_agreement: boolean
        }
        confirmation_token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        AConfirmationPartialUpdateResult,
        void | {
          errors: AErrors
        }
      >({
        path: `/sales_people/confirmation`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SalesPeopleDetail
     * @summary Get sales person profile data
     * @request GET:/sales_people/{id}
     */
    salesPeopleDetail: (
      id: number,
      query?: {
        /** To specify sections returned in response */
        section?: ASalesPeopleDetailParamsSectionEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPeopleDetailData, void>({
        path: `/sales_people/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SalesPeoplePartialUpdate
     * @summary Update sales person
     * @request PATCH:/sales_people/{id}
     */
    salesPeoplePartialUpdate: (
      id: number,
      data: {
        sales_person?: ASalesPersonAttributes
      },
      query?: {
        /** To specify sections returned in response */
        section?: ASalesPeoplePartialUpdateParamsSectionEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPeoplePartialUpdateData, void>({
        path: `/sales_people/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name PublicDetail
     * @summary Get public sales person data
     * @request GET:/sales_people/{id}/public
     */
    publicDetail: (id: number, params: RequestParams = {}) =>
      this.request<APublicDetailData, void>({
        path: `/sales_people/${id}/public`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name AcceptPartialUpdate
     * @summary Accept sales person
     * @request PATCH:/sales_people/{id}/accept
     */
    acceptPartialUpdate: (
      id: number,
      data: {
        sales_person?: AAcceptSalesPerson
      },
      query?: {
        /** To specify sections returned in response */
        section?: AAcceptPartialUpdateParams1SectionEnum
      },
      params: RequestParams = {}
    ) =>
      this.request<AAcceptPartialUpdateResult, void>({
        path: `/sales_people/${id}/accept`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name BusinessDataDetail
     * @summary Get business data
     * @request GET:/sales_people/{id}/business_data
     */
    businessDataDetail: (id: number, params: RequestParams = {}) =>
      this.request<ABusinessDataDetailData, void>({
        path: `/sales_people/${id}/business_data`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name SubmitBusinessDataCreate
     * @summary Submit business data
     * @request POST:/sales_people/{id}/submit_business_data
     */
    submitBusinessDataCreate: (
      id: number,
      data: {
        sales_person: {
          company_name: string
          company_id: string
          street_address: string
          city: string
          postal_code: string
          country_id: number
          state?: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ASubmitBusinessDataCreateData, void>({
        path: `/sales_people/${id}/submit_business_data`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesPeople
     * @name DashboardDetail
     * @summary Get sales person dashboard data
     * @request GET:/sales_people/dashboard/{id}
     */
    dashboardDetail: (id: number, params: RequestParams = {}) =>
      this.request<ADashboardDetailResult, void>({
        path: `/sales_people/dashboard/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  salesPlans = {
    /**
     * No description
     *
     * @tags Sales Plans
     * @name SalesPlansList
     * @summary List of all sales plans
     * @request GET:/sales_plans
     */
    salesPlansList: (
      query?: {
        /** SalesPlan search on names */
        search?: string
        /** Sort by values */
        sort_by?: ASalesPlanSortByValues
        /** Sort by direction */
        sort_direction?: ASalesPlanSortDirectionValues
        /** Status filter */
        status?: ASalesPlanQueryStatusValues
        /** Country filter */
        country_id?: number
        /** Page number */
        page?: number
        /** Number of results per page */
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ASalesPlansListData, void>({
        path: `/sales_plans`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  }
  settings = {
    /**
     * No description
     *
     * @tags Settings
     * @name SettingsList
     * @summary List of all countries, currencies and other settings
     * @request GET:/settings
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<ASettingsListData, void>({
        path: `/settings`,
        method: "GET",
        format: "json",
        ...params,
      }),
  }
  subscriptions = {
    /**
     * No description
     *
     * @tags Subscriptions
     * @name CheckoutDetail
     * @summary Show order data
     * @request GET:/subscriptions/checkout/{id}
     */
    checkoutDetail: (id: number, params: RequestParams = {}) =>
      this.request<ACheckoutDetailData, void>({
        path: `/subscriptions/checkout/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CheckoutCreate
     * @summary Confirm order with customer data
     * @request POST:/subscriptions/checkout/{id}
     */
    checkoutCreate: (
      id: number,
      data: {
        order?: {
          country_id: number
          payment_method: APaymentMethods
          /** must be true */
          terms_of_service: boolean
          subscription_billing_type: ASubscriptionBillingTypes
          customer_name: string
          customer_email: string
          city: string
          address: string
          postal_code: string
          state?: string
          company_name: string
          company_number: string
          company_tax_number?: string
          company_vat_number?: string
          invoice_detail_id?: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ACheckoutCreateData, void>({
        path: `/subscriptions/checkout/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CheckoutUpdate
     * @summary Update order with specific params
     * @request PUT:/subscriptions/checkout/{id}
     */
    checkoutUpdate: (
      id: number,
      data: {
        /** Update specific parameter Country or billing type, that recalculates prices for order */
        order?: {
          country_id?: number
          subscription_billing_type?: ASubscriptionBillingTypes
          company_vat_number?: string
          voucher_code_input?: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ACheckoutUpdateData, void>({
        path: `/subscriptions/checkout/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CheckoutPreviewDetail
     * @summary Show preview order data
     * @request GET:/subscriptions/checkout/{id}/preview
     */
    checkoutPreviewDetail: (id: number, params: RequestParams = {}) =>
      this.request<ACheckoutPreviewDetailData, void>({
        path: `/subscriptions/checkout/${id}/preview`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CheckoutCreate2
     * @summary Create order for submitted packages
     * @request POST:/subscriptions/checkout
     * @originalName checkoutCreate
     * @duplicate
     */
    checkoutCreate2: (
      data: {
        checkout?: {
          packages: {
            package_id: number
            sales_person_id?: number | null
          }[]
          sales_cycle_id: number
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ACheckoutCreate2Data, void>({
        path: `/subscriptions/checkout`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CancelCreate
     * @summary Cancel subscription
     * @request POST:/subscriptions/{id}/cancel
     */
    cancelCreate: (
      id: number,
      data: {
        subscription: {
          cancel_reason: string | null
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ACancelCreateData, void>({
        path: `/subscriptions/${id}/cancel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name SignInCreate
     * @summary Creates a session / logs in user
     * @request POST:/users/sign_in
     */
    signInCreate: (
      data: {
        user?: {
          email: string
          password: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<ASignInCreateData, void>({
        path: `/users/sign_in`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name SignOutDelete
     * @summary Deletes a session / logs out user
     * @request DELETE:/users/sign_out
     */
    signOutDelete: (params: RequestParams = {}) =>
      this.request<ASignOutDeleteData, any>({
        path: `/users/sign_out`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PasswordCreate
     * @summary Requests password reset (forgot password flow)
     * @request POST:/users/password
     */
    passwordCreate: (
      data: {
        user?: {
          email: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<APasswordCreateData, void>({
        path: `/users/password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name PasswordUpdate
     * @summary Reset password
     * @request PUT:/users/password
     */
    passwordUpdate: (
      data: {
        user?: {
          password: string
          password_confirmation: string
          reset_password_token: string
        }
      },
      params: RequestParams = {}
    ) =>
      this.request<APasswordUpdateData, void>({
        path: `/users/password`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
}
