import React from "react"
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../Link"
import {EOrderDirection, TColumnMeta, TColumns, useTableContext} from "./shared"

export type THeaderCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
}

export type THeaderCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  onOrder?: () => void
}

function DefaultHeaderCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
}: THeaderCellValueProps<TCol, TRowData>): React.ReactNode {
  return columnMeta.column
}

export function DefaultHeaderCell<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  onOrder,
}: THeaderCellProps<TCol, TRowData>): React.ReactNode {
  const {orderBy} = useTableContext<TCol, TRowData>()

  const Value = columnMeta.HeaderCellValue ?? DefaultHeaderCellValue

  const align = columnMeta.align ?? "left"

  return (
    <UnstyledLink
      className={twMerge([
        "whitespace-nowrap bg-cr-white px-2 py-2.5 text-sm font-bold text-cr-blue-dark transition-all md:px-6 md:py-3",
        "sticky top-0 z-10 flex min-h-10 select-none items-center gap-2 empty:px-0",
        "group-[.ghost]:bg-cr-white",
        onOrder && "cursor-pointer hover:text-cr-grey-80",
        align === "left" && "justify-start",
        align === "center" && "justify-center",
        align === "right" && "justify-end",
      ])}
      onClick={onOrder}
    >
      <Value columnMeta={columnMeta} />
      {orderBy?.column === columnMeta.column &&
        (orderBy?.direction === EOrderDirection.ASC ? (
          <ArrowUpIcon className={"h-4 w-4"} />
        ) : (
          <ArrowDownIcon className={"h-4 w-4"} />
        ))}
    </UnstyledLink>
  )
}
