import React from "react"
import {twMerge} from "tailwind-merge"

import {EMPTY_ARRAY} from "../../utils"
import {CheckboxBase} from "../fields/Checkbox"
import {DefaultCell} from "./Cell"
import {DefaultHeaderCell} from "./HeaderCell"
import {
  CHECKBOX_NAME_PREFIX,
  EOrderDirection,
  rowCheckContext,
  tableContext,
  TColumnMeta,
  TColumns,
  TColumnsMetaWithEmpty,
  TOrderBy,
  TRowCheckContext,
  TRowId,
  TTableContext,
  useRowCheckContext,
  useTableContext,
} from "./shared"

const TableComponent = function TableComponent<
  TCol extends TColumns,
  TRowData extends Record<string, any> & {id: TRowId},
>({
  columnsMeta: columnsMetaWithEmptyItems,
  data,
  ghost,
  orderBy,
  onOrder,
  loading,
  className,
  checkedRows,
  onCheckRow,
  children,
}: {
  columnsMeta: TColumnsMetaWithEmpty<TCol, TRowData>
  data: TRowData[]
  ghost?: boolean
  orderBy?: TOrderBy<TCol>
  onOrder?: (newOrderBy: TOrderBy<TCol>) => void
  loading?: boolean
  className?: string
  onCheckRow?: (rowId: TRowId) => void
  checkedRows?: readonly TRowId[]
  children?: ((args: {data: TRowData[]}) => React.ReactNode) | React.ReactNode
}): React.ReactNode {
  const handleOrder = React.useMemo(() => {
    if (!onOrder) {
      return undefined
    }

    return (column: TCol) => () => {
      if (orderBy?.column !== column) {
        return onOrder({column, direction: EOrderDirection.ASC})
      }

      if (orderBy.direction === EOrderDirection.ASC) {
        return onOrder({column, direction: EOrderDirection.DESC})
      }

      return onOrder(undefined)
    }
  }, [onOrder, orderBy?.column, orderBy?.direction])

  const columnsMeta = React.useMemo(
    () => columnsMetaWithEmptyItems.filter((item): item is TColumnMeta<TCol, TRowData> => !!item),
    [columnsMetaWithEmptyItems]
  )

  const contextValue = React.useMemo<TTableContext<TCol, TRowData>>(
    () => ({orderBy, onOrder, columnsMeta}),
    [columnsMeta, onOrder, orderBy]
  )

  const areAllChecked = React.useMemo(() => {
    return !!(checkedRows?.length && data.every(row => checkedRows.some(id => row.id === id)))
  }, [data, checkedRows])

  const handleCheckAll = React.useCallback(() => {
    if (!onCheckRow || !checkedRows) {
      return
    }

    data.forEach(row => {
      const isChecked = checkedRows.includes(row.id)

      if (isChecked === areAllChecked) {
        onCheckRow(row.id)
      }
    })
  }, [areAllChecked, checkedRows, data, onCheckRow])

  const rowCheckContextValue = React.useMemo<TRowCheckContext>(
    () => ({
      onCheck: onCheckRow ?? (() => undefined),
      areAllChecked,
      checked: checkedRows ?? [],
      onCheckAll: handleCheckAll,
    }),
    [areAllChecked, checkedRows, handleCheckAll, onCheckRow]
  )

  return (
    <rowCheckContext.Provider value={rowCheckContextValue}>
      <tableContext.Provider value={contextValue}>
        <div
          className={twMerge([
            "cr-shadow relative z-0 grid h-full w-full overflow-auto rounded-lg",
            loading
              ? "overlay-gray overlay-fast is-loading pointer-events-none after:border-cr-blue-super-light after:border-t-cr-blue"
              : "overlay-base",
            ghost && "ghost group border-transparent",
            className,
          ])}
          style={{gridTemplateColumns: columnsMeta.map(col => col.size ?? "auto").join(" ")}}
        >
          {columnsMeta.map(columnMeta => {
            const HeaderRenderer = columnMeta.HeaderCell ?? DefaultHeaderCell

            return (
              <HeaderRenderer
                key={columnMeta.column}
                columnMeta={columnMeta}
                onOrder={columnMeta.sortFn && handleOrder?.(columnMeta.column)}
              />
            )
          })}
          {children
            ? typeof children === "function"
              ? children({data})
              : children
            : data.map((row, index) => <Row key={row.id ?? index} row={row} />)}
        </div>
      </tableContext.Provider>
    </rowCheckContext.Provider>
  )
}

function Row<TCol extends TColumns, TRowData extends Record<string, any>>({
  row,
  className,
  testId,
  onClick,
}: {
  row: TRowData
  className?: string
  testId?: string
  onClick?: (e: React.MouseEvent) => void
}): React.ReactNode {
  const {columnsMeta} = useTableContext<TCol, TRowData>()
  const ref = React.useRef<HTMLDivElement>(null)

  const handleClick = React.useMemo(() => {
    if (!onClick) {
      return undefined
    }

    return (e: React.MouseEvent) => {
      // Don't execute onClick if the click event comes from something else than the row itself
      let el = e.target as HTMLElement | null
      while (el !== ref.current) {
        if (el == null || el.onclick || ["a", "input", "button", "label"].includes(el.tagName.toLowerCase())) {
          return
        }

        el = el.parentElement
      }

      return onClick(e)
    }
  }, [onClick])

  return (
    <div
      onClick={handleClick}
      className={twMerge(["group/row contents", onClick && "cursor-pointer"])}
      ref={ref}
      data-testid={testId}
    >
      {columnsMeta.map(columnMeta => {
        const CellRenderer = columnMeta.Cell ?? DefaultCell

        return <CellRenderer key={columnMeta.column} row={row} columnMeta={columnMeta} className={className} />
      })}
    </div>
  )
}

const rowCheckColumn: any = {
  column: "check" as const,
  size: "min-content",
  HeaderCellValue: () => {
    const {onCheckAll, areAllChecked} = useRowCheckContext()

    return <CheckboxBase name={"bulkCheck"} onChange={onCheckAll} checked={areAllChecked} />
  },
  CellValue: ({row}) => {
    const {checked, onCheck} = useRowCheckContext()

    return (
      <CheckboxBase
        checked={checked.includes(row.id)}
        onChange={() => onCheck(row.id)}
        name={`${CHECKBOX_NAME_PREFIX}${row.id}`}
      />
    )
  },
} satisfies TColumnMeta<any, Record<string, any> & {id: TRowId}>

export type TRowCheckingProps = {checkedRows: readonly TRowId[]; checkRow: (id: TRowId) => void}
export type TBasicRowCheckingProps = TRowCheckingProps & {
  clearRows: () => void
  setCheckedRows: (checkedRows: React.SetStateAction<readonly TRowId[]>) => void
}

function useRowChecking(rows: ReadonlyArray<{id: TRowId}> = EMPTY_ARRAY): TRowCheckingProps {
  const {setCheckedRows, checkRow, checkedRows} = useBasicRowChecking()

  const allRowIds = React.useMemo(() => {
    return rows.map(row => row.id)
  }, [rows])

  React.useMemo(() => {
    setCheckedRows(currentCheckedIds => currentCheckedIds.filter(checkedId => allRowIds.includes(checkedId)))
  }, [allRowIds, setCheckedRows])

  return {checkedRows, checkRow}
}

function useBasicRowChecking(): TBasicRowCheckingProps {
  const [checkedRows, setCheckedRows] = React.useState<readonly TRowId[]>([])

  const checkRow = React.useCallback((id: TRowId) => {
    setCheckedRows(currentCheckedIds => {
      const checkedRowIdIndex = currentCheckedIds.findIndex(rowId => rowId === id)

      if (checkedRowIdIndex === -1) {
        return [...currentCheckedIds, id]
      }

      return currentCheckedIds.filter(rowId => rowId !== id)
    })
  }, [])

  const clearRows = React.useCallback(() => {
    setCheckedRows(EMPTY_ARRAY)
  }, [])

  return {checkedRows, checkRow, clearRows, setCheckedRows}
}

export const Table = Object.assign(TableComponent, {Row, rowCheckColumn, useRowChecking, useBasicRowChecking})
