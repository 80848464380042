import {FC} from "react"
import {useTranslation} from "react-i18next"

import {InputBase} from "../../../components/fields/Input"
import {DropdownBase} from "../../../components/formElements/Dropdown"
import {useCountryOptions} from "../../../utils/hooks.tsx"
import {TSalesPlansFiltering} from "./utils.ts"

type TProps = Pick<TSalesPlansFiltering, "searchString" | "setSearchString" | "countryId" | "setCountryId">

const Filters: FC<TProps> = ({searchString, setSearchString, countryId, setCountryId}) => {
  const {t} = useTranslation()

  const countryOptions = useCountryOptions()

  return (
    <div className={"my-8 flex flex-wrap justify-end gap-6"}>
      <div className={"flex items-center gap-4"}>
        {t("T_Filters")}:
        <DropdownBase
          clearable
          buttonClassName={"w-40"}
          value={countryId}
          options={countryOptions}
          placeholder={t("T_Target market")}
          onChange={setCountryId}
        />
      </div>

      <div className={"flex items-center gap-4"}>
        <InputBase value={searchString} placeholder={t("T_Search")} onChange={e => setSearchString(e.target.value)} />
      </div>
    </div>
  )
}

export default Filters
