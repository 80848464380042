import React from "react"

import {EMPTY_ARRAY} from "./index.ts"
import {getLSItem, setLSItem, useLSValue} from "./localStorage"

export enum EFeature {
  SHOW_SALESPERSON_RATINGS = "SHOW_SALESPERSON_RATINGS",
  ALLOW_SALESPERSON_BUSINESS_INFO = "ALLOW_SALESPERSON_BUSINESS_INFO",
}

export const useEnabledFeatures = (): readonly EFeature[] => {
  return useLSValue("features") ?? EMPTY_ARRAY
}

export const useIsFeatureEnabled = (...features: EFeature[]): boolean => {
  const enabledFeatures = useEnabledFeatures()

  return React.useMemo(() => {
    return features.every(feature => enabledFeatures.includes(feature))
  }, [enabledFeatures, features])
}

// Initialize LS
if (!getLSItem("features")) {
  setLSItem("features", [])
}
