import React, {ChangeEventHandler} from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {BuildingOffice2Icon, TrashIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {AutocompleteFilter} from "../../components/AutocompleteFilter"
import {ButtonLoading, IconButton} from "../../components/Button"
import {EnumFilter} from "../../components/EnumFilter"
import {CheckboxBase} from "../../components/fields/Checkbox"
import {InputBase} from "../../components/fields/Input"
import {DropdownBase, TOption} from "../../components/formElements/Dropdown"
import {Table} from "../../components/Table"
import {Tooltip} from "../../components/Tooltip.tsx"
import {
  getLeadsAutocompleteQuery,
  useAddProspectFromLeadMutation,
  useBulkAddProspectFromLeadMutation,
  useBulkRemoveProspectFromLeadMutation,
  useRemoveProspectFromLeadMutation,
} from "../../queries/leads"
import requestError from "../../services/requestError.tsx"
import {ALead, ALeadAutocompleteFields, ALeadWithAssignmentProspect} from "../../services/types.generated"
import {getFullName} from "../../utils"
import {commonTransComponents, enumToOptions, enumTranslKey} from "../../utils/i18n"
import {AssignmentContext} from "../Prospects/shared/context.ts"
import {DeletingContext, EditingContext} from "./context"
import {TCompanySizeOption, TLeadsFiltering} from "./useLeadsFiltering"

export type TLeadsTableColumn =
  | "actions"
  | "in use"
  | "company"
  | "segment"
  | "city"
  | "contact person"
  | "position"
  | "phone"
  | "email"

enum EBulkAction {
  UNSELECT,
  DELETE,
  ADD,
  REMOVE,
}

const companySizeOptions = enumToOptions("CompanySize") as TCompanySizeOption[]

export const DataTable: React.FC<
  {
    isInProspects?: boolean
    data: Array<ALead | ALeadWithAssignmentProspect>
    isLoading: boolean
    refetch: () => void
  } & TLeadsFiltering
> = ({
  isInProspects,
  data,
  orderBy,
  setOrderBy,
  checkRow,
  checkedRows,
  isLoading,
  isSelectedOnly,
  setIsSelectedOnly,
  clearRows,
  positionFilter,
  setPositionFilter,
  segmentFilter,
  setSegmentFilter,
  countriesFilter,
  setCountriesFilter,
  companySizeFilter,
  setCompanySizeFilter,
  searchString,
  setSearchString,
}) => {
  const {t} = useTranslation()

  const editContext = EditingContext.useContext()
  const deleteContext = DeletingContext.useContext()
  const assignmentContext = AssignmentContext.useOptionalContext()

  const bulkAddProspectFromLeadMutation = useBulkAddProspectFromLeadMutation()
  const bulkRemoveProspectFromLeadMutation = useBulkRemoveProspectFromLeadMutation()

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      setSearchString(e.target.value)
    },
    [setSearchString]
  )

  const bulkActionOptions = React.useMemo<Array<TOption<EBulkAction>>>(
    () => [
      {title: t("Leads_BulkAction_Unselect"), value: EBulkAction.UNSELECT},
      ...(isInProspects
        ? [
            {title: t("Leads_BulkAction_Add"), value: EBulkAction.ADD},
            {title: t("Leads_BulkAction_Remove"), value: EBulkAction.REMOVE},
          ]
        : [{title: t("Leads_BulkAction_Delete"), value: EBulkAction.DELETE}]),
    ],
    [isInProspects, t]
  )

  const handleBulkAction = React.useCallback(
    async (action: EBulkAction) => {
      switch (action) {
        case EBulkAction.UNSELECT:
          clearRows()
          break
        case EBulkAction.DELETE:
          deleteContext.setValue(checkedRows as number[])
          break
        case EBulkAction.ADD:
          if (!assignmentContext?.value?.assignment) {
            return
          }

          try {
            const response = await bulkAddProspectFromLeadMutation.mutateAsync({
              assignmentId: assignmentContext.value.assignment.id,
              ids: checkedRows as number[],
            })

            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Add_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Add_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
        case EBulkAction.REMOVE:
          if (!assignmentContext?.value?.assignment) {
            return
          }

          try {
            const response = await bulkRemoveProspectFromLeadMutation.mutateAsync({
              assignmentId: assignmentContext.value.assignment.id,
              ids: checkedRows as number[],
            })
            const processed = response.data.processed_count
            const skipped = response.data.skipped_count

            if (processed > 0 && skipped === 0) {
              // all processed
              toast.success(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllProcessed", {count: processed}))
            }
            if (processed > 0 && skipped > 0) {
              // some processed, some skipped
              toast.success(
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Remove_Bulk_SomeProcessed"}
                  components={commonTransComponents}
                  values={{
                    processed,
                    skipped,
                  }}
                />
              )
            }
            if (processed === 0) {
              // all skipped
              toast.warning(t("Prospects_ImportModal_LeadsStep_Remove_Bulk_AllSkipped"))
            }
          } catch (e) {
            requestError(e)
          }
          break
      }
    },
    [
      assignmentContext?.value,
      bulkAddProspectFromLeadMutation,
      bulkRemoveProspectFromLeadMutation,
      checkedRows,
      clearRows,
      deleteContext,
      t,
    ]
  )

  return (
    <div className={"flex flex-col gap-8"}>
      <div className={"flex flex-wrap-reverse items-center justify-between gap-8"}>
        <div className={"flex items-center gap-4"}>
          <DropdownBase
            options={bulkActionOptions}
            placeholder={t("Leads_BulkAction_Placeholder", {count: checkedRows.length})}
            value={null}
            multiple={false}
            onChange={handleBulkAction as any}
            buttonClassName={"min-w-[160px]"}
            disabled={checkedRows.length === 0}
          />
          <div
            className={twMerge(
              "pointer-events-none opacity-40 transition-all",
              checkedRows.length && "pointer-events-auto opacity-100"
            )}
          >
            <CheckboxBase onChange={e => setIsSelectedOnly(e.target.checked)} toggle checked={isSelectedOnly}>
              {t("Leads_ShowSelectedOnly")}
            </CheckboxBase>
          </div>
        </div>
        <div
          className={twMerge(
            "flex grow flex-wrap items-center justify-end gap-6 opacity-100 transition-all",
            isSelectedOnly && "pointer-events-none opacity-40"
          )}
        >
          <div className={"flex flex-wrap items-center gap-6"}>
            <span className={"font-bold"}>{t("Leads_Filters_Title")}</span>
            <EnumFilter
              multiple
              options={companySizeOptions}
              title={t("Leads_Filters_CompanySize")}
              value={companySizeFilter}
              onChange={setCompanySizeFilter}
            />
            <AutocompleteFilter
              title={t("Leads_Filters_Country")}
              value={countriesFilter}
              useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.Country)}
              onChange={setCountriesFilter}
            />
            <AutocompleteFilter
              title={t("Leads_Filters_Segment")}
              value={segmentFilter}
              useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.Segment)}
              onChange={setSegmentFilter}
            />
            <AutocompleteFilter
              title={t("Leads_Filters_Position")}
              value={positionFilter}
              useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.Position)}
              onChange={setPositionFilter}
            />
          </div>
          <InputBase value={searchString} onChange={handleChangeSearchString} placeholder={t("SearchPlaceholder")} />
        </div>
      </div>

      <Table<TLeadsTableColumn, (typeof data)[number]>
        data={data}
        loading={isLoading || bulkAddProspectFromLeadMutation.isPending || bulkRemoveProspectFromLeadMutation.isPending}
        orderBy={orderBy}
        onOrder={setOrderBy}
        checkedRows={checkedRows}
        onCheckRow={checkRow}
        columnsMeta={[
          Table.rowCheckColumn,
          {
            column: "actions",
            size: "min-content",
            HeaderCellValue: () => t("Leads_Table_Actions"),
            CellValue: isInProspects ? ActionsProspects : ActionsLeads,
          },
          data.some(row => row.in_use) && {
            column: "in use",
            size: "min-content",
            HeaderCellValue: () => t("Leads_Table_InUse"),
            CellValue: ({row}) => row.in_use && <BuildingOffice2Icon className={"size-4"} />,
          },
          {
            column: "company",
            sortFn: true,
            HeaderCellValue: () => t("Leads_Table_Company"),
            CellValue: ({row}) => row.organization_name,
          },
          {
            column: "segment",
            size: "max-content",
            HeaderCellValue: () => t("Leads_Table_Segment"),
            CellValue: ({row}) => (
              <span>
                {row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}
              </span>
            ),
          },
          {
            column: "city",
            size: "minmax(min-content, 400px)",
            sortFn: true,
            HeaderCellValue: () => t("Leads_Table_City"),
            CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
          },
          {
            column: "contact person",
            size: "max-content",
            HeaderCellValue: () => t("Leads_Table_ContactPerson"),
            CellValue: ({row}) => (
              <span>
                {getFullName({first_name: row.contact_person_first_name, last_name: row.contact_person_last_name}) ||
                  "-"}
              </span>
            ),
          },
          {
            column: "position",
            size: "minmax(150px, auto)",
            sortFn: true,
            HeaderCellValue: () => t("Leads_Table_Position"),
          },
          {
            column: "phone",
            size: "max-content",
            HeaderCellValue: () => t("Leads_Table_Phone"),
            CellValue: ({row}) => <span>{row.phone_number ?? "-"}</span>,
          },
          {column: "email", sortFn: true, HeaderCellValue: () => t("Leads_Table_Email")},
        ]}
      >
        {({data}) => (
          <>
            {data.length ? (
              data.map(row => (
                <Table.Row
                  key={row.id}
                  onClick={isInProspects ? undefined : () => editContext.setValue(row)}
                  row={row}
                  className={twMerge([row.id === editContext.value?.id && "bg-cr-blue-super-light"])}
                />
              ))
            ) : (
              <div className={"col-span-full py-7 text-center text-sm"}>{t("Leads_NoLeads_TableCaption")}</div>
            )}
          </>
        )}
      </Table>
    </div>
  )
}

export const ActionsLeads: React.FC<{row: ALead}> = ({row}) => {
  const {t} = useTranslation()
  const deleteContext = DeletingContext.useContext()

  return (
    <Tooltip
      buttonNode={
        <IconButton className={"text-cr-black hover:text-cr-red"} noEdges onClick={() => deleteContext.setValue(row)}>
          <TrashIcon className={"size-4"} />
        </IconButton>
      }
      openDelay={200}
    >
      <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>{t("Leads_Table_Delete")}</div>
    </Tooltip>
  )
}

export const ActionsProspects: React.FC<{row: ALeadWithAssignmentProspect}> = ({row}) => {
  const {t} = useTranslation()

  const assignmentContext = AssignmentContext.useContext()
  const assignment = assignmentContext.value?.assignment

  const addProspectFromLeadMutation = useAddProspectFromLeadMutation()
  const removeProspectFromLeadMutation = useRemoveProspectFromLeadMutation()

  const handleAdd = React.useCallback(async () => {
    if (!assignment) {
      return
    }

    try {
      await addProspectFromLeadMutation.mutateAsync({
        assignmentId: assignment.id,
        leadId: row.id,
      })
    } catch (e) {
      requestError(e)
    }
  }, [addProspectFromLeadMutation, assignment, row.id])

  const handleRemove = React.useCallback(async () => {
    if (row.assignment_prospect?.id == null) {
      return
    }

    try {
      await removeProspectFromLeadMutation.mutateAsync(row.assignment_prospect?.id)
    } catch (e) {
      requestError(e)
    }
  }, [removeProspectFromLeadMutation, row.assignment_prospect?.id])

  return row.assignment_prospect ? (
    <ButtonLoading size={"xs"} fullWidth onClick={handleRemove}>
      {t("Prospects_ImportModal_LeadsStep_Remove_Button")}
    </ButtonLoading>
  ) : (
    <ButtonLoading variant={"outlined"} color={"gray"} size={"xs"} fullWidth onClick={handleAdd}>
      {t("Prospects_ImportModal_LeadsStep_Add_Button")}
    </ButtonLoading>
  )
}
