import React from "react"
import {Survicate} from "@survicate/survicate-web-surveys-wrapper"
import {getSurvicateInstance, initSurvicate} from "@survicate/survicate-web-surveys-wrapper/widget_wrapper"
import {QueryClient} from "@tanstack/react-query"
import axios from "axios"
import Rollbar from "rollbar"

import {TOrderBy} from "../components/Table/shared"
import {ELanguage, getCurrentLanguage} from "../i18n"
import {TReportsMetric} from "../queries/reports"
import {useUserSettingsQuery} from "../queries/user.ts"
import {TLeadsTableColumn} from "../routes/Leads/DataTable"
import {TCompanyTableColumn} from "../routes/Prospects/Company/types.ts"
import {TAdminTableColumn} from "../routes/Prospects/shared/DataTable.tsx"
import {getFullName} from "../utils"
import {isCompanyUser} from "../utils/types"
import {
  AActivityEvents,
  ALeadAutocompleteFields,
  ALeadCompanySizes,
  Api,
  AProspectQueryStatusValues,
  ASalesCycleFilterStages,
  ASalesCycleProspectAutocompleteFields,
  ASalesCycleProspectStatuses,
  ASettings,
  AUserTypes,
  ContentType,
} from "./types.generated"

export const baseURL = import.meta.env.VITE_API_URL
const api = new Api({baseURL, withCredentials: true, headers: {Accept: ContentType.Json}})
api.instance.interceptors.request.use(config => ({...config, params: {lang: getCurrentLanguage(), ...config.params}}))

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000,
      throwOnError: (error, query) => {
        rollbar.error("Query error", error, query)
        return false
      },
    },
  },
})

export const prospectsGeneralTablesKey = ["prospects", "table"] as const
const prospectsSpecificTableKey =
  (table: string) =>
  ({
    salesCycleId,
    searchString,
    orderBy,
    assignmentId,
    status,
  }: {
    salesCycleId: number
    assignmentId?: number
    searchString: string
    status: AProspectQueryStatusValues | null
    orderBy: TOrderBy<TAdminTableColumn | TCompanyTableColumn>
  }) =>
  (paginationQuery: string) => [
    ...prospectsGeneralTablesKey,
    table,
    salesCycleId,
    assignmentId,
    searchString,
    orderBy,
    status,
    paginationQuery,
  ]

export const leadsGeneralTableKey = ["leads"] as const
export const leadsImportersGeneralKey = ["leads importers"] as const

export const activityFeedGeneralKey = ["activity feed"] as const

export const salesCycleDetailGeneralKey = ["sales cycle detail"] as const

export const queryKey = {
  logout: ["logout"],
  userSettings: ["user settings"],
  stripe: (apiKey: string) => ["stripe", apiKey],
  checkoutDetail: (id: number) => ["checkout", id],
  checkoutPreview: (id: number) => ["checkout", "preview", id],
  checkoutUpdate: ["checkout", "update"],
  salesDashboard: (id: number) => ["sales dashboard", id],
  companyDashboard: (id: number) => ["company dashboard", id],
  companyDashboardActive: (id: number) => ["company dashboard", id, "active"],
  companyDashboardDraft: (id: number) => ["company dashboard", id, "draft"],
  companyDashboardFinished: (id: number) => ["company dashboard", id, "finished"],
  companyDashboardPaused: (id: number) => ["company dashboard", id, "paused"],
  salesCycles: ["sales cycles"],
  salesCycle: (id: number | undefined) => ["sales cycles", id],
  salesCycleDetail: (id: number) => [...salesCycleDetailGeneralKey, id],
  salesCycleNewsDetail: (id: number) => ["sales cycle news", id],
  salesCycleIterations: (filters: string) => (paginationQuery: string) => [
    "sales cycle iterations",
    filters,
    paginationQuery,
  ],
  salesCycleIteration: (id: string) => ["sales cycle iterations", id],
  salesCycleIterationMatchable: (id: string, searchString?: string) => [
    "sales cycle iterations",
    id,
    "matchable",
    searchString,
  ],
  company: (id: number) => ["company", id],
  companyWelcomeScreen: (id: number) => ["company welcome screen", id],
  salesperson: (id: number) => ["salesperson", id],
  salespersonPublic: (id: number) => ["salespersonPublic", id],
  salespersonBusinessInfo: (id: number) => ["salesperson business info", id],
  maintenanceCheck: ["maintenance check"],
  languages: ["languages"],
  prospects: (id: number) => (paginationQuery: string) => ["prospects", id, paginationQuery],
  pendingConfirmation: (token: string) => ["confirmation", token],
  prospectsSalesCycle: (id?: number) => ["prospects", "sales cycle", id].filter(part => part != null),
  prospectsTables: prospectsGeneralTablesKey,
  prospectsDraftTable: prospectsSpecificTableKey("draft"),
  prospectsLifetimeTable: prospectsSpecificTableKey("lifetime"),
  prospectsWaitingForApprovalTable: prospectsSpecificTableKey("waiting for approval"),
  prospectsFinishedTable: prospectsSpecificTableKey("finished"),
  saasDashboard: ["saas dashboard"],
  leads:
    (filter: {
      searchString?: string
      countryIds?: number[]
      positions?: string[]
      segments?: string[]
      companySizes?: ALeadCompanySizes[]
      ids?: number[]
      orderBy: TOrderBy<TLeadsTableColumn>
    }) =>
    (paginationQuery: string) => [...leadsGeneralTableKey, filter, paginationQuery],
  removalRequestedLeads:
    (filter: {
      searchString?: string
      countryIds?: number[]
      positions?: string[]
      segments?: string[]
      companySizes?: ALeadCompanySizes[]
      ids?: number[]
      orderBy: TOrderBy<TLeadsTableColumn>
    }) =>
    (paginationQuery: string) => [...leadsGeneralTableKey, "removal requested", filter, paginationQuery],
  leadsForAssignment:
    (
      assignmentId: number,
      filter: {
        searchString?: string
        countryIds?: number[]
        positions?: string[]
        segments?: string[]
        companySizes?: ALeadCompanySizes[]
        ids?: number[]
        orderBy: TOrderBy<TLeadsTableColumn>
      }
    ) =>
    (paginationQuery: string) => [...leadsGeneralTableKey, assignmentId, filter, paginationQuery],
  leadsAutocomplete: (field: ALeadAutocompleteFields, searchString: string) => [
    "leads autocomplete",
    field,
    searchString,
  ],
  leadDetail: (id: number | undefined) => ["lead detail", id],
  leadsImporters: (paginationQuery: string) => [...leadsImportersGeneralKey, paginationQuery],
  leadsMetadata: [...leadsGeneralTableKey, "metadata"],
  GDPR: (token: string) => ["GDPR", token],
  reportsSalesCycle: (salesCycleId: number) => ["reports", "sales cycle", salesCycleId],
  reportsIteration: (salesCycleId: number, iterationId: number | null) => [
    "reports",
    "sales cycle",
    salesCycleId,
    "iteration",
    iterationId,
  ],
  reportsNewsAndInsights: (salesCycleId: number, iterationId: number | null) => [
    "reports",
    "sales cycle",
    salesCycleId,
    "iteration",
    iterationId,
    "news and insights",
  ],
  reportsProspects:
    (
      salesCycleId: number,
      iterationId: number | null,
      assignmentId: string | null,
      filter: {
        searchString?: string
        segments?: string[]
        stages?: ASalesCycleFilterStages[]
        statuses?: ASalesCycleProspectStatuses[]
      }
    ) =>
    (paginationQuery: string) => [
      "reports",
      "sales cycle",
      salesCycleId,
      "iteration",
      iterationId,
      "assignment",
      assignmentId,
      "prospects",
      filter,
      paginationQuery,
    ],
  reportsProspectsAutocomplete: (
    salesCycleId: number,
    field: ASalesCycleProspectAutocompleteFields,
    searchString: string
  ) => ["reports", "sales cycle", salesCycleId, "prospects autocomplete", field, searchString],
  reportsAssignment: (salesCycleId: number, iterationId: number | null, assignmentId: string | null) => [
    "reports",
    "sales cycle",
    salesCycleId,
    "iteration",
    iterationId,
    "assignment",
    assignmentId,
  ],
  reportsChart: (
    metric: TReportsMetric | AActivityEvents,
    salesCycleId: number,
    iterationId: number | null,
    assignmentId: string | null
  ) => ["reports", "sales cycle", salesCycleId, "iteration", iterationId, "assignment", assignmentId, "chart", metric],
  activityFeed: (iterationId: number) => (paginationQuery: string) => [
    ...activityFeedGeneralKey,
    iterationId,
    paginationQuery,
  ],
  activityFeedProspect: (prospectId?: number, iterationId?: number) => [
    ...activityFeedGeneralKey,
    "prospect",
    prospectId,
    iterationId,
  ],
  salesCyclePeople: (salesCycleId: number) => ["sales cycle", salesCycleId, "people"],
}

const isSerializable = (obj: unknown): obj is Record<string, any> =>
  obj !== null && typeof obj === "object" && ["Object", "Array"].includes(obj.constructor.name)

/**
 * Rails requires a peculiar format for data in the multipart/form-data shape.
 * This function converts a regular object to a FormData in a shape that is acceptable by Rails.
 */
export function serializeForMultipart(source: Record<string, any>): FormData {
  const pairsTemp: Array<[string, any]> = []

  // This produces an array of key value pairs, where key looks like "[a][][c]"
  walkEntries(source, (key, value) => {
    if (value === undefined) {
      return
    }
    pairsTemp.push([key, value])
  })

  // this removes the brackets from the top level key, producing "a[][c]"
  const pairs: typeof pairsTemp = pairsTemp.map(([key, value]) => {
    const [, topLevelKey, restOfKeys] = key.match(/^\[(.*?)\](.*)$/) ?? []
    return [`${topLevelKey}${restOfKeys}`, value]
  })

  const formData = new FormData()
  pairs.forEach(([key, value]) => {
    formData.append(key, value)
  })

  return formData
}

/**
 * Calls append() on every leaf of the source object, with the key being the path to the leaf
 */
function walkEntries<T>(source: T, append: (key: string, value: any) => void) {
  if (!isSerializable(source)) {
    append("", source)
    return
  }

  if (Array.isArray(source)) {
    source.forEach(subSource => walkEntries(subSource, (subKey, subValue) => append(`[]${subKey}`, subValue)))
    return
  }

  Object.entries(source).forEach(([key, subSource]) =>
    walkEntries(subSource, (subKey, subValue) => append(`[${key}]${subKey}`, subValue))
  )
}

export type TRollbarUserInfo =
  | {
      id: -1
      username: "Not fetched yet"
      language: ELanguage
    }
  | {
      id: -2
      username: "Not logged in"
      language: ELanguage
    }
  | {
      id: number
      username: string
      email: string
      type: AUserTypes
      language: ELanguage
      isMasqueraded: boolean
    }

// @ts-expect-error __COMMIT_SHA is coming from Vite config
const commitSHA = __COMMIT_SHA

const getRollbarPerson = (): TRollbarUserInfo => {
  const settingsQuery = queryClient.getQueryState<ASettings>(queryKey.userSettings)
  const data = settingsQuery?.data

  if (settingsQuery?.status === "success" && data) {
    const username = isCompanyUser(data.user) ? data.user.company.name : getFullName(data.user)

    return {
      id: data.user.id,
      username,
      type: data.user.type,
      email: data.user.email,
      language: getCurrentLanguage(),
      isMasqueraded: data.masqueraded ?? false,
    }
  }

  if (settingsQuery?.status === "error") {
    return {id: -2, username: "Not logged in", language: getCurrentLanguage()}
  }

  return {id: -1, username: "Not fetched yet", language: getCurrentLanguage()}
}

export const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {source_map_enabled: true, code_version: commitSHA},
    },
  },
  transform: data => {
    data.smartlookUrl = (globalThis as {smartlook?: {playUrl?: string}})?.smartlook?.playUrl
    data.person = getRollbarPerson()
  },
})

/**
 * Buffers survicate commands until the service is initialized
 */
class SurvicateBuffer {
  private instance: Survicate | null = null
  private queue: Array<[keyof Survicate, Parameters<Survicate[keyof Survicate]>]> = []

  constructor() {
    this.initialize()
  }

  public run = <TCommand extends keyof Survicate>(command: TCommand, ...args: Parameters<Survicate[TCommand]>) => {
    if (!this.instance) {
      this.queue.push([command, args])
      return
    }

    this.executeCommand(command, args)
  }

  private initialize = () => {
    if (!import.meta.env.VITE_SURVICATE_KEY) {
      return
    }

    initSurvicate({
      workspaceKey: import.meta.env.VITE_SURVICATE_KEY,
    }).then(() => {
      this.instance = getSurvicateInstance()
      this.executeQueue()
    })
  }

  private executeCommand = <TCommand extends keyof Survicate>(
    command: TCommand,
    args: Parameters<Survicate[TCommand]>
  ) => {
    // @ts-expect-error TS just basically gives up here
    this.instance?.[command]?.(...args)
  }

  private executeQueue = () => {
    this.queue.forEach(([command, args]) => this.executeCommand(command, args))
    this.queue = []
  }
}

export const survicate = new SurvicateBuffer()

export const ServicesUserSetup: React.FC = () => {
  const {data, error} = useUserSettingsQuery()

  React.useEffect(() => {
    if (!error) {
      return
    }

    survicate.run("setVisitorTraits", getRollbarPerson())
    survicate.run("retarget")
  }, [error])

  React.useEffect(() => {
    if (!data) {
      return
    }

    survicate.run("setVisitorTraits", getRollbarPerson())
    survicate.run("retarget")
  }, [data])

  return null
}

export function multipartRequest({
  path,
  data,
  method,
}: {
  path: string
  data: Record<string, any>
  method: "POST" | "PUT" | "PATCH"
}) {
  return axios.request({
    url: `${import.meta.env.VITE_API_URL}${path}`,
    // Axios also has methods that serialize the values for you, but they produce obj[1][key] instead of obj[][key]
    // for objects inside an array. Our Rails BE cannot process that.
    data: serializeForMultipart(data),
    method,
    withCredentials: true,
  })
}

export default api
