import React from "react"

import {TCellProps, TCellValueProps} from "./Cell"
import {THeaderCellProps, THeaderCellValueProps} from "./HeaderCell"

export enum EOrderDirection {
  DESC = -1,
  ASC = 1,
}

export type TOrderBy<TCol extends TColumns> = {column: TCol; direction: EOrderDirection} | undefined

export type TColumns = string

type TAlign = "left" | "right" | "center"
type TVerticalAlign = "top" | "bottom" | "center"

export type TSortFn<TRowData extends Record<string, any>> = (
  direction: EOrderDirection
) => (A: TRowData, B: TRowData) => number

export type TColumnMeta<TCol extends TColumns, TRowData extends Record<string, any>> = {
  column: TCol
  size?: string
  align?: TAlign
  verticalAlign?: TVerticalAlign
  sortFn?: TSortFn<TRowData> | true
  HeaderCell?: React.ComponentType<THeaderCellProps<TCol, TRowData>>
  Cell?: React.ComponentType<TCellProps<TCol, TRowData>>
  CellValue?: React.ComponentType<TCellValueProps<TCol, TRowData>>
  HeaderCellValue?: React.ComponentType<THeaderCellValueProps<TCol, TRowData>>
}
export type TColumnMetaWithEmpty<TCol extends TColumns, TRowData extends Record<string, any>> =
  | TColumnMeta<TCol, TRowData>
  | undefined
  | null
  | false

export type TColumnsMeta<TCol extends TColumns, TRowData extends Record<string, any>> = Array<
  TColumnMeta<TCol, TRowData>
>
export type TColumnsMetaWithEmpty<TCol extends TColumns, TRowData extends Record<string, any>> = Array<
  TColumnMetaWithEmpty<TCol, TRowData>
>

export type TTableContext<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnsMeta: TColumnsMeta<TCol, TRowData>
  orderBy: TOrderBy<TCol>
  onOrder: ((newOrderBy: TOrderBy<TCol>) => void) | undefined
}

export const tableContext = React.createContext<TTableContext<any, any> | undefined>(undefined)

export function useTableContext<TCol extends TColumns, TRowData extends Record<string, any>>() {
  const ctx = React.useContext<TTableContext<TCol, TRowData> | undefined>(tableContext)

  if (!ctx) {
    throw new Error("Error: Attempt to read table context without a provider")
  }

  return ctx
}

export type TRowId = string | number

export type TRowCheckContext = {
  onCheckAll: () => void
  areAllChecked: boolean
  onCheck: (id: TRowId) => void
  checked: readonly TRowId[]
}

export const rowCheckContext = React.createContext<TRowCheckContext | undefined>(undefined)

export const useRowCheckContext = (): TRowCheckContext => {
  const ctx = React.useContext(rowCheckContext)

  if (ctx == null) {
    throw new Error("Row check context not found.")
  }

  return ctx
}

export const CHECKBOX_NAME_PREFIX = "row-check-"
